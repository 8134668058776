<template lang="pug">
  .resume-hide
    .container
      .hide-img__wrap
        img(alt="cvboost" src="@/assets/images/cv-is-hidden.svg").hide-img
      h4.h4 Похоже, ваше резюме на HH.ru скрыто настройками приватности
      p.text-center Чтобы проверить резюме с помощью CV Boost, нужно временно открыть резюме. Это можно сделать в личном кабинете HH.ru в разделе Мои резюме
      .buttons-wrap
        Button.button-orange(label='Ок' @click="showResumeDownload()")
      .info-block
        img(alt="cvboost" src="@/assets/images/HH-instruction_anima.gif").gif
        ul.info-block__list
          li.info-block__item
            | 1. Авторизируйтесь на hh.ru и зайдите в раздел <a href='https://hh.ru/'>Мои резюме</a>.
          li.info-block__item
            | 2. Нажмите <span>изменить видимость</span>, отмечайте пункт <span>Видно только по прямой ссылке</span>, внизу страницы не забудьте нажать <span>сохранить</span>
          li.info-block__item
            | 3. Чтобы скопировать ссылку, в личном кабинете в разделе <a href='https://hh.ru/'>Мои резюме</a> нажмите на <span>Доступно только по прямой ссылке</span> и скопируйте ссылку в появившемся окне.

</template>

<script>
import Button from "@/components/elements/Buttons/Button";
import {mapActions, mapState} from "vuex";

export default {
  name: "ResumeHide",
  components: {Button},
  computed: {
    
    ...mapState({
      errored: state => state.errored,
    }),
    
  },
  methods: {
    ...mapActions([
      'saveErroredAction',
    ]),
    showResumeDownload() {
      this.saveErroredAction(false)
    },
  }
  
}
</script>

<style scoped lang="scss">

.hide-img {
  width: 167px;
  height: 167px;
}

.hide-img__wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.resume-hide {
  padding: 100px 0 100px;
}

.h4 {
  text-align: center;
  margin-bottom: 15px;
}

.button-wrap {
  ::v-deep {
    width: 184px;
    margin-top: 40px;
    
  }
}

.info-block {
  display: flex;
  margin-top: 80px;
}

.container {
  max-width: 900px;
}

.gif {
  @include width-flex(50%)
}

.info-block__list {
  margin-left: 35px;
  font-size: 14px;
  line-height: 1.3;
  @include tablet {
    margin-left: 0;
  }
}

.info-block__item {
  margin-bottom: 25px;
  
  a {
    color: #ff8562;
  }
  
  span {
    font-family: $RobotoBold;
  }
}


</style>