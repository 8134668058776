<template lang="pug">
  .download-resume
    .container
      ValidationObserver(v-slot="{ handleSubmit }")
        form(@submit.prevent="handleSubmit(submitForm)").inputs-block
          h1.h1 Загрузите свое резюме для анализа
          .note-input Должно выглядеть примерно так: https://hh.ru/resume/123ccfdfff45e67ee89101ed1f2c136a141516
          .input-wrap
            //Input(placeholder='Ссылка на резюме на hh.ru' v-model="link" name="Ссылка" :rules="{required:true, regex: /^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$/}" )
            Input(placeholder='Ссылка на резюме на hh.ru' v-model="link" name="Ссылка" :rules="{required:true, regex: /^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?([a-z0-9]+\.+[hh\.]{1}|[hh\.]{1})+([\\-\\.]{2}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$/}" )
            //Input(placeholder='Ссылка на резюме на hh.ru' v-model="link" name="Ссылка" :rules="{required:true, regex: /^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$/}" )
            .note-input Не забудьте открыть резюме для общего доступа. <a href='javascript:void(0);' @click="show = !show">Как это сделать?</a>
          transition(name='fade')
            div(v-if='show')
              .info-block
                img(alt="cvboost" src="@/assets/images/HH-instruction_anima.gif").gif
                ul.info-block__list
                  li.info-block__item
                    | 1. Авторизируйтесь на hh.ru и зайдите в раздел <a href='https://hh.ru/'>Мои резюме</a>.
                  li.info-block__item
                    | 2. Нажмите <span>изменить видимость</span>, отмечайте пункт <span>Видно только по прямой ссылке</span>, внизу страницы не забудьте нажать <span>сохранить</span>
                  li.info-block__item
                    | 3. Чтобы скопировать ссылку, в личном кабинете в разделе <a href='https://hh.ru/'>Мои резюме</a> нажмите на <span>Доступно только по прямой ссылке</span> и скопируйте ссылку в появившемся окне.
          .input-wrap
            label.label Профессия
            validation-provider(rules='required' v-slot='{ errors }' name="Профессия")
              div(:class="{ 'errors': errors[0] }")
                v-select(:options="professionOptions" placeholder="Выберите из списка" v-model="profession")
              .error-text {{ errors[0] }}
          .input-wrap
            label.label Квалификация
            validation-provider(rules='required' v-slot='{ errors }' name="Квалификация")
              div(:class="{ 'errors': errors[0] }")
                v-select(:options="qualificationOptions" placeholder="Выберите из списка" v-model="qualification")
              .error-text {{ errors[0] }}
          .checkbox-block
            Checkbox(
              v-model='checkbox'
              :rules='{ required: { allowFalse: false } }'
              label="Соглашаюсь с <a href='#'>политикой конфиденциальности</a>"
              identifier='checkboxResume'
            )
          
          ButtonSubmit.button-yellow.w-100(label='Отправить' type='submit')

</template>

<script>
import Button from "@/components/elements/Buttons/Button";
import Input from "@/components/elements/Input";
import Checkbox from "@/components/elements/Checkbox";
import {mapActions, mapState} from 'vuex'
import ButtonSubmit from "@/components/elements/Buttons/ButtonSubmit";
import axios from "axios";

export default {
  name: "ResumeDownload",
  components: {ButtonSubmit, Checkbox, Button, Input},
  data() {
    return {
      show: false,
      link: '',
      profession: localStorage.getItem('profession'),
      qualification: localStorage.getItem('qualification'),
      checkbox: JSON.parse(localStorage.getItem("checkbox")) || [],
      
    }
  },
  
  methods: {
    
    ...mapActions([
      'saveLinkAction',
      'saveProfessionAction',
      'saveQualificationAction',
      'saveResumeAction',
      
      'saveInfoAction',
      'saveLoadingAction',
      'saveErroredAction',
    ]),
    submitForm: function () {
      this.$metrika.reachGoal(85906765,'reachGoal','send-resume')
      this.$gtag.event('ga-resume-submit', {
        'event_category': 'ga-resume',
        'event_label': 'ga-resume submit',
        'value': 1
      });
  
      this.saveLinkAction(this.link)
      this.saveProfessionAction(this.profession)
      this.saveQualificationAction(this.qualification)
      this.saveLoadingAction(true)
      
      localStorage.link = this.link
      localStorage.profession = this.profession
      localStorage.qualification = this.qualification

      // console.log('Отправили')
      const apiKey = 'DD4NMKT-5WW4RNJ-KJNCX9F-M66KAN9'

      axios
        .post('https://app.hr-drive.com/api/cv-boost/check', {
            position: this.profession,
            level: this.qualification,
            hhUrl: this.link,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': apiKey
            },
          })
        
        
        .then(
          response => {
            this.saveResumeAction(response.data)
             localStorage.setItem('resume', JSON.stringify(response.data))
            return response;
          }
        )
        .catch(error => {
          console.log(error);
          this.saveErroredAction(true);
        })
        .finally(() => (this.saveLoadingAction(false)));
      
      
    },
  },
  computed: {
    ...mapState({
      professionOptions: state => state.professionOptions,
      qualificationOptions: state => state.qualificationOptions,
      resume: state => state.resume,
      loading: state => state.loading,
      errored: state => state.errored,
    }),
  },
  mounted() {
    this.$metrika.reachGoal('send-resume')
  
  
    if (localStorage.link) {
      this.link = localStorage.link;
    }
    if (localStorage.profession) {
      this.profession = localStorage.profession;
    }
    
    if (localStorage.qualification) {
      this.qualification = localStorage.qualification;
    }
  },
  watch: {
    link(newLink) {
      localStorage.link = newLink;
    },
    profession(newProfession) {
      localStorage.profession = newProfession;
    },
    qualification(newQualification) {
      localStorage.qualification = newQualification;
    }
  }
  
}
</script>

<style scoped lang="scss">
.h1 {
  text-align: center;
  color: #000;
  margin-bottom: 75px;
}

.input-wrap_link {
  margin-bottom: 35px;
}

.download-resume {
  padding-top: 150px;
  padding-bottom: 150px;
  @include tablet {
    overflow-y: hidden;
  }
}

.inputs-block {
  max-width: 760px;
  margin: 0 auto;
}

.note-input {
  font-size: 14px;
  line-height: 1.55;
  margin-bottom: 10px;
  color: #a7a7a7;
  
  a {
    color: #409EFF;
    opacity: 1;
  }
}


.input-wrap {

}

.download-resume {
  .button-wrap {
    margin-top: 25px;
  }
}

.info-block {
  background: #F3F7FC;
  border-radius: 5px;
  padding: 40px 25px;
  display: flex;
  margin-bottom: 30px;
  @include tablet {
    flex-direction: column;
  }
}

.gif {
  @include width-flex(47%)
  @include tablet {
    @include width-flex(100%)
    margin-bottom: 25px;
  }
}

.info-block__list {
  margin-left: 35px;
  font-size: 14px;
  line-height: 1.3;
  @include tablet {
    margin-left: 0;
  }
}

.info-block__item {
  margin-bottom: 25px;
  
  a {
    color: #ff8562;
  }
  
  span {
    font-family: $RobotoBold;
  }
}

</style>