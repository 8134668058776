export default {
  positive: [
    {
      key: 'gt-ruby',
      title: 'Ruby',
      zerodesc: '<b>Если вы знаете Ruby, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b>  Если вы знаете Ruby, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме',
      smalldesc: '<b>Навык знания Ruby раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с Ruby. На скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: Bootstrap-Sass или Nokogiri. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Расскажите в деталях',
      tip: '<mark>Ruby</mark> – динамический, рефлективный, интерпретируемый высокоуровневый язык программирования. Язык обладает независимой от операционной системы реализацией многопоточности, сильной динамической типизацией, сборщиком мусора и многими другими возможностями'
    },
    {
      key: 'gt-java',
      title: 'Java',
      zerodesc: '<b>Если вы знаете Java, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете Java, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме',
      smalldesc: '<b>Навык знания Java раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с Java. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: JHipster или Maven. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Распишите в деталях',
      tip: '<mark>Java</mark> – строго типизированный объектно-ориентированный язык программирования общего назначения'
    },
    {
      key: 'gt-csharp',
      title: 'C#',
      zerodesc: '<b>Если вы знаете C#, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете C#, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме',
      smalldesc: '<b>Навык знания C# раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с C#. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: .net или asp.net MVC. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Распишите в деталях',
      tip: '<mark>С#</mark> – объектно-ориентированный язык программирования разработанный внутри компании Microsoft'
    },
    {
      key: 'gt-cplusplus',
      title: 'C++',
      zerodesc: '<b>Если вы знаете C++, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете C++, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме',
      smalldesc: '<b>Навык знания C++ раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с C++. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: MFC/ATL или C++AMP. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Распишите в деталях',
      tip: '<mark>C++</mark> – компилируемый, статически типизированный язык программирования общего назначения. Поддерживает такие парадигмы программирования, как процедурное программирование, объектно-ориентированное программирование, обобщённое программирование'
    },
    {
      key: 'gt-c',
      title: 'C',
      zerodesc: '<b>Если вы знаете C, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете C, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме',
      smalldesc: '<b>Навык знания C раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с C. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: GNU C или signal.h. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Распишите в деталях',
      tip: '<mark>C</mark> – компилируемый статически типизированный язык программирования общего назначения'
    },
    {
      key: 'gt-goland',
      title: 'Golang',
      zerodesc: '<b>Если вы знаете Golang, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете Golang, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме',
      smalldesc: '<b>Навык знания Golang раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с Golang. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: Wasmer или go-qml. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Распишите в деталях',
      tip: '<mark>Golang</mark> – компилируемый многопоточный язык программирования, разработанный внутри компании Google'
    },
    {
      key: 'gt-scala',
      title: 'Scala',
      zerodesc: '<b>Если вы знаете Scala, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете Scala, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме',
      smalldesc: '<b>Навык знания Scala раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано со Scala. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: Saddle или Akka. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Постарайтесь расписать кратко, но в деталях',
      tip: '<mark>Scala</mark> – мультипарадигмальный язык программирования, спроектированный кратким и типобезопасным для простого и быстрого создания компонентного программного обеспечения, сочетающий возможности функционального и объектно-ориентированного программирования'
    },
    {
      key: 'gt-python',
      title: 'Python',
      zerodesc: '<b>Если вы знаете Python, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете Python, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме',
      smalldesc: '<b>Навык знания Python раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с Python. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки использовали. Например: Matplotlib или Keras. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Распишите в деталях',
      tip: '<mark>Python</mark> – высокоуровневый язык программирования общего назначения, ориентированный на повышение производительности разработчика и читаемости кода'
    },
    {
      key: 'gt-php',
      title: 'PHP',
      zerodesc: '<b>Если вы знаете PHP, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете PHP, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме',
      smalldesc: '<b>Навык знания PHP раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с PHP. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: Purl или Ratchet. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Распишите в деталях',
      tip: '<mark>PHP</mark> – скриптовый язык общего назначения, применяемый для разработки веб-приложений. Поддерживается большинством хостинг-провайдеров и является одним из лидеров среди языков, применяющихся для создания динамических веб-сайтов'
    },
    {
      key: 'gt-javascript',
      title: 'JavaScript',
      zerodesc: '<b>Если вы знаете JavaScript, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете JavaScript, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме',
      smalldesc: '<b>Навык знания JavaScript раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с JavaScript. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: React или JQuery. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Постарайтесь расписать в деталях',
      tip: '<mark>JavaScript</mark> – мультипарадигменный язык программирования. Поддерживает объектно-ориентированный, императивный и функциональный стили. Является реализацией стандарта ECMAScript. JavaScript обычно используется как встраиваемый язык для программного доступа к объектам приложений'
    },
    {
      key: 'gt-devops',
      title: 'DevOps',
      zerodesc: '<b>Вы случайно не использовали практики DevOps? Спецов с таким опытом любят больше, чем без, особенно в маленьких командах.</b> Подумайте, возможно в вашем опыте был этап освоения и использования DevOps и инструментов для него? Примеры инструментов: Apache Tomcat, Amazon Web Services, Google Cloud Platform, DigitalOcean, Sentry и т.п.',
      smalldesc: '<b>В вашем резюме есть упоминание о DevOps или  инструментов для него.</b> Правда считывается это плохо. Рекомендуем лучше расписать. Какие задачи вы решали с помощью DevOps практик? Какие инструменты использовали - расширьте список. Спецов с опытом DevOps любят больше, чем без.',
      tip: '<mark>DevOps</mark> – это набор практик для повышения эффективности процессов разработки (Development) и эксплуатации (Operation) программного обеспечения (ПО).'
    },
    {
      key: 'gt-qa',
      title: 'Quality Assurance',
      zerodesc: '<b>Расскажите в резюме, как обеспечивали качество разрабатываемого ПО.</b> Может вы использовали TDD подход? Или всё покрывали Unit тестами?',
      smalldesc: '<b>В вашем резюме есть упоминание о методах тестирования или инструментах для него.</b> Кажется об этом написано всего пара упоминаний. Напишите пожалуйста ещё об этом опыте, нам кажется, сейчас маловато информации. Часто пишут про "Модульное тестирование, проведение тестирований, нагрузочное тестирование, регрессионный анализ"',
      tip: '<mark>QA</mark> – это процесс обеспечения качества программного продукта на всех этапах разработки, но на просторах СНГ часто этот термин применяется относительно тестирования ПО'
    },
    {
      key: 't-shaped',
      title: 'Универсальный герой',
      zerodesc: '<b>Есть ощущение, что вы всю жизнь шли прямо вперёд, чтобы развиваться как узконаправленный специалист и это круто!</b> Но если в вашей жизни был и другой опыт, не стесняйтесь, напишите об этом! Людей которые имеют глубокую экспертизу в одной области и широкий спектр знаний в других, называют T-shaped специалистом и, конечно же, очень ценят на рынке',
      smalldesc: '<b>Судя по резюме, у вас есть экспертиза в разных сферах.</b> Людей которые имеют глубокую экспертизу в одной области и широкий спектр знаний в других, называют T-shaped специалистами. Сейчас к этому стремится вся digital-индустрия. Чем больше вы напишете о сферах, где вы компетентны и чем глубже раскроете, тем более "T" образной персоной будете считаться. Но не переборщите с нерелевантным профессии опытом, можно показаться недостаточно компетентным в своём основном поле',
      tip: '<mark>Универсальный герой</mark> - такой навык получают те, у кого есть глубокая экспертиза в одном вопросе и широкий спектр знаний в других, так называемые T-shaped специалисты'
    },
    {
      key: 'made-myself',
      title: 'Ориентация на результат',
      zerodesc: '<b>Есть ощущение, что в тексте вы много говорите о том, что делали, но не говорите о том, что СДЕЛАЛИ.</b> Это не очень информативно для рекрутенра. Попробуйте переформулировать текст о ваших позициях, исходя из результатов - что было сделано. Для этого используйте слова вроде “создал(а)”, “внедрил(а)” или “оптимизировал(а)”',
      smalldesc: '<b>Кажется, вы много пишете о своих задачах, а о результатах значительно меньше.</b> Попробуйте переписать те куски, где пишете формулировки вроде “я делал(а)” так, чтобы получалось “я сделал(а)”. Для этого используйте слова вроде “реализовал(а)”, “организовал(а)” или “улучшил(а)”',
      tip: '<mark>Ориентация на результат</mark> - это важное качество для любого независимого специалиста. Можно показать свою целеустремленность, немного изменив формулировки в резюме'
    },
    {
      key: 'exp-startup',
      title: '"Покоритель стартапов"',
      zerodesc: '<b>Если вдруг одна из компаний, которые перечислены в вашем опыте работы, была стартапом, напишите об этом прямо</b> - это может быть большим плюсом для рекрутера, т.к. частенько в сартапах работать сложнее, нагрузка больше, а опыт, соответственно, ценнее. “Опыт работы в стартапах, во внутренних стартапах-стримах крупных корпораций, общении с финансовыми службами, построения юнит-экономики, проведении презентаций и защиты проектов перед стейкхолдерами”',
      smalldesc: '<b>Наши алгоритмы решили, что у вас есть опыт работы в стартапах.</b> Напишите пожалуйста ещё пару слов об этом опыте, нам кажется, сейчас маловато информации. Например так: “Запустили на международные рынок два собственных стартапа, (IT, медицина, биомедицина, нано- технологии). Консультировала 60+ стартапов по CustDev, клиентскому опыту, разработке продуктов, бизнес-модели и монетизации”',
      tip: '<mark>"Покоритель стартапов"</mark> – кажется, вы работали в стартапах. Неплохо!'
    },
    {
      key: 'gt-scrum',
      title: 'Мастер гибких методологий',
      zerodesc: '<b>Если в одной из ваших команд вы применяли гибкие методологии, обязательно напишите об этом!</b> Можно как-то так: “При построении и развитии продуктов использую методики lean, JTBD, HADI, GrowthHacking, CustDev, прорабатываю пользовательские сценарии, анализирую рынок, конкурентов, связи продуктовых метрик и бизнеса; Имею практический опыт работы по методологии Lean/Agile”. Но можно и проще: “Тесное взаимодействие с командой разработчиков (в качестве Product Owner) по Scrum”',
      smalldesc: '<b>Мы считаем, что вы пробовали работать в гибких методологиях и вам не понаслышке знаком фреймворк Scrum.</b> Теперь мы хотим знать больше! Попробуйте больше подчеркнуть ваше участие в команде. Для этого можно, например, упомянуть какие из мероприятий скрама по-вашему являются наиболее эффективными, а от каких можно отказаться',
      tip: '<mark>Мастер гибких методологий</mark> - вы знаете чем отличается Scrum от Kanban, а Agile для вас - не пустой звук, а песня!'
    },
    {
      key: 'achievements',
      title: 'Достижения',
      zerodesc: '<b>В вашем резюме нет упоминания, что вы чего-то достигли или добились. Работодатель любит результативных, а не процессовых.</b> Переформулируйте текст о ваших успехах (или напишите его) и постарайтесь рассказать с точки зрения вашей личной результативности. Поменьше используйте слово "участвовал"',
      smalldesc: '<b>Вы упоминаете о своих достижениях, но недостаточно делаете акцента на себе.</b> Переформулируйте текст о ваших успехах и постарайтесь рассказать с точки зрения вашей личной результативности',
      tip: '<mark>Достижения</mark> – упоминания про личные результаты, успехи и достижения'
    },
    {
      key: 'methodologies',
      title: 'Методологии разработки',
      zerodesc: '<b>Пропишите в резюме какие методологии разработки вы использовали и о каких знаете (изучали).</b> Например: Scrum, Kanban, devops. Большинство современных компаний ценят такие знания, а ещё больше опыт построения. Это помогает быстрее влиться в процесс',
      smalldesc: '<b>Видим, что пишите про метологии разработки. Это круто. Угубитесь немного в описании, расширьте количество упоминаний.</b> Для чего какие метологии использовали? Почему выбрали именно их и т.д. Ну вы поняли',
      tip: '<mark>Методологии разработки</mark> – упомнинания и отсутствие упоминаний методологий разработки'
    },
    {
      key: 'github',
      title: 'GitHub',
      zerodesc: '<b>Добавьте в резюме ссылку на ваш GitHub (или любой другой хостинг проектов, где хранятся ваши ветки).</b> Один из важнейших аспектов на который обращает внимание работодатель при поиске разработчиков – это его код. Если же вы не храните свой код на хостингах, то самое время начать – это ваше портфолио',
      smalldesc: '<b>О, ссылки на хостинг проектов – правильно. Красава',
      tip: '<mark>GitHub</mark> – крупнейший веб-сервис для хостинга IT-проектов и их совместной разработки'
    },
    {
      key: 'from-scratch',
      title: 'Разработка проекта с нуля',
      zerodesc: '<b>Пробовали ли вы когда-нибудь развивать проект с нуля? От идеи через MVP до взрослого работающего классного продукта.</b> Если пробовали, обязательно напишите об этом опыте: упомяните, что проект был "под ключ" и дальше расскажите о деталях управления, customer development, основных метриках и конечно аналитике',
      smalldesc: '<b>Пробовали запускать проект c нуля? Начинали разработку продукта? Напишите об этом детальнее! Это отличный опыт.</b> Расскажите почему не получилось (если не получилось) и какие выводы вы сделали. Это многое расскажет о вас и поможет найти максимально подходящее предложение',
      tip: '<mark>"С нуля"</mark> – разработка проекта с нуля, от идеи до реализации'
    },
    {
      key: 'machine-learning',
      title: 'Машинное обучение',
      zerodesc: '<b>Вы случайно с машинным обучением дел не имели? Это востребованное и модное направление.</b> Спецов с таким опытом любят больше, чем без. Подумайте, возможно в вашем опыте был этап освоения machine learning и инструментов для него? Примеры инструментов: Google Cloud, Yandex Cloud, Anaconda, Spark, Tensorflow и т.п.',
      smalldesc: '<b>В вашем резюме есть упоминания о машинном обучении или инструментов для него.</b> Правда считывается это плохо. Рекомендуем лучше расписать. Какие задачи вы решали с помощью инструментов машинного обучения? Какие инструменты использовали – расширьте список. Это востребованное и модное направление. Спецов с опытом machine learning любят больше, чем без.',
      tip: '<mark>Машинное обучение</mark> – инструменты, которые можно использовать для машинного обучения. Продукты в которых используется машинное обучение. Machine Learning обширный подраздел искусственного интеллекта, изучающий методы построения алгоритмов, способных обучаться'
    },
    {
      key: 'api-integration',
      title: 'API и интеграции',
      zerodesc: '<b>Для вашей профессии характерен опыт использования API и интеграций.</b> Но у вас в резюме ничего про это нет. Постарайтесь вспомнить, может быть вы сталкивались с задачами интеграций одного сервиса в другой? Или расширяли функционал продукта при помощи интерфейса программирования другого продукта? Распишите весь похожий опыт',
      smalldesc: '<b>Вы пишите про API или интеграции – какие сервисы и с чем приходилось использовать?</b> Расскажите детальнее. Опишите реальные случаи. Обратите внимание рекрутера на это вашим текстом',
      tip: '<mark>API и интеграции</mark> – в резюме упоминаются микросервисы, интеграция систем, интерфейсы программирования и т.п.'
    },
    {
      key: 'gt-bigdata',
      title: 'Большие данные',
      zerodesc: '<b>У востребованных специалистов нередко встречается опыт работы с большими данными. Возможно у вас он тоже есть?</b> Оглянитесь назад, может вы когда-нибудь сталкивались с задачами обработки большого количества информации? Структурировали огромное количество информации? Использовали инструменты для BigData? Это ценный навык, расскажите о нём, если он у вас есть',
      smalldesc: '<b>Судя по всему у вас есть опыт работы с большими данными, но он недостаточно широко раскрыт.</b> Может быть вы сможете глубже описать ваш опыт взаимодействия с BigData? Какие подходы вы использовали? Какие инструменты и методы применяли для больших данных? Это ценный навык, раскройте его максимально широко',
      tip: '<mark>Большие данные</mark> – в резюме есть упоминание о опыте с BigData. BigData – серия подходов, инструментов и методов обработки структурированных и неструктурированных данных огромных объёмов'
    },
    {
      key: 'gt-databases',
      title: 'Базы данных',
      zerodesc: '<b>Базы данных это хорошо, с базами данных нужно уметь работать. Алгоритм не нашел упоминаний Data Bases в вашем резюме.</b> Может вы забыли про них упомянуть? Если у вас был такое опыт – расскажите о нём в деталях. Мы например используем MongoDB и Postgre‍S‍Q‍L',
      smalldesc: '<b>Вы умеете работать с базами данных, но что-то маловато расписали про этот опыт.</b> Детализируйте ваш экспириенс. Расскажите о ваших кейсах, как, где и для каких данных вы задействовали data base. Заодно напишите какие именно базы использовали. Не дайте рекрутерам упустить эту деталь',
      tip: '<mark>Базы данных</mark> – представленная в объективной форме совокупность самостоятельных материалов, систематизированных таким образом, чтобы эти материалы могли быть найдены и обработаны. Примеры баз данных: My‍S‍Q‍L, Postgre‍S‍Q‍L, MS SQL, Apache Derby, Tarantool и т.п.'
    },
    {
      key: 'teamlead',
      title: 'Teamlead',
      zerodesc: '<b>Вы управляли командой разработчиков, приходилось ли когда-нибудь?</b> Если такой опыт имеется расскажите об этом в резюме не дайте рекрутерам упустить вас. Это большой плюс к вашей кандидатуре (и зарплате)',
      smalldesc: '<b>Слушайте, кажется по вашему резюме вы были тимблидом или выполняли функционал.</b> Как-то маловато об этом инофрмации. Расскажите подробнее, не дайте рекрутерам упустить вас. Это большой плюс к вашей кандидатуре (и зарплате)',
      tip: '<mark>Teamlead</mark> – высококвалифицированный программист, который знает, как управлять другими программистами. Он уже не пишет код своими руками и не думает над тем, как реализовать ту или иную функцию. Вместо этого он занимается распределением нагрузки на команду, следит за ходом проекта и берёт на себя ответственность за проект в целом'
    }
  ],
  negative: [
    {
      key: 'bouncer',
      title: '"Попрыгунчик"',
      desc: '<b>Вы часто меняли работу после непродолжительного периода. Обязательного опишите в резюме причины почему так происходило.</b> Возможно это были проектные контракты или осознание того, что это не ваше место? Это нормально, расскажите об этом. Иначе, работодатель посчитав, что вы проблемный кандидат, решит не связыватся c вами',
      tip: '<mark>"Попрыгунчик"</mark> - часто меняющий места работы после непродолжительного периода'
    },
    {
      key: 'job-gaps',
      title: 'Перерывы в работе',
      desc: '<b>Кажется, у вас был перерыв в работе больше 3 месяцев. Это может смутить HRа, но мы знаем что с этим сделать.</b> Хорошая идея: отметить это время как работу на себя и прописать каким полезным в работе навыкам вы в этот момент обучились, в чём стали сильнее. Вот отличный пример: “Компания: Декрет и отпуск по уходу за ребенком. Должность: мама и проектный менеджер. Описание: Во время отпуска по уходу за ребенком создала несколько занятных проектов: 1. Переработка сайта компании, торгующей немецкой кухонной фурнитурой - весь дизайн и функции. Начиная со сбора требований заказчика, до ТЗ на дизайн и разработку, через взаимодействие с подрядчиками. 2. Организация собственных курсов для кормящих и занятий послеродовой йогой. Благодаря этому проекту удалось получить опыт в Customer development, SMM, email-маркетинге, создании и аб-тестировании разных версий лендингов, составлении ТЗ на рекламные макеты',
      tip: '<mark>Перерывы в работе</mark> – наличие в резюме более 3 месяцев перерывов в работе'
    },
    {
      key: 'lev-tolstoy',
      title: '"Лев Толстой"',
      desc: '<b>У вас в тексте мы нашли несколько довольно длинных предложений, восприятие которых любым специалистом, включая рекрутеров, как мы считаем, часто бывает затруднительно</b>, особенно в случае, когда обработкаа информации происходит ближе к концу рабочего дня, а HR устал и ждёт, когда поедет домой… ну, вы поняли.) Постарайтесь разбить текст на более читаемые блоки, можно даже визуально разделить их переносом строки - так удобнее читать',
      tip: '<mark>"Лев Толстой"</mark> – любовь к большим и сложным текстам'
    },
    {
      key: 'small-experience',
      title: '"Зелёный ещё"',
      desc: '<b>Может быть, вы что-то забыли? По себе знаем, сложно что-либо написать про работу, если релевантного опыта особо и не было.</b> В таком случае можно описать другой ваш опыт, который давал вам необходимые в работе навыки. Если вы только выпустились, но были старостой группы и отвечали за посещаемость - замечательно, расскажите чему вы научились! Участвовали в конкурсе стипендий и выиграли - чудесно, это тоже навык, очень похоже на получение проектов в тендерной системе! Если же вы давно начали карьеру, но сейчас решили совершить крутой поворот, попробуйте найти в вашем прошлом опыте навыки, релевантные для текущей должности. Например, врач-хирург, который решил пойти в бизнес-аналитики, точно может писать о навыках глубокого анализа информации(изучение анамнеза) и принятия решений в ситуации давления/стресса',
      tip: '<mark>"Зелёный ещё"</mark> – общий опыт работы небольшой. Менее трёх лет. Но это не страшно, мы уверены, что вам есть чем заполнить пустые клетки!'
    },
    {
      key: 'cliche',
      title: 'Клише',
      desc: '<b>Есть некоторый список самых популярных фраз в резюме и именно их на дух не переносит любой HR.</b> Это фразы типа “активная жизненная позиция”',
      tip: '<mark>Клише</mark> - мы нашли в резюме шаблонные фразы, которые ненавидят все рекрутеры'
    },
    {
      key: 'perfectionist',
      title: 'Перфекционизм',
      desc: '<b>Мы обнаружили в резюме упоминание перфекционизма и спешим вас предупредить:</b> перфекционисты тормозят процессы в стартапах, но классно дают качественную работу, нужную для сложных "водопадных" проектов и качественных продуктов на заказ. То есть, перфекционизм сильно влияет на скорость работы и рекрутеры об этом знают. Если вы метите в стартап, маленькую, молодую, или просто быстро растущую компанию, лучше об этом не писать. Если ваше место в спокойной, стабильной компании-гиганте и её огромной команде, оставляйте “перфекционизм” на месте!',
      tip: '<mark>Перфекционист</mark> - болезненное стремление сделать работу идеально, из-за чего могут тормозиться процессы'
    },
    {
      key: 'exp-perfectionist',
      title: 'Перфекционист',
      desc: '<b>Мы нашли в резюме что-то “идеальное” и спешим вас предупредить:</b> перфекционисты тормозят процессы в стартапах, но классно дают качественную работу, нужную для сложных "водопадных" проектов и качественных продуктов на заказ. То есть, перфекционизм сильно влияет на скорость работы и рекрутеры об этом знают. Если вы метите в стартап, маленькую, молодую, или просто быстро растущую компанию, лучше так не писать. Если ваше место в спокойной, стабильной компании-гиганте и её огромной команде, оставляйте  весь перфекционизм на месте!',
      tip: '<mark>Перфекционист</mark> - болезненное стремление сделать работу идеально, из-за чего могут тормозиться процессы'
    },
    {
      key: 'freelance',
      title: 'Freelance',
      desc: '<b>Обнаружили в вашем резюме, что вы упоминаете Freelance. Если на чистоту, то HR-ы не очень любят фрилансеров.</b> Будет полезно рассказать, почему вы решили пофрилансить. Пример: "Захотел путишествоать, но финансовой подушки не хватало и т.п.". Если вы хотите фриланс и у вас указан только фриланс, то не обращайте внимание на эту рекомендацию. Удачи!',
      tip: '<mark>Freelance</mark> – ну, что тут можно пояснить. В резюме есть упоминание фриланса, всё'
    },
    {
      key: 'junior',
      title: 'Из Junior в князи',
      desc: '<b>Нашим алгоритмам показалось, что ваш переход от Junior позиции к Senior произошёл как-то очень быстро, скачкообразно.</b> Возможно, имеет смысл прописать более постепенный ход событий или как-то объяснить такой серьёзный рост за такое маленькое время. Например, если вы работали в одной компании 7 лет и прошли путь от junior через Middle к Senior и на следующем месте работы начали сразу с Senior позиции, стоит прописать изменение должностей на месте работы, где состоялся ваш рост',
      tip: '<mark>Из Junior в князи</mark> - быстрый переход от junior позиций к более высоким без промежуточного звена'
    },
    {
      key: 'unemployed',
      title: 'Безработный',
      desc: '<b>Кажется, вы сейчас нигде не работаете, HR-ов это насторожит, так что лучше рассказать почему так вышло.</b> Например: "Выгорел на прошлой работе, ушёл вникуда чтобы отдохнуть. Теперь настало время камбэкнуться в полную силу"',
      tip: '<mark>Безработный</mark> – на момент размещения резюме'
    }
  ]
}
