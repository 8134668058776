<template lang="pug">
  #app
    component(:is="layout")
      router-view

</template>

<style lang="scss">
</style>

<script>
import LandingLayout from '@/layouts/layout-landing'
import AppLayout from '@/layouts/layout-app'

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'app') + '-layout'
    },
  },
  components: {
    AppLayout,
    LandingLayout
  },
  
}
</script>