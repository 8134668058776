<template lang="pug">
  
  modal(name="modal-send" width="760" adaptive=true height="auto" scrollable=true).modal-send
    .close-button(@click="hide")
      svg(width='23px' height='23px' viewBox='0 0 23 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink')
        g(stroke='none' stroke-width='1' fill='#fff' fill-rule='evenodd')
          rect(transform='translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) ' x='10.3137085' y='-3.6862915' width='2' height='30')
          rect(transform='translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) ' x='10.3137085' y='-3.6862915' width='2' height='30')
    .modal-head
      .modal-head__bg
  
    .modal-content
      ValidationObserver(v-slot="{ handleSubmit }")
        form(@submit.prevent="handleSubmit(submitFormSend)")
          .modal-title Если хотите похвалить, поругать или что-то сообщить — напишите нам
          p.mail Наш e-mail: alice@cvboost.ru
          .input-wrap
            Input(placeholder='Ваш e-mail')
          .input-wrap
            Input(placeholder='Ваше имя')
          .input-wrap
            validation-provider(rules='required' v-slot='{ errors }' name="Отзыв")
              div(:class="{ 'errors': errors[0] }")
                Textarea(placeholder='Ваш отзыв или пожелание')
              .error-text {{ errors[0] }}
          ButtonSubmit.button-yellow.button-radius.w-100(label='Отправить' type='submit')
          .agree-text «Нажимая „Отправить“ вы даете согласие на обработку персональных данных и соглашаетесь c <a href='#'>политикой конфиденциальности</a>».
        
</template>


<script>

import Input from "@/components/elements/Input";
import Textarea from "@/components/elements/Textarea";
import Button from "@/components/elements/Buttons/Button";
import ButtonSubmit from "@/components/elements/Buttons/ButtonSubmit";
export default {
  name: "ModalSend",
  components: {ButtonSubmit, Button, Textarea, Input},
  mounted () {
    this.$modal.hide('modal-send')
  },
  methods: {
    hide () {
      this.$modal.hide('modal-send');
    },
    submitFormSend: function () {
      // console.log('Отправлено')
    },
  },
}
</script>

<style scoped lang="scss">

.modal-head__bg{
  @include background-cover('cvboost_logo-modal.webp')
  width: 100%;
  height: 205px;
}

.mail{
  font-family: $RobotoThin;
  margin-bottom: 25px;
  margin-top: 15px;
}

.button{
  margin-bottom: 25px;
}
</style>