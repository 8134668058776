<template lang="pug">
  
  modal(name="modal-analysis" width="760" adaptive=true height="auto" scrollable=true ).modal-send
    .close-button(@click="hide")
      svg(width='23px' height='23px' viewBox='0 0 23 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink')
        g(stroke='none' stroke-width='1' fill='#fff' fill-rule='evenodd')
          rect(transform='translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) ' x='10.3137085' y='-3.6862915' width='2' height='30')
          rect(transform='translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) ' x='10.3137085' y='-3.6862915' width='2' height='30')
    .modal-content
      ValidationObserver(v-slot="{ handleSubmit }")
        form(@submit.prevent="handleSubmit(submitForm)").inputs-block
          h1.h1 Скопируйте сюда ссылку на свое резюме
          .input-wrap
            Input(placeholder='Ссылка на резюме на hh.ru' v-model="link" name="Ссылка" :rules="{required:true, regex: /^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?([a-z0-9]+\.+[hh\.]{1}|[hh\.]{1})+([\\-\\.]{2}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$/}" )
            .note-input Не забудьте открыть резюме для общего доступа. <a href='javascript:void(0);' @click="show = !show">Как это сделать?</a>
          transition(name='fade')
            div(v-if='show')
              .info-block
                img(alt="cvboost" src="@/assets/images/HH-instruction_anima.gif").gif
                ul.info-block__list
                  li.info-block__item
                    | 1. Авторизируйтесь на hh.ru и зайдите в раздел <a href='https://hh.ru/'>Мои резюме</a>.
                  li.info-block__item
                    | 2. Нажмите <span>изменить видимость</span>, отмечайте пункт <span>Видно только по прямой ссылке</span>, внизу страницы не забудьте нажать <span>сохранить</span>
                  li.info-block__item
                    | 3. Чтобы скопировать ссылку, в личном кабинете в разделе <a href='https://hh.ru/'>Мои резюме</a> нажмите на <span>Доступно только по прямой ссылке</span> и скопируйте ссылку в появившемся окне.
          .inputs-wrapper
            .input-wrap
              label.label Профессия
              validation-provider(rules='required' v-slot='{ errors }' name="Профессия")
                div(:class="{ 'errors': errors[0] }")
                  v-select(:options="professionOptions"  placeholder="Выберите из списка"  v-model="profession" :required="!profession")
                  .error-text {{ errors[0] }}
            .input-wrap
              label.label Квалификация
              validation-provider(rules='required' v-slot='{ errors }' name="Квалификация")
                div(:class="{ 'errors': errors[0] }")
                  v-select(:options="qualificationOptions" placeholder="Выберите из списка" v-model="qualification")
                .error-text {{ errors[0] }}
          .checkbox-block
            Checkbox(
              v-model='checkbox'
              :rules='{ required: { allowFalse: false } }'
              label="Соглашаюсь с <a href='#'>политикой конфиденциальности</a>"
              identifier='checkboxResumeModal'
            )
          ButtonSubmit.button-blue.w-100(label='Отправить' type='submit' )
</template>


<script>

import Input from "@/components/elements/Input";
import Button from "@/components/elements/Buttons/Button";
import Checkbox from "@/components/elements/Checkbox";
import {mapActions, mapState} from "vuex";
import ButtonSubmit from "@/components/elements/Buttons/ButtonSubmit";
import axios from "axios";

export default {
  name: "ModalAnalysis",
  components: {ButtonSubmit, Checkbox, Button, Input},
  data() {
    return {
      show: false,
  
      link: localStorage.getItem('link'),
      profession: localStorage.getItem('profession'),
      qualification: localStorage.getItem('qualification'),
      checkbox: true
      
      
    }
  },
  
  mounted() {
    this.$metrika.reachGoal('repeat-resume-submit')
  
    if (localStorage.link) {
      this.link = localStorage.link;
    }
    if (localStorage.profession) {
      this.profession = localStorage.profession;
    }
    
    if (localStorage.qualification) {
      this.qualification = localStorage.qualification;
    }
  },
  watch: {
    link(newLink) {
      localStorage.link = newLink;
    },
    profession(newProfession) {
      localStorage.profession = newProfession;
    },
    qualification(newQualification) {
      localStorage.qualification = newQualification;
    }
  },
  

  methods: {
    ...mapActions([
      'saveLinkAction',
      'saveProfessionAction',
      'saveQualificationAction',
      'saveResumeAction',
      'saveInfoAction',
      'saveLoadingAction',
      'saveErroredAction',
    ]),
    
    submitForm: function () {
      this.$metrika.reachGoal(85906765,'reachGoal','repeat-resume-submit')
  
  
      this.saveLinkAction(this.link)
      this.saveProfessionAction(this.profession)
      this.saveQualificationAction(this.qualification)
      this.saveLoadingAction(true)
  
      const apiKey = 'DD4NMKT-5WW4RNJ-KJNCX9F-M66KAN9'
      
      axios
        .post('https://app.hr-drive.com/api/cv-boost/check', {
            position: this.profession,
            level: this.qualification,
            hhUrl: this.link,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': apiKey
            },
          })
        
        
        .then(
          response => {
            this.saveResumeAction(response.data)
            localStorage.setItem('resume', JSON.stringify(response.data))
            // console.log('response.data', response.data)
          }
        )
        .catch(error => {
          console.log(error);
          this.saveErroredAction(true);
        })
        .finally(() => (this.saveLoadingAction(false)));
      
      
    },
    hide() {
      this.$modal.hide('modal-analysis');
      this.$emit('close')
    }
  },
  computed: {
    
    ...mapState({
      linkShow: state => state.link,
      qualificationShow: state => state.qualification,
      professionShow: state => state.profession,
      professionOptions: state => state.professionOptions,
      qualificationOptions: state => state.qualificationOptions,
      resume: state => state.resume,
  
      loading: state => state.loading,
      errored: state => state.errored,
    }),
    
  },
  
  
}
</script>

<style scoped lang="scss">

.h1 {
  text-align: center;
  color: #000;
  margin-bottom: 15px;
}


.inputs-block {
  max-width: 760px;
  margin: 0 auto;
}

.note-input {
  font-size: 14px;
  line-height: 1.55;
  margin-bottom: 10px;
  color: #a7a7a7;
}

.input-wrap {
  .note-input {
    margin-top: 7px;
    
    a {
      color: #409EFF;
      opacity: 1;
    }
  }
}

.download-resume {
  .button-wrap {
    margin-top: 25px;
  }
}

.info-block {
  background: #F3F7FC;
  border-radius: 5px;
  padding: 40px 25px;
  display: flex;
  margin-bottom: 30px;
  @include tablet {
    flex-direction: column;
  }
}

.gif {
  @include width-flex(47%)
  @include tablet {
    @include width-flex(100%)
    margin-bottom: 25px;
  }
}

.info-block__list {
  margin-left: 35px;
  font-size: 14px;
  line-height: 1.3;
  @include tablet {
    margin-left: 0;
  }
}

.info-block__item {
  margin-bottom: 25px;
  
  a {
    color: #ff8562;
  }
  
  span {
    font-family: $RobotoBold;
  }
}

.checkbox-block {
  margin-bottom: 35px;
}

.inputs-wrapper {
  display: flex;
  justify-content: space-between;
  @include tablet {
    display: block;
  }
  
  .input-wrap {
    @include width-flex(48%)
    @include tablet {
      @include width-flex(100%)
      
    }
  }
}

</style>