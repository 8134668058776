<template lang="pug">
  
  modal(name="modal-reviews" width="560" adaptive=true height="auto" scrollable=true).modal-reviews
    .close-button(@click="hide")
      svg(width='23px' height='23px' viewBox='0 0 23 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink')
        g(stroke='none' stroke-width='1' fill='#fff' fill-rule='evenodd')
          rect(transform='translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) ' x='10.3137085' y='-3.6862915' width='2' height='30')
          rect(transform='translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) ' x='10.3137085' y='-3.6862915' width='2' height='30')
    .modal-head
      .modal-head__bg
    
    .modal-content
      ValidationObserver(v-slot="{ handleSubmit }")
        form(@submit.prevent="handleSubmit(submitFormSend)")#formReviews
          .modal-title Оставьте отзыв о CV Boost
          .radio-btn-group
            .radio
              input(type='radio', name='emoji', value='Не очень', v-model="checkedEmoji")#sad
              label(for="sad")
                img(alt="cvboost" src="@/assets/images/face-sad.png").emoji-img
                | Не очень
            .radio
              input(type='radio', name='emoji', value='Сойдет', v-model="checkedEmoji")#neutral
              label(for="neutral")
                img(alt="cvboost" src="@/assets/images/face-neitral.png").emoji-img
                | Сойдет
            .radio
              input(type='radio', name='emoji', value='Круто!', v-model="checkedEmoji")#smile
              label(for="smile")
                img(alt="cvboost" src="@/assets/images/face-smile.png").emoji-img
                | Круто!
          .input-wrap
            Input(placeholder='Ваше имя' v-model="name" name="Имя" :rules="{required:true}")
          .input-wrap
            Input(placeholder='Ваш e-mail' v-model="email" name="E-mail" :rules="{required:true, regex: /^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$/}")
          .input-wrap
            Textarea(placeholder='Отзыв о CV Boost' v-model="textReviews" name="Отзыв")
          .test-upload
            input(ref="image" type="file" @change="onFileChange")
          //.upload
          //  ul
          //    li(v-for='file in files') {{file.name}} - Ошибка: {{file.error}}, Success: {{file.success}}
          //  file-upload(
          //    ref='upload'
          //    v-model='files'
          //    post-action='/post.method'
          //    put-action='/put.method'
          //    @input-file='inputFile'
          //    @input-filter='inputFilter'
          //    :multiple="true"
          //
          //  )
          //    | Загрузить файлы
          
          ButtonSubmit.button-black.w-100(label='Отправить' type='submit')
          .agree-text «Нажимая „Отправить“ вы даете согласие на обработку персональных данных и соглашаетесь c <a href='#'>политикой конфиденциальности</a>».

</template>


<script>

import Input from "@/components/elements/Input";
import Textarea from "@/components/elements/Textarea";
import ButtonSubmit from "@/components/elements/Buttons/ButtonSubmit";
import axios from "axios";

export default {
  name: "ModalReviews",
  data() {
    return {
      name: '',
      email: '',
      checkedEmoji: '',
      textReviews: '',
      files: [],
      
    }
  },
  components: {ButtonSubmit, Textarea, Input},
  
  mounted() {
    // this.$modal.show('modal-reviews')
  },
  methods: {
    hide() {
      this.$modal.hide('modal-reviews');
    },
    submitFormSend: function () {
      this.$modal.hide('modal-reviews');
      
      // localStorage.name = this.name
      // localStorage.email = this.email
      // localStorage.checkedEmoji = this.checkedEmoji
      // localStorage.textReviews = this.textReviews
      localStorage.files = this.files
  
      // let formData = new FormData();
      // formData.append('name', this.name);
      // formData.append('email', this.email);
      // formData.append('checkedEmoji', this.checkedEmoji);
      // formData.append('textReviews', this.textReviews);
      // formData.append('files', this.files);
      
      console.log('submit files', this.files)
      
      // const apiKey = 'DD4NMKT-5WW4RNJ-KJNCX9F-M66KAN9'
      axios
        // .post('https://hook.integromat.com/cpn5wijc9zgho7j8xj8p3w0xdczs9oel', formData,
        //   {
        //     headers: {
        //       'Content-Type': 'application/json',
        //       // 'x-api-key': apiKey
        //     },
        //   })
        .post('https://hook.integromat.com/cpn5wijc9zgho7j8xj8p3w0xdczs9oel', {
            name: this.name,
            email: this.email,
            checkedEmoji: this.checkedEmoji,
            textReviews: this.textReviews
          },
          {
            headers: {
              'Content-Type': 'application/json',
              // 'x-api-key': apiKey
            },
          })
        
        
        .then(
          response => {
            console.log('response.data', response.data)
          }
        )
        .catch(error => {
          console.log(error);
        })
        
      this.name = this.name= '',
      this.email = this.email = '',
      this.checkedEmoji = this.checkedEmoji = '',
      this.textReviews = this.textReviews = ''
  
  
    },
  
  
    onFileChange() {
      const files = this.$refs.image.files;
      const data = new FormData();
      data.append('logo', files[0]);
      console.log( files[0])
      // return this.files = files[0]
    },
    
    inputFile: function (newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response)
        if (newFile.xhr) {
          //  Get the response status code
          console.log('status', newFile.xhr.status)
        }
      }
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent()
        }
      }
      
      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
    }
  },
}
</script>

<style scoped lang="scss">

.modal-head__bg {
  @include background-cover('cvboost_logo-modal.webp')
  width: 100%;
  height: 205px;
}

.modal-title {
  font-family: $RobotoBold;
  margin-bottom: 30px;
}

.mail {
  font-family: $RobotoThin;
  margin-bottom: 25px;
  margin-top: 15px;
}

.emoji-img {
  width: 90px;
  height: 90px;
}

.button {
  margin-bottom: 25px;
}

.file-uploads {
  color: #ffffff;
  background-color: #000000;
  border-radius: 5px;
  font-size: 16px;
  max-width: 250px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  padding: 5px 20px;
  margin-bottom: 20px;
}

.upload {
  ul li {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  &:hover {
    cursor: pointer;
  }
}


.radio-btn-group {
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
  
  .radio {
    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      border: 1px solid transparent;
      padding: 5px;
      cursor: pointer;
      font-size: 15px;
      font-family: $RobotoRegular;
      color: rgba(0, 0, 0, .8);
      
      .emoji-img {
        margin-bottom: 10px;
      }
    }
    
    input[type="radio"] {
      display: none;
    }
    
    input[type="radio"]:checked + label {
      border-color: #FF8400;
      transition: all 0.5s ease-in;
    }
  }
}


</style>