<template lang="pug">
  .payment-form
    #payment-form

</template>

<script>
import axios from "axios";
import {hostName} from '@/variables'
const uuid = require('uuid');


export default {
  name: "Paid",
  async mounted() {
    const key = uuid.v1()
    const response = await axios.post('https://hook.integromat.com/ezgt48s33dtp5oi0wfzqgl2c9vkr7b3l?key=' + key, {});
    // console.log('response', response.data)
    const checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token: response.data.confirmation.confirmation_token, //Токен, который перед проведением оплаты нужно получить от ЮKassa
      return_url: hostName + 'payment-sucsess?puuid=' + key, //Ссылка на страницу завершения оплаты, это может быть любая ваша страница

      //При необходимости можно изменить цвета виджета, подробные настройки см. в документации
      //customization: {
      //Настройка цветовой схемы, минимум один параметр, значения цветов в HEX
      //colors: {
      //Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
      //controlPrimary: '#00BF96', //Значение цвета в HEX

      //Цвет платежной формы и ее элементов
      //background: '#F2F3F5' //Значение цвета в HEX
      //}
      //},

      error_callback: function (error) {
        console.log(error)
      }


    });

    
    //Отображение платежной формы в контейнере
    checkout.render('payment-form');
    

    
    
  }
  
}
</script>

<style scoped lang="scss">
.payment-form{
  padding-top: 150px;
}
</style>