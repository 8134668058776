<template lang="pug">
  footer.footer
    .footer-block
      .copy
        | © All Right Reserved, 2020. Получите работу мечты с CV Boost
    
    .footer-center
      ul.footer-list
        li.footer-item
          a(href="#").footer-link Контакты
        li.footer-item
          a(href="#").footer-link Юридическая информация
        li.footer-item
          a(href="#").footer-link Пользовательское соглашение
        li.footer-item
          a(href="#").footer-link Политика конфиденциальности
      
    .footer-block

</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">
.footer {
  background-color: #111111;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  @include tablet{
    flex-direction: column-reverse;
  }
}
.copy{
  color: #cccccc;
  font-family: $RobotoMedium;
  font-size: 14px;
  line-height: 1.35;
  @include tablet {
    font-size: 12px;
  }
}
.footer-block {
  @include width-flex(15%)
  @include tablet {
    @include width-flex(100%)
    text-align: center;
    
  }
}

.footer-list{
  display: flex;
  align-items: center;
  @include tablet{
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.footer-link{
  font-size: 14px;
  color: #707070;
  font-family: $RobotoMedium;
  &:hover{
    color: #cccccc;
  }
  @include tablet{
    font-size: 12px;
  }
}

.footer-item{
  margin-left: 40px;
  @include tablet{
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    
  }
}


</style>