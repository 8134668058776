<template lang="pug">
  .description
    .container
      svg.divider(style='fill:#fed831;' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 12')
        path(d='M87.5 12c-3.9 0-5.9-2.8-7.5-5.1C78.4 4.5 77.2 3 75 3s-3.4 1.5-5 3.8-3.6 5.1-7.5 5.1-5.9-2.8-7.5-5.1C53.4 4.5 52.2 3 50 3s-3.4 1.5-5 3.8-3.6 5.1-7.5 5.1-5.9-2.8-7.5-5.1C28.4 4.5 27.2 3 25 3s-3.4 1.5-5 3.8-3.6 5.1-7.5 5.1-5.9-2.7-7.5-5C3.4 4.5 2.2 3 0 3V0c3.9 0 5.9 2.8 7.5 5.1 1.7 2.4 2.8 3.8 5 3.8s3.4-1.5 5-3.8S21.1 0 25 0s5.9 2.8 7.5 5.1c1.7 2.4 2.8 3.8 5 3.8s3.4-1.5 5-3.8S46.1 0 50 0s5.9 2.8 7.5 5.1c1.7 2.4 2.8 3.8 5 3.8s3.4-1.5 5-3.8S71.1 0 75 0s5.9 2.8 7.5 5.1c1.7 2.4 2.8 3.8 5 3.8s3.4-1.5 5-3.8S96.1 0 100 0v3c-2.2 0-3.4 1.5-5 3.8-1.6 2.4-3.6 5.2-7.5 5.2z')
      .description-text(v-html='text')
      svg.divider.divider_rotate(style='fill:#fed831;' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 12')
        path(d='M87.5 12c-3.9 0-5.9-2.8-7.5-5.1C78.4 4.5 77.2 3 75 3s-3.4 1.5-5 3.8-3.6 5.1-7.5 5.1-5.9-2.8-7.5-5.1C53.4 4.5 52.2 3 50 3s-3.4 1.5-5 3.8-3.6 5.1-7.5 5.1-5.9-2.8-7.5-5.1C28.4 4.5 27.2 3 25 3s-3.4 1.5-5 3.8-3.6 5.1-7.5 5.1-5.9-2.7-7.5-5C3.4 4.5 2.2 3 0 3V0c3.9 0 5.9 2.8 7.5 5.1 1.7 2.4 2.8 3.8 5 3.8s3.4-1.5 5-3.8S21.1 0 25 0s5.9 2.8 7.5 5.1c1.7 2.4 2.8 3.8 5 3.8s3.4-1.5 5-3.8S46.1 0 50 0s5.9 2.8 7.5 5.1c1.7 2.4 2.8 3.8 5 3.8s3.4-1.5 5-3.8S71.1 0 75 0s5.9 2.8 7.5 5.1c1.7 2.4 2.8 3.8 5 3.8s3.4-1.5 5-3.8S96.1 0 100 0v3c-2.2 0-3.4 1.5-5 3.8-1.6 2.4-3.6 5.2-7.5 5.2z')
  

</template>

<script>
export default {
name: "DescriptionBlock",
  props: {
    text: {
      type: String,
      default: ''
    },
  },
}
</script>

<style scoped lang="scss">

.divider{
  width: 120px;
  margin: 0 auto;
}

.divider_rotate{
  transform: rotate(180deg);
}

.description{
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #1f1f1f;
}

.description-text{
  font-family: $RobotoThin;
  padding: 44px 0 54px 0;
  line-height: 1.5;
  font-size: 38px;
  color: $white;
  @include tablet{
    font-size: 26px;
    font-family: $RobotoThin;
    padding-top: 18px;
    padding-bottom: 27px;
  }

}

</style>