export default {
  positive: [
    {
      key: 'frontend-JS-frameworks-libs',
      title: 'Мастер JS',
      zerodesc: '<b>В резюме не досстает информации о популярных JavaScript-фреймворках и либах для frontend-разработки — непорядок!</b> Давайте исправим положение: напишите в каких продуктах и с каким стэком вы работали. Что удобнее и быстрее всего помогает вам добиться результата? Можно даже написать с чем пробовали работать, но никогда больше не вернётесь — так HR узнают, по каким позициям вам не нужно звонить.',
      smalldesc: '<b>Вы пишете о фреймворках и либах JS, но стоит раскрыть эту тему побольше.</b> Попробуйте подробнее расписать стэк, любимые и нелюбимые либы, что-то с чем сталкивались в работе и точно можете рекомендовать.',
      tip: '<mark>Мастер JS</mark> - Наши алгоритмы узнали знакомые либы и фреймворки — JS Forever!'
    },
    {
      key: 'gt-java',
      title: 'Java',
      zerodesc: '<b>Если вы знаете Java, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете Java, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме.',
      smalldesc: '<b>Навык знания Java раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с Java. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: JHipster или Maven. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Распишите в деталях.',
      tip: '<mark>Java</mark> – строго типизированный объектно-ориентированный язык программирования общего назначения.'
    },
    {
      key: 'frontend-desktop-app',
      title: 'Настольные приложения',
      zerodesc: '<b>Укажите в резюме свой опыт разработки десктопных приложений или хотя бы условное знакомство с областью.</b> Напишите об инструментах и фреймворках, которые вы использовали — это важно для рекрутера и в целом для понимания ваших навыков.',
      smalldesc: '<b>Раскройте тему с настольными приложениями - сейчас об этом написано мало.</b> Напишите об инструментах и фреймворках, которые вы использовали — это важно для рекрутера и в целом для понимания ваших навыков.',
      tip: '<mark>Настольные приложения</mark> - алгоритмы нашли упоминания о разработке настольных приложений.'
    },
    {
      key: 'frontend-mobile-app',
      title: 'Мобильные приложения',
      zerodesc: '<b>Был ли у вас опыт разработки мобильных приложений? Если да, то напишите об этом.</b> Если у вас был подобный опыт или есть подобные навыки, расскажите об этом в резюме. Обязательно упоминайте названия фреймворков, с которыми работали. Мобильные версии веб-сервисов тоже стоит указать.',
      smalldesc: '<b>Судя по найденным ключевым словам, вы работали с мобильными приложениями — это классно! Напишите поподробнее об этом.</b> Обязательно упоминайте названия фреймворков, с которыми работали. Мобильные версии веб-сервисов тоже стоит указать.',
      tip: '<mark>Мобильные приложения</mark> - Вы упоминали фреймворки JavaScript для разработки мобильных приложений.'
    },
    {
      key: 'frontend-site-generators',
      title: 'SSG',
      zerodesc: '<b>Если вы пользовались генераторами статических сайтов, то стоит упомянуть об этом.</b> Вот пример: "использовался SSG в его Gatsby.js инкарнации + Headless CMS на Ghost.io".',
      smalldesc: '<b>Вы немного писали о static site generators… но ключевое тут слово «немного».</b> Добавьте деталей: конкретные фреймворки, библиотеки, инструменты.',
      tip: '<mark>SSG</mark> - вы говорили о генераторах статических сайтов.'
    },
    {
      key: 'frontend-graphql',
      title: 'GraphQL',
      zerodesc: '<b>Вы не написали о работе с GraphQL — кажется, что это важно.</b> Если у вас всё же был опыт такой работы, о нём обязательно стоит упомянуть!',
      smalldesc: 'Алгоритмы CVBoost нашли в резюме слова о GraphQL — можно раскрыть это еще поподробнее.',
      tip: '<mark>GraphQL</mark> - язык запросов с открытым исходным кодом, разработанный Facebook.'
    },
    {
      key: 'frontend-ssr',
      title: 'SSR',
      zerodesc: '<b>Напишите немного о SSR-фреймворках, с которыми приходилось сталкиваться в работе.</b>',
      smalldesc: '<b>В резюме есть пара слов о SSR — распишите об этом поподробнее.</b> Или не расписывайте. Я веб-сервис, а не полицейский.',
      tip: '<mark>SSR</mark> - Наши алгоритмы нашли слова про рендеринг на стороне сервера.'
    },
    {
      key: 'frontend-seo',
      title: 'Я умею в SEO',
      zerodesc: '<b>В резюме ничего не сказано про SEO-оптимизацию, а это кажется важным навыком для Frontend-разработчика.</b> Если у вас всё же ест опыт работы с SEO, напишите об этом пожалуйста.',
      smalldesc: '<b>Вы немного написали про SEO-оптимизацию, но кажется, стоит раскрыть эту тему подробнее.</b> Перечислите несколько знакомых и любимых инструментов или реальные кейсы, с которыми приходилось сталкиваться.',
      tip: '<mark>Я умею в SEO</mark> - вы писали о SEO оптимизации, а мы это поняли.'
    },
    {
      key: 'frontend-pwas',
      title: 'PWA',
      zerodesc: '<b>Если вы работали с Progressive Web App — напишите об этом (сейчас в вашем резюме на такой опыт ничего не указывает).</b> Если вы не работали с ними на практике, но очень хорошо разобрались в теории и не против попрактиковаться, тоже напишите. Писать лучше всего о конкретных фреймворках и инструментах— они сразу бросаются в глаза.',
      smalldesc: '<b>Вы писали о работе с Progressive Web App, но не очень подробно.</b> Раскройте тему конкретнее. Самый простой вариант: просто перечислите знакомые вам на практике фреймворки и инструменты.',
      tip: '<mark>PWA</mark> - судя по всему, вы знакомы с прогрессивными веб-приложениями.'
    },
    {
      key: 'frontend-testing',
      title: 'Безопасность: Аналитика и Оптимизация',
      zerodesc: '<b>Unit, Integration и Functional тестирования — огромная и очень важная часть работы frontend-разработчика. А по вашему резюме это не видно.</b> Безопасность данных — прежде всего! Напишите о том как писали их и какими инструментами/фреймворками вы пользовались в этом направлении.',
      smalldesc: '<b>Стоит побольше расписать об инструментах, связанных с безопасностью данных.</b> Круто, что вы говорите о безопасности! Если можете, раскройте эту тему шире? Например расскажите больше об инструментах и фреймворках, которые используете для написания тестов.',
      tip: '<mark>Безопасность: Аналитика и Оптимизация</mark> - мы увидели, что вы пишете о тестах и считаем, что вы очень ответственный разработчик.'
    },
    {
      key: 'frontend-css-in-js',
      title: 'CSS в JS',
      zerodesc: '<b>Вы, кажется, ничего не писали о CSS-in-JS.</b> Напишите, если есть что. Например, можно перечислить несколько знакомых библиотек по работе с «CSS-in-JS». Это важно.',
      smalldesc: '<b>В тексте есть информация о том, что вы работали с CSS-in-JS, но наши консультанты считают, что она не раскрыта.</b> Добавьте деталей: либ, фреймворков, хлеба и зрелищ. ;)',
      tip: '<mark>CSS в JS </mark> - мы нашли слова, связанные с CSS in JS.'
    },
    {
      key: 'frontend-build-tools',
      title: 'Build Tools',
      zerodesc: '<b>Вы не рассказали о том какими менеджерами задач предпочитаете пользоваться.</b> Но мы-то знаем, что вам есть что сказать — ведь после работы с инструментами сборки возвращаться к обычному процессу точно не захочется. Напишите об инструментах, которыми пользовались в каждом из проектов, это важно.',
      smalldesc: '<b>Расскажите подробнее про менеджеры задач, которыми вы пользовались. </b>Мы знаем, что после работы с инструментами сборки возвращаться к обычному процессу точно не захочется. Напишите об инструментах, которыми пользовались в каждом из проектов, это важно.',
      tip: '<mark>Build Tools</mark> - автоматизация в JavaScript — это прекрасно. Вы писали про менеджеры задач, мы это увидели и оценили.'
    },
    {
      key: 'frontend-ui',
      title: 'Графический Дизайн',
      zerodesc: '<b>В тексте нет упоминаний о web-дизайне.</b> Довольно часто frontend`ы использует инструменты и практики из этой области. Попробуйте добавить немного о том с какими инструментами, фреймворками и библиоттеками работали и для чего именно их использовали. Также можете рассказать о вашем опыте работы с дизайнерами.',
      smalldesc: '<b>Мы увидели, что вы пишете о дизайне и прототипировании — это то, что нужно!</b> Советуем  вам добавить  чуть больше информации об этом. Попробуйте написать о том, какие инструменты использовали и для чего именно - эта информация очень помогает рекрутеру при отборе.',
      tip: '<mark>Графический Дизайн</mark> - мы нашли слова, связанные с дизайном и протипированием.'
    },
    {
      key: 'frontend-package-management',
      title: 'Package Management',
      zerodesc: '<b>Расскажите, использовали ли вы в работе Package Manager?</b> Package Manager — это утилиты, которые помогают в установке и удалении пакетов, управлении версиями и зависимостями, необходимыми для запуска проекта. Вы наверняка сталкивались с такими программами в работе -  обязательно напишите в резюме с какими сервисами работали.',
      smalldesc: '<b>Расскажите подробнее про использование в работе Package Manager</b> Package Manager — это утилиты, которые помогают в установке и удалении пакетов, управлении версиями и зависимостями, необходимыми для запуска проекта. Вы наверняка сталкивались с такими программами в работе -  обязательно напишите в резюме с какими сервисами работали.',
      tip: '<mark>Package Management</mark> - вы пишете об инструментах управления пакетами.'
    },
    {
      key: 'frontend-web-security',
      title: 'Защита данных',
      zerodesc: '<b>Кажется, вы не написали о мерах соблюдения безопасности.</b> Например, уделите несколько строк в резюме знакомым инструментам для хэширования паролей или обфускации, назовите пару инструментов логирования и мониторинга клиентской части или просто упомяните OWASP. Это покажет рекрутеру уровень вашей ответственности и косвенно укажет на опыт работы с действительно крутыми продуктами.',
      smalldesc: '<b>Вы немного писали о работе с безопасностью приложения, но наши алгоритмы считают, что стоит написать больше.</b> Напишите пару строк, ведь ещё столько тем можно включить: от конфигурации Webpack через хэширование паролей и обфускацию до инструментов логирования и мониторинга клиентской части.',
      tip: '<mark>Защита данных</mark> - в ы пишете о проверке с соблюдении безопасности в приложениях.'
    },
    {
      key: 'frontend-version-control',
      title: 'Контроль версий',
      zerodesc: '<b>Вы ничего не написали о работе с системами контроля версий.</b> Будем честны, этот навык как бы подразумевается в современном мире у каждого специалиста. Но лучше всё равно хотя бы упомянуть какую систему вы используете.',
      smalldesc: '<b>Вы мало написали о работе с системами контроля версий.</b> Будем честны, этот навык как бы подразумевается в современном мире у каждого специалиста. Но лучше всё равно хотя бы упомянуть какую систему вы используете.',
      tip: '<mark>Контроль версий</mark> - мы считаем, что вы работали с системами контроля версий.'
    },
    {
      key: 'frontend-eng',
      title: 'Полиглот',
      zerodesc: '<b>Кажется, вы ничего не указали о своем уровне знания английского языка.</b> Для любого программиста крайне желателен английский язык не ниже upper-intermediate. Советуем упомянуть один из подходящих уровней или упомянуть уровень пониже и немного подучиться.',
      smalldesc: '<b>Кажется, вы мало сказали о своем уровне знания английского языка.</b> Для любого программиста крайне желателен английский язык не ниже upper-intermediate. Советуем упомянуть один из подходящих уровней или упомянуть уровень пониже и немного подучиться.',
      tip: '<mark>Полиглот</mark> - на самом деле, не полиглот конечно. Мы просто нашли у вас инфо об уровне английского.'
    },
    {
      key: 'frontend-db',
      title: 'Базы данных',
      zerodesc: '<b>Нужно уметь работать с базами данных, а у вас в резюме про них ничего нет.</b> Алгоритм не нашел упоминаний Data Bases в вашем резюме. Может вы забыли про них упомянуть? Если у вас был такое опыт – расскажите о нём в деталях. Мы например используем MongoDB и Postgre‍S‍Q‍L.',
      smalldesc: '<b>Кажется, вы умеете работать с базами данных, но стоит расписать об этом подробнее</b> Детализируйте ваш экспириенс. Расскажите о ваших кейсах, как, где и для каких данных вы задействовали data base. Заодно напишите какие именно базы использовали. Не дайте рекрутерам упустить эту деталь.',
      tip: '<mark>Базы данных</mark> – представленная в объективной форме совокупность самостоятельных материалов, систематизированных таким образом, чтобы эти материалы могли быть найдены и обработаны. Примеры баз данных: My‍S‍Q‍L, Postgre‍S‍Q‍L, MS SQL, Apache Derby, Tarantool и т.п.'
    },
    {
      key: 'html-css',
      title: 'Верстка на 5+',
      zerodesc: '<b>Судя по всему, вы не написали ничего про опыт в верстке: HTML и CSS — это вы зря.</b> О таких навыках писать стоит в любом случае. Советуем добавить в описание рабочих задач немного инструментов, фреймворков с которыми работали и просто слова "CSS" и "HTML" - рекрутеры часто пробегают резюме "по диагонали" и это то, что обязательно должно задержать их взгляд.',
      smalldesc: '<b>Вы пишете о верстке, но желательно раскрыть это подробнее.</b> Напишите чуть больше об используемых инструментах и фреймворках в рамках каждого продукта, в котором вы работали. Это точно поможет рекрутеру.',
      tip: '<mark>Верстка на 5+</mark> - вы писали о HTML и CSS так, что даже роботу CVBoost стало интересно.'
    },
    {
      key: 'gt-php',
      title: 'PHP',
      zerodesc: '<b>Если вы знаете PHP, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете PHP, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме.',
      smalldesc: '<b>Навык знания PHP раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с PHP. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: Purl или Ratchet. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Распишите в деталях.',
      tip: '<mark>PHP</mark> – скриптовый язык общего назначения, применяемый для разработки веб-приложений. Поддерживается большинством хостинг-провайдеров и является одним из лидеров среди языков, применяющихся для создания динамических веб-сайтов.'
    },
    {
      key: 'gt-javascript',
      title: 'JavaScript',
      zerodesc: '<b>Если вы знаете JavaScript, стоит написать об этом, особенно если вам интересно решать задачи с его применением.</b> Если вы знаете JavaScript, но не хотите с ним работать и получать вакансии, то лучше не упоминать его в резюме.',
      smalldesc: '<b>Навык знания JavaScript раскрыт недостаточно. Расширьте описания опыта связанного с этим языком.</b> Увеличьте количество упоминаний всего, что связано с JavaScript. Во скольких проектах вы использовали этот язык? Что с помощью него реализовали? Почему выбрали его? Не забудьте упомянуть какие библиотеки и фреймворки использовали. Например: React или JQuery. Это кратно увеличит интерес рекрутеров к вашей кандидатуре. Постарайтесь расписать в деталях.',
      tip: '<mark>JavaScript</mark> – мультипарадигменный язык программирования. Поддерживает объектно-ориентированный, императивный и функциональный стили. Является реализацией стандарта ECMAScript. JavaScript обычно используется как встраиваемый язык для программного доступа к объектам приложений.'
    },
    {
      key: 'gt-qa',
      title: 'Quality Assurance',
      zerodesc: '<b>Расскажите в резюме, как вы обеспечивали качество разрабатываемого ПО.</b> Может вы использовали TDD подход? Или всё покрывали Unit тестами?',
      smalldesc: '<b>В вашем резюме есть упоминание о методах тестирования или инструментах для него — расскажите поподробнее.</b> Кажется об этом написано всего пара упоминаний. Напишите пожалуйста ещё об этом опыте, нам кажется, сейчас маловато информации. Часто пишут про "Модульное тестирование, проведение тестирований, нагрузочное тестирование, регрессионный анализ".',
      tip: '<mark>QA</mark> – это процесс обеспечения качества программного продукта на всех этапах разработки, но на просторах СНГ часто этот термин применяется относительно тестирования ПО.'
    },
    {
      key: 't-shaped',
      title: 'Универсальный герой',
      zerodesc: '<b>Есть ощущение, что вы всю жизнь шли прямо вперёд, чтобы развиваться как узконаправленный специалист — это здорово!</b> Но если в вашей жизни был и другой опыт, не стесняйтесь, напишите об этом. Людей которые имеют глубокую экспертизу в одной области и широкий спектр знаний в других, называют T-shaped специалистом и, конечно же, очень ценят на рынке.',
      smalldesc: '<b>Судя по резюме, у вас есть экспертиза в разных сферах.</b> Людей которые имеют глубокую экспертизу в одной области и широкий спектр знаний в других, называют T-shaped специалистами. Сейчас к этому стремится вся digital-индустрия. Чем больше вы напишете о сферах, где вы компетентны и чем глубже раскроете, тем более "T" образной персоной будете считаться. Но не переборщите с нерелевантным профессии опытом — можно показаться недостаточно компетентным в своём основном поле.',
      tip: '<mark>Универсальный герой</mark> - такой навык получают те, у кого есть глубокая экспертиза в одном вопросе и широкий спектр знаний в других, так называемые T-shaped специалисты.'
    },
    {
      key: 'made-myself',
      title: 'Ориентация на результат',
      zerodesc: '<b>Кажется, в тексте вы много говорите о том, что ДЕЛАЛИ, но не говорите о том, что СДЕЛАЛИ.</b> Рекрутеры часто обращают на это внимание. Попробуйте переформулировать текст о ваших позициях, исходя из результатов - что было сделано. Для этого используйте слова вроде "создал(а)”, "внедрил(а)” или "оптимизировал(а)”',
      smalldesc: '<b>Кажется, вы много пишете о своих задачах, а о результатах значительно меньше.</b> Попробуйте переписать те куски, где пишете формулировки вроде "я делал(а)” так, чтобы получалось "я сделал(а)”. Для этого используйте слова вроде "реализовал(а)”, "организовал(а)” или "улучшил(а)”',
      tip: '<mark>Ориентация на результат</mark> - это важное качество для любого независимого специалиста. Можно показать свою целеустремленность, немного изменив формулировки в резюме'
    },
    {
      key: 'exp-startup',
      title: '"Покоритель стартапов"',
      zerodesc: '<b>Расскажите принимали ли вы участие в создании и развитии стартапов.</b> Если вдруг одна из компаний, которые перечислены в вашем опыте работы, была стартапом, напишите об этом прямо — это может быть большим плюсом для рекрутера, т.к. частенько в стартапах работать сложнее, нагрузка больше, а опыт, соответственно, ценнее. "Опыт работы в стартапах, во внутренних стартапах-стримах крупных корпораций, общении с финансовыми службами, построения юнит-экономики, проведении презентаций и защиты проектов перед стейкхолдерами”.',
      smalldesc: '<b>Наши алгоритмы решили, что у вас есть опыт работы в стартапах — расскажите об этом поподробнее.</b> Напишите ещё пару слов об этом опыте — кажется, что сейчас маловато информации. Например так: "Запустили на международные рынок два собственных стартапа, (IT, медицина, биомедицина, нано- технологии). Консультировала 60+ стартапов по CustDev, клиентскому опыту, разработке продуктов, бизнес-модели и монетизации”.',
      tip: '<mark>"Покоритель стартапов"</mark> – кажется, вы работали в стартапах. Неплохо!'
    },
    {
      key: 'gt-scrum',
      title: 'Мастер гибких методологий',
      zerodesc: '<b>Если в одной из ваших команд вы применяли гибкие методологии, обязательно напишите об этом!</b> Можно как-то так: "При построении и развитии продуктов использую методики lean, JTBD, HADI, GrowthHacking, CustDev, прорабатываю пользовательские сценарии, анализирую рынок, конкурентов, связи продуктовых метрик и бизнеса; Имею практический опыт работы по методологии Lean/Agile”. Но можно и проще: "Тесное взаимодействие с командой разработчиков (в качестве Product Owner) по Scrum”.',
      smalldesc: '<b>По резюме видно, что вы пробовали работать в гибких методологиях и вам не понаслышке знаком фреймворк Scrum — напишите подробнее об этом.</b> Многие команды посвящают много времени выстраиванию рабочих процессов, поэтому для них это может быть важно. Попробуйте, например, подчеркнуть вашу роль в скрам-команде. Также можно упомянуть какие из мероприятий скрама по-вашему являются наиболее эффективными, а от каких можно отказаться. Можно рассказать о других гибких методологиях, которые вы использовали.',
      tip: '<mark>Мастер гибких методологий</mark> - вы знаете чем отличается Scrum от Kanban, а Agile для вас - не пустой звук, а песня!'
    },
    {
      key: 'achievements',
      title: 'Достижения',
      zerodesc: '<b>В вашем резюме нет упоминания, что вы чего-то достигли или добились. Работодатели любят результативных, а не процессовых.</b> Переформулируйте текст о ваших успехах (или напишите его) и постарайтесь рассказать с точки зрения вашей личной результативности. Поменьше используйте слово "участвовал".',
      smalldesc: '<b>Вы упоминаете о своих достижениях, но, кажется, делаете недостаточно акцента на себе.</b> Переформулируйте текст о ваших успехах и постарайтесь рассказать с точки зрения вашей личной результативности — чего именно вы достигли, за какой срок и за счет каких навыков.',
      tip: '<mark>Достижения</mark> – упоминания про личные результаты, успехи и достижения.'
    },
    {
      key: 'methodologies',
      title: 'Методологии разработки',
      zerodesc: '<b>Пропишите в резюме какие методологии разработки вы использовали и о каких знаете (изучали).</b> Например: Scrum, Kanban, Lean Startup. Большинство современных компаний ценят такие знания, а ещё больше опыт построения. Это помогает быстрее влиться в процесс.',
      smalldesc: '<b>Видим, что пишите про методологии разработки. Это круто. Углубитесь немного в описании, расширьте количество упоминаний.</b> Для чего какие методологии использовали? Почему выбрали именно их и т.д. Ну вы поняли.',
      tip: '<mark>Методологии разработки</mark> – упоминания и отсутствие упоминаний методологий разработки.'
    },
    {
      key: 'github',
      title: 'GitHub',
      zerodesc: '<b>Добавьте в резюме ссылку на ваш GitHub (или на другой хостинг проектов, где хранятся ваши ветки).</b> Один из важнейших аспектов на который обращает внимание работодатель при поиске разработчиков – это его код. Если же вы не храните свой код на хостингах, то самое время начать – это ваше портфолио.',
      smalldesc: '<b>Ссылки на хостинг проектов – хорошо и правильно! Респект.</b>',
      tip: '<mark>GitHub</mark> – крупнейший веб-сервис для хостинга IT-проектов и их совместной разработки.'
    },
    {
      key: 'from-scratch',
      title: 'Разработка проекта с нуля',
      zerodesc: '<b>Расскажите — пробовали ли вы когда-нибудь развивать проект с нуля? </b>Если у вас был опыт развития продукта от идеи через MVP до взрослого работающего продукта, то обязательно напишите об этом опыте: расскажите о деталях управления, customer development, основных метриках и аналитике.',
      smalldesc: '<b>Пробовали запускать проект c нуля? Начинали разработку продукта? Напишите об этом детальнее!</b> Расскажите почему не получилось (если не получилось) и какие выводы вы сделали. Это многое расскажет о вас и поможет найти максимально подходящее предложение.',
      tip: '<mark>"С нуля"</mark> – разработка проекта с нуля, от идеи до реализации.'
    },
    {
      key: 'api-integration',
      title: 'API и интеграции',
      zerodesc: '<b>Для вашей профессии характерен опыт использования API и интеграций. Но у вас в резюме ничего про это нет.</b> Постарайтесь вспомнить, может быть вы сталкивались с задачами интеграций одного сервиса в другой? Или расширяли функционал продукта при помощи интерфейса программирования другого продукта? Распишите весь похожий опыт.',
      smalldesc: '<b>Вы указываете про API или интеграции – опишите, какие сервисы и с чем приходилось использовать?</b> Расскажите детальнее. Опишите реальные случаи. Обратите внимание рекрутера на это вашим текстом.',
      tip: '<mark>API и интеграции</mark> – в резюме упоминаются микросервисы, интеграция систем, интерфейсы программирования и т.п.'
    },
    {
      key: 'teamlead',
      title: 'Teamlead',
      zerodesc: '<b>Приходилось ли вам когда-нибудь управлять командой разработчиков? Если такой опыт имеется, расскажите об этом в резюме не дайте рекрутерам упустить вас.</b> Это большой плюс к вашей кандидатуре (и зарплате).',
      smalldesc: '<b>Слушайте, кажется по вашему резюме вы были тимблидом или выполняли функционал.</b> Как-то маловато об этом инофрмации. Расскажите подробнее, не дайте рекрутерам упустить вас. Это большой плюс к вашей кандидатуре (и зарплате).',
      tip: '<mark>Teamlead</mark> – высококвалифицированный программист, который знает, как управлять другими программистами. Он уже не пишет код своими руками и не думает над тем, как реализовать ту или иную функцию. Вместо этого он занимается распределением нагрузки на команду, следит за ходом проекта и берёт на себя ответственность за проект в целом.'
    }
  ],
  negative: [
    {
      key: 'bouncer',
      title: '"Попрыгунчик"',
      desc: '<b>Вы часто меняли работу после непродолжительного периода. Обязательного опишите в резюме причины почему так происходило.</b> Возможно это были проектные контракты или осознание того, что это не ваше место? Это нормально, расскажите об этом. Иначе, работодатель посчитав, что вы проблемный кандидат, решит не связыватся c вами.',
      tip: '<mark>"Попрыгунчик"</mark> - часто меняющий места работы после непродолжительного периода.'
    },
    {
      key: 'job-gaps',
      title: 'Перерывы в работе',
      desc: '<b>Кажется, у вас были перерывы в работе больше 3-х месяцев. Это может смутить HR-а.</b> Хорошая идея: отметить это время как работу на себя и прописать каким полезным в работе навыкам вы в этот момент обучились, в чём стали сильнее. Вот отличный пример: "Компания: Декрет и отпуск по уходу за ребенком. Должность: мама и проектный менеджер. Описание: Во время отпуска по уходу за ребенком создала несколько занятных проектов: 1. Переработка сайта компании, торгующей немецкой кухонной фурнитурой - весь дизайн и функции. Начиная со сбора требований заказчика, до ТЗ на дизайн и разработку, через взаимодействие с подрядчиками. 2. Организация собственных курсов для кормящих и занятий послеродовой йогой. Благодаря этому проекту удалось получить опыт в Customer development, SMM, email-маркетинге, создании и аб-тестировании разных версий лендингов, составлении ТЗ на рекламные макеты.',
      tip: '<mark>Перерывы в работе</mark> – наличие в резюме более 3 месяцев перерывов в работе.'
    },
    {
      key: 'lev-tolstoy',
      title: '"Лев Толстой"',
      desc: '<b>У вас в тексте есть довольно длинные предложения, восприятие которых бывает затруднительно. </b> Особенно в случае, когда обработка информации происходит ближе к концу рабочего дня, а рекрутер устал… Постарайтесь разбить текст на более читаемые блоки, можно даже визуально разделить их переносом строки — так удобнее воспринимать большой объем информации.',
      tip: '<mark>"Лев Толстой"</mark> – в резюме присутствуют большие, сложные для восприятия куски текста.'
    },
    {
      key: 'small-experience',
      title: '"Зелёный ещё"',
      desc: '<b>По вашему резюме кажется, что у вас мало релевантного опыта. Может быть, вы что-то забыли?</b> По себе знаем, сложно что-либо написать про работу, если релевантного опыта особо и не было. В таком случае можно описать другой ваш опыт, который давал вам необходимые в работе навыки. Если вы только выпустились, но были старостой группы и отвечали за посещаемость - замечательно, расскажите чему вы научились! Участвовали в конкурсе стипендий и выиграли - чудесно, это тоже навык, очень похоже на получение проектов в тендерной системе! Если же вы давно начали карьеру, но сейчас решили совершить крутой поворот, попробуйте найти в вашем прошлом опыте навыки, релевантные для текущей должности. Например, врач-хирург, который решил пойти в бизнес-аналитики, точно может писать о навыках глубокого анализа информации(изучение анамнеза) и принятия решений в ситуации давления/стресса.',
      tip: '<mark>"Зелёный ещё"</mark> – общий опыт работы небольшой. Менее трёх лет. Но это не страшно, мы уверены, что вам есть чем заполнить пустые клетки!'
    },
    {
      key: 'cliche',
      title: 'Клише',
      desc: '<b>Стоит избавляться от шаблонных фраз в резюме — многих рекрутеров это отталкивает.</b> Это фразы типа "активная жизненная позиция".',
      tip: '<mark>Клише</mark> - мы нашли в резюме шаблонные фразы, от которых лучше избавиться.'
    },
    {
      key: 'exp-perfectionist',
      title: 'Перфекционист',
      desc: '<b>Стоит убрать из резюме упоминания перфекционизма и «идеального» результата.</b> Перфекционисты тормозят процессы в стартапах, но классно дают качественную работу, нужную для сложных "водопадных" проектов и качественных продуктов на заказ. То есть, перфекционизм сильно влияет на скорость работы и рекрутеры об этом знают. Если вы метите в стартап, маленькую, молодую, или просто быстро растущую компанию, лучше так не писать. Если ваше место в спокойной, стабильной компании-гиганте и её огромной команде, оставляйте весь перфекционизм на месте!',
      tip: '<mark>Перфекционист</mark> - болезненное стремление сделать работу идеально, из-за чего могут тормозиться процессы в гибких методологиях.'
    },
    {
      key: 'freelance',
      title: 'Freelance',
      desc: '<b>Вы упоминаете работу на фрилансе — стоит рассказать о причинах, по которым вы фрилансили</b> Многие HR-ы не очень любят фрилансеров. Будет полезно рассказать, почему вы решили пофрилансить. Пример: "Захотел путешествовать, но финансовой подушки не хватало и т.п.". Если вы хотите фриланс и у вас указан только фриланс, то не обращайте внимание на эту рекомендацию. Удачи!',
      tip: '<mark>Freelance</mark> – а резюме есть упоминание фриланса.'
    },
    {
      key: 'junior',
      title: 'Из Junior в князи',
      desc: '<b>Нашим алгоритмам показалось, что ваш переход от Junior позиции к Senior произошёл очень быстро</b> Возможно, имеет смысл прописать более постепенный ход событий или как-то объяснить такой серьёзный рост за такое маленькое время. Например, если вы работали в одной компании 7 лет и прошли путь от junior через Middle к Senior и на следующем месте работы начали сразу с Senior позиции, стоит прописать изменение должностей на месте работы, где состоялся ваш рост.',
      tip: '<mark>Из Junior в князи</mark> - быстрый переход от junior позиций к более высоким без промежуточного звена.'
    },
    {
      key: 'unemployed',
      title: 'Безработный',
      desc: '<b>Кажется, вы сейчас нигде не работаете, HR-ов это насторожит, так что лучше рассказать почему так вышло.</b> Например: "Выгорел на прошлой работе, ушёл вникуда чтобы отдохнуть. Теперь настало время камбэкнуться в полную силу".',
      tip: '<mark>Безработный</mark> – на момент размещения резюме.'
    }
  ]
}
