<template lang="pug">
  div
    .sucsses-payment Успешно !
</template>

<script>

import {hostName, PAYMENT_COOKIE, PAYMENT_DAYS} from '@/variables'


export default {
  name: "PaidSucsess",
  data() {
    return {
    }
  },
  mounted() {
    
    function setCookie(name, value, days) {
      const date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      const expires = `expires=${date.toUTCString()}`
      const domain = window.location.hostname
      document.cookie = `${name}=${value};${expires};path=/;domain=${domain}`
    
    }
    
    function main() {
      const searchParams = new URLSearchParams(document.location.search)
      if (searchParams.get('puuid')) {
        setCookie(PAYMENT_COOKIE, Date.now(), PAYMENT_DAYS)
        location.href = hostName + 'app'
      }
    }
    
    main()
  },
  

}





</script>

<style scoped lang="scss">

.sucsses-payment{
  padding-top: 200px;
  text-align: center;
  font-size: 100px;
  font-family: $RobotoBold;
  color: #67C23A;
  
}

</style>