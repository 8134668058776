<template lang="pug">
  nav.header(ref="header")
    .header-left
      router-link(
        to="/"
      )
        img(alt="cvboost logo" src="@/assets/images/logo.svg").logo
    slot(name="header-right")


</template>

<script>
import HeaderLink from "@/components/landing/HeaderLink";

export default {
  name: "Header",
  components: {
    HeaderLink
  },
  
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      if (window.pageYOffset > 200) {
        this.$refs.header.classList.add('header-fixed');
      } else {
        this.$refs.header.classList.remove('header-fixed');
      }
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  }
}
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10000;
  transition: 1s;
  @include tablet{
    background: #111;
  }
}

.header-fixed {
  background-color: rgba(0, 0, 0, 0.6);
  transition: 1s;
  @include tablet{
    background: #111;
  }
}

.logo {
  width: 120px;
  height: 27px;
}

.header-left {
  padding-left: 40px;
  @include tablet{
    padding-left: 15px;
  }
}

.header-right {
  padding-right: 40px;
  @include tablet{
    padding-right: 15px;
  }
}


.header-right__item {
  margin-left: 15px;
  position: relative;
  
}

.header-right__list {
  display: flex;
  align-items: center;
  @include tablet{
    display: none;
  }
}



</style>