<template lang="pug">
  
  modal(name="modal-boring" width="760" adaptive=true height="auto" scrollable=true).modal-send
    .close-button(@click="hide")
      svg(width='23px' height='23px' viewBox='0 0 23 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink')
        g(stroke='none' stroke-width='1' fill='#fff' fill-rule='evenodd')
          rect(transform='translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) ' x='10.3137085' y='-3.6862915' width='2' height='30')
          rect(transform='translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) ' x='10.3137085' y='-3.6862915' width='2' height='30')
    .modal-content
      .modal-text Заскучали? А давайте разбавим эту тишину скидкой 50% на проверку вашего резюме CV Boost! Скопируйте его и введите в окошке оплаты:
      .promo BORING50
      Button.button-orange(label='Ладно' @click="hide")

</template>


<script>

import Input from "@/components/elements/Input";
import Textarea from "@/components/elements/Textarea";
import Button from "@/components/elements/Buttons/Button";
export default {
  name: "ModalBoring",
  components: {Button, Textarea, Input},
  mounted () {

  },
  methods: {
    hide () {
      this.$modal.hide('modal-boring');
    },
    showModal: function () {
      setTimeout(function () {
        this.$modal.show('modal-boring');
      }.bind(this), 60000)
    },
 
  
  
  },
}
</script>

<style scoped lang="scss">

.modal-head__bg{
  @include background-cover('cvboost_logo-modal.webp')
  width: 100%;
  height: 205px;
}

.mail{
  font-family: $RobotoThin;
  margin-bottom: 25px;
  margin-top: 15px;
}
::v-deep{
  .button{
    font-size: 20px;
    padding: 20px 60px;
  }
}

.button-wrap{
  margin-bottom: 25px;
}


.modal-text{
  font-size: 24px;
  color: #2F394A;
  margin-bottom: 45px;
}

.promo{
  padding: 28px 40px;
  border: 4px solid #FF8400;
  max-width: 411px;
  margin: 0 auto 45px auto;
  font-size: 37px;
  font-family: $RobotoMedium;
  color: #FF8400;
  @include tablet {
    font-size: 25px;
  }
}






</style>