<template lang="pug">
  .button-wrap(@click="handleClick")
    button.button(
      v-html="label"
      :type='type'
    )

</template>

<script>
export default {
  name: "ButtonSubmit",
  props: {
    label: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: ''
    },

  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    }
  }
  
  
}
</script>

<style scoped lang="scss">

@import "ButtonStyles";

</style>