export function getCookie(name) {

  /* eslint-disable no-useless-escape */
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));


  return matches ? true : false;
}

