<template lang="pug">
  modal(name="modal-paid" width="760" adaptive=true height="auto" scrollable=true).modal-send
    .close-button(@click="hide")
      svg(width='23px' height='23px' viewBox='0 0 23 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink')
        g(stroke='none' stroke-width='1' fill='#fff' fill-rule='evenodd')
          rect(transform='translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) ' x='10.3137085' y='-3.6862915' width='2' height='30')
          rect(transform='translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) ' x='10.3137085' y='-3.6862915' width='2' height='30')
    .modal-content
      .img-content__wrap
        img(alt="cvboost" src="@/assets/images/no-payment.svg").img-content
      Button.button-blue.blue_access.button-radius.w-100.button-pay(@click="ym(85906765,'reachGoal','modal-paid-link')" label='<span>Открыть все рекомендации</span> <span class="price-pay">249 ₽</span>' scroll=false link='/payment')
      //.promo-wrap
        input(type="text").promo
      .access-block
        .title-small Что дает платный доступ:
        ul.access-list.access-list_star
          li.access-item 30 дней доступа ко всем функциям сервиса!
          li.access-item Все персональные рекомендации для твоего резюме (29 штук)
          li.access-item Список навыков, которыми (судя по резюме) ты владеешь.
          li.access-item Список навыков, которые нужно подтянуть или изучить


</template>


<script>

import Input from "@/components/elements/Input";
import Textarea from "@/components/elements/Textarea";
import Button from "@/components/elements/Buttons/Button";

export default {
  name: "ModalPaid",
  components: {Button, Textarea, Input},
  mounted () {
    this.$modal.hide('modal-paid')
    this.$metrika.reachGoal('modal-paid-link')
    this.$gtag.event('ga-modal-paid', {
      'event_category': 'ga-paid',
      'event_label': 'ga-paid modal clicked',
      'value': 2
    });
  
  },
  methods: {
    hide () {
      this.$modal.hide('modal-paid');
    },
    
  },
}
</script>

<style scoped lang="scss">
.img-content__wrap,
.promo-wrap{
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}


.button-wrap{
  margin-bottom: 25px;
  font-size: 20px;
  ::v-deep{
    .button{
      &:before{
        display: block;
        content: '';
        @include background-contain('cv-btn.svg')
        width: 34px;
        height: 36px;
        position: absolute;
        top: 50%;
        margin-top: -18px;
        left: 15px;
      }
      @include tablet{
        &:before {
          display: none;
        }
      }
    }
  }

}

.promo{
  text-align: center;
  padding: 8px 30px;
  border: 1px solid #BBC7DB;
  border-radius: 5px;
}




</style>