export default {
  backend: {
    senior: {
      salary: {
        min: 145000,
        max: 275000
      },
      chartUrl: 'https://static.tildacdn.com/tild3338-6431-4535-b137-316264646564/backend-senior.svg'
    },
    middle: {
      salary: {
        min: 104000,
        max: 200000
      },
      chartUrl: 'https://static.tildacdn.com/tild3463-3363-4637-a230-386633633232/backend-middle.svg'
    },
    junior: {
      salary: {
        min: 50000,
        max: 125000
      },
      chartUrl: 'https://static.tildacdn.com/tild3362-3564-4339-a633-343734626638/backend-junior.svg'
    }
  },
  frontend: {
    senior: {
      salary: {
        min: 138000,
        max: 260000
      },
      chartUrl: 'https://static.tildacdn.com/tild3133-3033-4836-b261-633031656138/frontend-senior.svg'
    },
    middle: {
      salary: {
        min: 95000,
        max: 180000
      },
      chartUrl: 'https://static.tildacdn.com/tild6662-3066-4762-b864-366638376334/frontend-middle.svg'
    },
    junior: {
      salary: {
        min: 50000,
        max: 120000
      },
      chartUrl: 'https://static.tildacdn.com/tild3130-6337-4330-b134-373533326330/frontend-junior.svg'
    }
  },
  fullstack: {
    senior: {
      salary: {
        min: 120000,
        max: 250000
      },
      chartUrl: 'https://static.tildacdn.com/tild3933-6437-4537-a539-343631393961/fullstack-senior.svg'
    },
    middle: {
      salary: {
        min: 80000,
        max: 170000
      },
      chartUrl: 'https://static.tildacdn.com/tild3664-3338-4233-b264-363363656261/fullstack-middle.svg'
    },
    junior: {
      salary: {
        min: 45000,
        max: 120000
      },
      chartUrl: 'https://static.tildacdn.com/tild3333-3535-4431-a630-663061613664/fullstack-junior.svg'
    }
  },
  designer: {
    senior: {
      salary: {
        min: 80000,
        max: 180000
      },
      chartUrl: 'https://static.tildacdn.com/tild6365-3533-4638-b762-623963396138/designer-senior.svg'
    },
    middle: {
      salary: {
        min: 40000,
        max: 130000
      },
      chartUrl: 'https://static.tildacdn.com/tild3230-3439-4363-b635-393939323734/designer-middle.svg'
    },
    junior: {
      salary: {
        min: 40000,
        max: 82000
      },
      chartUrl: 'https://static.tildacdn.com/tild6663-3864-4637-b835-353563373065/designer-junior.svg'
    }
  },
  product: {
    senior: {
      salary: {
        min: 70000,
        max: 235000
      },
      chartUrl: 'https://static.tildacdn.com/tild6433-6531-4634-b964-303230383761/product-senior.svg'
    },
    middle: {
      salary: {
        min: 60000,
        max: 170000
      },
      chartUrl: 'https://static.tildacdn.com/tild6330-3135-4133-b433-336533326263/product-middle.svg'
    },
    junior: {
      salary: {
        min: 45000,
        max: 126000
      },
      chartUrl: 'https://static.tildacdn.com/tild3836-3465-4638-a131-326134626432/product-junior.svg'
    }
  },
  analyst: {
    senior: {
      salary: {
        min: 95000,
        max: 217000
      },
      chartUrl: 'https://static.tildacdn.com/tild6635-3964-4734-b138-356361616435/analyst-senior.svg'
    },
    middle: {
      salary: {
        min: 63000,
        max: 140000
      },
      chartUrl: 'https://static.tildacdn.com/tild3365-3363-4132-b831-326531323734/analyst-middle.svg'
    },
    junior: {
      salary: {
        min: 37000,
        max: 120000
      },
      chartUrl: 'https://static.tildacdn.com/tild6339-3931-4635-a231-656131393138/analyst-junior.svg'
    }
  }
}
