import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueSmoothScroll from 'vue-smooth-scroll'
import VModal from 'vue-js-modal'
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VTooltip from 'v-tooltip'
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import ru from 'vee-validate/dist/locale/ru.json';
const VueUploadComponent = require('vue-upload-component')



// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('ru', ru);



Vue.component('file-upload', VueUploadComponent)


extend('positive', value => {
  if (value >= 0) {
    return true;
  }

  return 'This field must be a positive number';
});



Vue.use(VTooltip, {
  defaultPlacement: 'bottom-end',
  // defaultTrigger: 'click',
  defaultClass: 'tooltip-landing',
})

Vue.use(
  VueLodash, {
  name: '__' ,
  lodash: lodash
})

Vue.component('v-select', vSelect)

Vue.use(VModal)

Vue.use(vueSmoothScroll)

Vue.config.productionTip = false

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
