import Vue from 'vue'
import Vuex from 'vuex'
import {getCookie} from "@/helpers/helper";
import {PAYMENT_COOKIE} from '@/variables'


Vue.use(Vuex)

// import data from "@/db.json"




export default new Vuex.Store({
  state: {
    resume: JSON.parse(localStorage.getItem('resume') || null),
    loading: false,
    errored: false,
    link:'',
    profession:'',
    qualification:'',
    professionOptions: [
      'Product Owner / Manager',
      'Fullstack-разработчик',
      'Frontend-разработчик',
      'Backend-разработчик',
      'Дизайнер',
      'Аналитик',
    ],
    qualificationOptions: [
      'Junior',
      'Middle',
      'Senior',
    ],
    paid: getCookie(PAYMENT_COOKIE),

  },
  mutations: {
    saveLink(state, payload) {
      state.link = payload;
    },

    savePaid(state, payload) {
      state.paid = payload;
    },
    saveProfession(state, payload) {
      state.profession = payload;
    },
    saveQualification(state, payload) {
      state.qualification = payload;
    },

    saveResume(state, payload) {
      state.resume = payload;
    },


    saveLoading(state, payload) {
      state.loading = payload;
    },
    saveErrored(state, payload) {
      state.errored = payload;
    },

  },
  actions: {
    savePaidAction({commit}, payload) {
      commit('savePaid', payload);
    },

    saveLinkAction({commit}, payload) {
      commit('saveLink', payload);
    },
    saveProfessionAction({commit}, payload) {
      commit('saveProfession', payload);
    },
    saveQualificationAction({commit}, payload) {
      commit('saveQualification', payload);
    },

    saveResumeAction({commit}, payload) {
      commit('saveResume', payload);
    },


    //
    saveInfoAction({commit}, payload) {
      commit('saveInfo', payload);
    },
    saveLoadingAction({commit}, payload) {
      commit('saveLoading', payload);
    },
    saveErroredAction({commit}, payload) {
      commit('saveErrored', payload);
    },


  },
  modules: {
  }
})
