import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueGtag from "vue-gtag";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    meta: {layout: 'landing', basePages:true},
    component: Main
  },
  {
    path: '/service',
    name: 'Service',
    meta: {layout: 'landing', serviceSettings: true},
    component: () => import(/* webpackChunkName: "about" */ '../views/Service.vue')
  },
  {
    path: '/app',
    name: 'Analysis',
    meta: {layout: 'app', basePages:false},
    component: () => import(/* webpackChunkName: "about" */ '../views/Analysis.vue')
  },
  {
    path: '/payment',
    name: 'Paid',
    meta: {layout: 'app', basePages:false},
    component: () => import(/* webpackChunkName: "about" */ '../views/Paid.vue')
  },
  {
    path: '/payment-sucsess',
    name: 'PaidSucsess',
    meta: {layout: 'app', basePages:false},
    component: () => import(/* webpackChunkName: "about" */ '../views/PaidSucsess.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


Vue.use(VueYandexMetrika, {
  // id: 85551094,
  id: 85906765,
  router: router,
  env: 'production',

  defer: true,
  clickmap:true,
  trackLinks:true,
  accurateTrackBounce:true
})


Vue.use(VueGtag, {
  // config: { id: "G-DTQVMTEGT6" }
  config: { id: "G-GCW64F3B4E" }
}, router);





export default router
