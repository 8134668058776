<template lang="pug">
  validation-provider(:rules='rules' :name='name || label' v-slot='{ errors, required }')
    label.wrapper(:for='identifier' :class="{ 'errors': errors[0] }")
      .label(v-html="label" )
      input.checkbox(:id='identifier' v-model='innerValue' :value='identifier' type='checkbox' ref='input')
      span.checkmark
</template>

<script>

export default {
  name: 'Checkbox',
  props: {
    vid: {
      type: String,
      default: undefined
    },
    identifier: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    value: {
      type: null,
      default: ""
    },
    checked: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    innerValue: null
  }),
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
}

.label {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 2px;
  font-family: $RobotoRegular;
  a {
    color: rgb(51, 102, 187)
  }
}

/* Hide the browser's default checkbox */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 2px;
  background-color: transparent;
  border: 2px solid #000;
}


/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  //background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.wrapper input:checked ~ .checkmark {
  /*background-color: #1CD4A7;*/
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.wrapper .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

</style>
