<template lang="pug">
  .burger(@click="handleClick")
    span
    span
    span
    span
</template>

<script>
export default {
  name: "Burger",
  methods: {
    handleClick(val) {
      this.$emit('click', val);
    }
  }
}
</script>

<style scoped lang="scss">
.burger {
  display: none;
  width: 28px;
  height: 20px;
  transform: rotate(
      0deg
  );
  transition: .5s ease-in-out;
  cursor: pointer;
  @include tablet{
    display: block;
  }
}

.burger {
  span {
    display: block;
    position: absolute;
    width: 100%;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    height: 3px;
    background-color: #fff;
  }
  
  span:nth-child(1) {
    top: 0;
  }
  
  span:nth-child(2),
  span:nth-child(3) {
    top: 8px;
  }
  
  span:nth-child(4) {
    top: 16px;
  }
}

.menu-open .burger {
  span:nth-child(3) {
    transform: rotate(-45deg);
  }
  span:nth-child(2) {
    transform: rotate(45deg);
  }
  
  span:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
  span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
  
}



</style>