export default {
  v: '5.7.3',
  fr: 29.9700012207031,
  ip: 0,
  op: 60.0000024438501,
  w: 982,
  h: 1018,
  nm: 'Lupa',
  ddd: 0,
  assets: [{
    id: 'comp_0', layers: [{
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Group 5',
      sr: 1,
      ks: {
        o: {a: 0, k: 10, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [437.207, 78.92, 0], ix: 2},
        a: {a: 0, k: [437.207, 78.92, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.696, 0], [1.907, 1.905], [0, 2.695], [-1.905, 1.908], [-2.694, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.694, 0], [-1.905, -1.907], [0, -2.694], [1.907, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [0, 10.162], [-7.187, 7.187], [-10.163, 0], [-7.187, -7.187], [0, -10.162], [7.187, -7.187], [10.162, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.721], [5.265, 0], [3.722, -3.724], [0, -5.264], [-3.723, -3.721], [-5.263, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.724], [-5.263, 0], [-3.723, 3.721], [0, 5.264], [3.722, 3.724], [5.265, 0], [3.723, -3.722], [0, -5.264]],
              v: [[14.034, -14.034], [0, -19.849], [-14.034, -14.034], [-19.849, 0], [-14.034, 14.034], [0, 19.849], [14.034, 14.034], [19.849, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [437.207, 78.92], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 5',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 31.0000012626559,
      op: 931.000037920407,
      st: 31.0000012626559,
      bm: 0
    }, {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Group 2',
      sr: 1,
      ks: {
        o: {a: 0, k: 20, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [399.365, 78.921, 0], ix: 2},
        a: {a: 0, k: [399.365, 78.921, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.799, 0], [0, -2.799], [0, 0]],
              o: [[-2.799, 0], [0, 0], [0, -2.799], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0, 18.134], [-5.068, 13.066], [-5.068, -13.066], [0, -18.134], [5.068, -13.066], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [399.365, 78.921], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 2',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 30.0000012219251,
      op: 930.000037879677,
      st: 30.0000012219251,
      bm: 0
    }, {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Group 3',
      sr: 1,
      ks: {
        o: {a: 0, k: 30, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [373.752, 78.921, 0], ix: 2},
        a: {a: 0, k: [373.752, 78.921, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.799, 0], [0, -2.799], [0, 0]],
              o: [[-2.799, 0], [0, 0], [0, -2.799], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0, 18.134], [-5.068, 13.066], [-5.068, -13.066], [0, -18.134], [5.068, -13.066], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [373.752, 78.921], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 3',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 29.0000011811942,
      op: 929.000037838946,
      st: 29.0000011811942,
      bm: 0
    }, {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Group 4',
      sr: 1,
      ks: {
        o: {a: 0, k: 40, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [348.138, 78.921, 0], ix: 2},
        a: {a: 0, k: [348.138, 78.921, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.8, 0], [0, 2.799], [0, 0], [-2.799, 0], [0, -2.799], [0, 0]],
              o: [[-2.799, 0], [0, 0], [0, -2.799], [2.8, 0], [0, 0], [0, 2.799]],
              v: [[0, 18.134], [-5.068, 13.066], [-5.068, -13.066], [0, -18.134], [5.068, -13.066], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [348.138, 78.921], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 4',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 28.0000011404634,
      op: 928.000037798215,
      st: 28.0000011404634,
      bm: 0
    }, {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Group 6',
      sr: 1,
      ks: {
        o: {a: 0, k: 50, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [311.203, 78.92, 0], ix: 2},
        a: {a: 0, k: [311.203, 78.92, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.907, 1.905], [0, 2.695], [-1.905, 1.908], [-2.694, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.694, 0], [-1.905, -1.907], [0, -2.694], [1.907, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.186, 7.187], [-0.001, 10.162], [-7.187, 7.187], [-10.163, 0], [-7.187, -7.187], [-0.001, -10.162], [7.186, -7.187], [10.161, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.721], [5.265, 0], [3.721, -3.724], [0, -5.264], [-3.723, -3.721], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.724], [-5.264, 0], [-3.724, 3.721], [0, 5.264], [3.721, 3.724], [5.265, 0], [3.724, -3.722], [0, -5.264]],
              v: [[14.034, -14.034], [-0.001, -19.849], [-14.035, -14.034], [-19.849, 0], [-14.035, 14.034], [-0.001, 19.849], [14.034, 14.034], [19.848, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [311.203, 78.92], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 6',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 27.0000010997325,
      op: 927.000037757484,
      st: 27.0000010997325,
      bm: 0
    }, {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Group 7',
      sr: 1,
      ks: {
        o: {a: 0, k: 60, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [272.048, 78.921, 0], ix: 2},
        a: {a: 0, k: [272.048, 78.921, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.799, 0], [0, -2.799], [0, 0]],
              o: [[-2.799, 0], [0, 0], [0, -2.799], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0, 18.134], [-5.068, 13.066], [-5.068, -13.066], [0, -18.134], [5.068, -13.066], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [272.048, 78.921], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 7',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 26.0000010590017,
      op: 926.000037716753,
      st: 26.0000010590017,
      bm: 0
    }, {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Group 8',
      sr: 1,
      ks: {
        o: {a: 0, k: 70, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [235.964, 78.92, 0], ix: 2},
        a: {a: 0, k: [235.964, 78.92, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.907, 1.905], [0, 2.695], [-1.905, 1.908], [-2.694, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.694, 0], [-1.905, -1.907], [0, -2.694], [1.907, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [-0.001, 10.162], [-7.187, 7.187], [-10.163, 0], [-7.187, -7.187], [-0.001, -10.162], [7.187, -7.187], [10.161, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.721], [5.265, 0], [3.721, -3.724], [0, -5.264], [-3.723, -3.721], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.724], [-5.264, 0], [-3.724, 3.721], [0, 5.264], [3.721, 3.724], [5.265, 0], [3.724, -3.722], [0, -5.264]],
              v: [[14.035, -14.034], [-0.001, -19.849], [-14.035, -14.034], [-19.848, 0], [-14.035, 14.034], [-0.001, 19.849], [14.035, 14.034], [19.848, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [235.964, 78.92], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 8',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 25.0000010182709,
      op: 925.000037676022,
      st: 25.0000010182709,
      bm: 0
    }, {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Group 9',
      sr: 1,
      ks: {
        o: {a: 0, k: 80, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [187.282, 78.92, 0], ix: 2},
        a: {a: 0, k: [187.282, 78.92, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.908, 1.905], [0, 2.695], [-1.904, 1.908], [-2.694, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.694, 0], [-1.904, -1.907], [0, -2.694], [1.908, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [-0.001, 10.162], [-7.188, 7.187], [-10.163, 0], [-7.188, -7.187], [-0.001, -10.162], [7.187, -7.187], [10.161, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.721], [5.265, 0], [3.721, -3.724], [0, -5.264], [-3.724, -3.721], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.724], [-5.264, 0], [-3.724, 3.721], [0, 5.264], [3.721, 3.724], [5.265, 0], [3.723, -3.722], [0, -5.264]],
              v: [[14.035, -14.034], [-0.001, -19.849], [-14.035, -14.034], [-19.848, 0], [-14.035, 14.034], [-0.001, 19.849], [14.035, 14.034], [19.848, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [187.282, 78.92], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 9',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 24.00000097754,
      op: 924.000037635292,
      st: 24.00000097754,
      bm: 0
    }, {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Group 10',
      sr: 1,
      ks: {
        o: {a: 0, k: 90, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [139.342, 78.92, 0], ix: 2},
        a: {a: 0, k: [139.342, 78.92, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.908, 1.905], [0, 2.695], [-1.905, 1.908], [-2.695, 0], [-1.908, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.695, 0], [-1.905, -1.907], [0, -2.694], [1.908, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [0, 10.162], [-7.187, 7.187], [-10.162, 0], [-7.187, -7.187], [0, -10.162], [7.187, -7.187], [10.162, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.721], [5.264, 0], [3.721, -3.724], [0, -5.264], [-3.724, -3.721], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.724], [-5.264, 0], [-3.724, 3.721], [0, 5.264], [3.721, 3.724], [5.264, 0], [3.723, -3.722], [0, -5.264]],
              v: [[14.035, -14.034], [0, -19.849], [-14.034, -14.034], [-19.849, 0], [-14.034, 14.034], [0, 19.849], [14.035, 14.034], [19.849, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [139.342, 78.92], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 10',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 23.0000009368092,
      op: 923.000037594561,
      st: 23.0000009368092,
      bm: 0
    }, {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Group 11',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [90.659, 78.92, 0], ix: 2},
        a: {a: 0, k: [90.659, 78.92, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.907, 1.905], [0, 2.695], [-1.905, 1.908], [-2.694, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.694, 0], [-1.905, -1.907], [0, -2.694], [1.907, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [-0.001, 10.162], [-7.186, 7.187], [-10.163, 0], [-7.186, -7.187], [-0.001, -10.162], [7.187, -7.187], [10.163, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.721], [5.265, 0], [3.721, -3.724], [0, -5.264], [-3.723, -3.721], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.724], [-5.264, 0], [-3.724, 3.721], [0, 5.264], [3.722, 3.724], [5.265, 0], [3.724, -3.722], [0, -5.264]],
              v: [[14.035, -14.034], [-0.001, -19.849], [-14.035, -14.034], [-19.848, 0], [-14.035, 14.034], [-0.001, 19.849], [14.035, 14.034], [19.848, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [90.659, 78.92], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 11',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 22.0000008960784,
      op: 922.00003755383,
      st: 22.0000008960784,
      bm: 0
    }, {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Group 1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [54.06, 78.921, 0], ix: 2},
        a: {a: 0, k: [54.06, 78.921, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.799, 0], [0, -2.799], [0, 0]],
              o: [[-2.799, 0], [0, 0], [0, -2.799], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0.001, 18.134], [-5.068, 13.066], [-5.068, -13.066], [0.001, -18.134], [5.068, -13.066], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [54.06, 78.921], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 21.0000008553475,
      op: 921.000037513099,
      st: 21.0000008553475,
      bm: 0
    }, {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Group 12',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [20.098, 78.92, 0], ix: 2},
        a: {a: 0, k: [20.098, 78.92, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.908, 1.905], [0, 2.695], [-1.904, 1.908], [-2.694, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.694, 0], [-1.904, -1.907], [0, -2.694], [1.908, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [-0.001, 10.162], [-7.188, 7.187], [-10.163, 0], [-7.188, -7.187], [-0.001, -10.162], [7.187, -7.187], [10.161, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.721], [5.265, 0], [3.721, -3.724], [0, -5.264], [-3.724, -3.721], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.724], [-5.264, 0], [-3.724, 3.721], [0, 5.264], [3.721, 3.724], [5.265, 0], [3.724, -3.722], [0, -5.264]],
              v: [[14.035, -14.034], [-0.001, -19.849], [-14.035, -14.034], [-19.848, 0], [-14.035, 14.034], [-0.001, 19.849], [14.035, 14.034], [19.848, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [20.098, 78.92], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 12',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 20.0000008146167,
      op: 920.000037472368,
      st: 20.0000008146167,
      bm: 0
    }, {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Group 17',
      sr: 1,
      ks: {
        o: {a: 0, k: 10, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [636.682, 20.099, 0], ix: 2},
        a: {a: 0, k: [636.682, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.907, 1.905], [0, 2.695], [-1.905, 1.908], [-2.695, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.695, 0], [-1.905, -1.907], [0, -2.694], [1.906, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [0, 10.162], [-7.187, 7.187], [-10.163, 0], [-7.187, -7.187], [0, -10.162], [7.187, -7.187], [10.161, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.722], [5.264, 0], [3.721, -3.723], [0, -5.264], [-3.723, -3.722], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.723], [-5.264, 0], [-3.724, 3.722], [0, 5.264], [3.721, 3.724], [5.264, 0], [3.723, -3.722], [0, -5.264]],
              v: [[14.034, -14.035], [0, -19.849], [-14.035, -14.035], [-19.849, 0], [-14.035, 14.034], [0, 19.849], [14.034, 14.034], [19.849, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [636.682, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 17',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 17.0000006924242,
      op: 917.000037350176,
      st: 17.0000006924242,
      bm: 0
    }, {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Group 14',
      sr: 1,
      ks: {
        o: {a: 0, k: 20, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [598.841, 20.099, 0], ix: 2},
        a: {a: 0, k: [598.841, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.8, 0], [0, -2.8], [0, 0]],
              o: [[-2.8, 0], [0, 0], [0, -2.8], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0, 18.134], [-5.068, 13.066], [-5.068, -13.065], [0, -18.134], [5.068, -13.065], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [598.841, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 14',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 16.0000006516934,
      op: 916.000037309445,
      st: 16.0000006516934,
      bm: 0
    }, {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Group 15',
      sr: 1,
      ks: {
        o: {a: 0, k: 30, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [573.227, 20.099, 0], ix: 2},
        a: {a: 0, k: [573.227, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.8, 0], [0, -2.8], [0, 0]],
              o: [[-2.8, 0], [0, 0], [0, -2.8], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0, 18.134], [-5.068, 13.066], [-5.068, -13.065], [0, -18.134], [5.068, -13.065], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [573.227, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 15',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 15.0000006109625,
      op: 915.000037268714,
      st: 15.0000006109625,
      bm: 0
    }, {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Group 16',
      sr: 1,
      ks: {
        o: {a: 0, k: 40, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [547.614, 20.099, 0], ix: 2},
        a: {a: 0, k: [547.614, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.799, 0], [0, -2.8], [0, 0]],
              o: [[-2.799, 0], [0, 0], [0, -2.8], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0, 18.134], [-5.068, 13.066], [-5.068, -13.065], [0, -18.134], [5.068, -13.065], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [547.614, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 16',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 14.0000005702317,
      op: 914.000037227983,
      st: 14.0000005702317,
      bm: 0
    }, {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Group 18',
      sr: 1,
      ks: {
        o: {a: 0, k: 50, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [510.679, 20.099, 0], ix: 2},
        a: {a: 0, k: [510.679, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.696, 0], [1.907, 1.905], [0, 2.695], [-1.905, 1.908], [-2.694, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.694, 0], [-1.905, -1.907], [0, -2.694], [1.907, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [-0.001, 10.162], [-7.187, 7.187], [-10.163, 0], [-7.187, -7.187], [-0.001, -10.162], [7.187, -7.187], [10.162, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.722], [5.265, 0], [3.722, -3.723], [0, -5.264], [-3.723, -3.722], [-5.263, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.723], [-5.263, 0], [-3.723, 3.722], [0, 5.264], [3.722, 3.724], [5.265, 0], [3.723, -3.722], [0, -5.264]],
              v: [[14.034, -14.035], [-0.001, -19.849], [-14.035, -14.035], [-19.849, 0], [-14.035, 14.034], [-0.001, 19.849], [14.034, 14.034], [19.849, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [510.679, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 18',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 13.0000005295009,
      op: 913.000037187252,
      st: 13.0000005295009,
      bm: 0
    }, {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'Group 20',
      sr: 1,
      ks: {
        o: {a: 0, k: 60, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [471.523, 20.099, 0], ix: 2},
        a: {a: 0, k: [471.523, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.8, 0], [0, -2.8], [0, 0]],
              o: [[-2.8, 0], [0, 0], [0, -2.8], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0, 18.134], [-5.068, 13.066], [-5.068, -13.065], [0, -18.134], [5.068, -13.065], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [471.523, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 20',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 12.00000048877,
      op: 912.000037146522,
      st: 12.00000048877,
      bm: 0
    }, {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: 'Group 24',
      sr: 1,
      ks: {
        o: {a: 0, k: 70, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [435.439, 20.099, 0], ix: 2},
        a: {a: 0, k: [435.439, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.696, 0], [1.907, 1.905], [0, 2.695], [-1.905, 1.908], [-2.694, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.694, 0], [-1.905, -1.907], [0, -2.694], [1.907, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [0, 10.162], [-7.187, 7.187], [-10.163, 0], [-7.187, -7.187], [0, -10.162], [7.187, -7.187], [10.162, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.722], [5.265, 0], [3.722, -3.723], [0, -5.264], [-3.723, -3.722], [-5.263, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.723], [-5.263, 0], [-3.723, 3.722], [0, 5.264], [3.722, 3.724], [5.265, 0], [3.723, -3.722], [0, -5.264]],
              v: [[14.034, -14.035], [0, -19.849], [-14.034, -14.035], [-19.849, 0], [-14.034, 14.034], [0, 19.849], [14.034, 14.034], [19.849, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [435.439, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 24',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 11.0000004480392,
      op: 911.000037105791,
      st: 11.0000004480392,
      bm: 0
    }, {
      ddd: 0,
      ind: 20,
      ty: 4,
      nm: 'Group 25',
      sr: 1,
      ks: {
        o: {a: 0, k: 80, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [386.757, 20.099, 0], ix: 2},
        a: {a: 0, k: [386.757, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.907, 1.905], [0, 2.695], [-1.905, 1.908], [-2.695, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.695, 0], [-1.905, -1.907], [0, -2.694], [1.907, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.186, 7.187], [-0.001, 10.162], [-7.187, 7.187], [-10.163, 0], [-7.187, -7.187], [-0.001, -10.162], [7.186, -7.187], [10.162, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.722], [5.264, 0], [3.722, -3.723], [0, -5.264], [-3.723, -3.722], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.723], [-5.264, 0], [-3.723, 3.722], [0, 5.264], [3.722, 3.724], [5.264, 0], [3.723, -3.722], [0, -5.264]],
              v: [[14.034, -14.035], [-0.001, -19.849], [-14.035, -14.035], [-19.849, 0], [-14.035, 14.034], [-0.001, 19.849], [14.034, 14.034], [19.848, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [386.757, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 25',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 10.0000004073083,
      op: 910.00003706506,
      st: 10.0000004073083,
      bm: 0
    }, {
      ddd: 0,
      ind: 21,
      ty: 4,
      nm: 'Group 26',
      sr: 1,
      ks: {
        o: {a: 0, k: 90, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [338.817, 20.099, 0], ix: 2},
        a: {a: 0, k: [338.817, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.907, 1.905], [0, 2.695], [-1.905, 1.908], [-2.695, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.695, 0], [-1.905, -1.907], [0, -2.694], [1.907, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [0, 10.162], [-7.186, 7.187], [-10.163, 0], [-7.186, -7.187], [0, -10.162], [7.187, -7.187], [10.163, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.722], [5.264, 0], [3.721, -3.723], [0, -5.264], [-3.724, -3.722], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.723], [-5.264, 0], [-3.724, 3.722], [0, 5.264], [3.721, 3.724], [5.264, 0], [3.723, -3.722], [0, -5.264]],
              v: [[14.035, -14.035], [0, -19.849], [-14.034, -14.035], [-19.848, 0], [-14.034, 14.034], [0, 19.849], [14.035, 14.034], [19.849, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [338.817, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 26',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 9.00000036657752,
      op: 909.000037024329,
      st: 9.00000036657752,
      bm: 0
    }, {
      ddd: 0,
      ind: 22,
      ty: 4,
      nm: 'Group 27',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [290.134, 20.099, 0], ix: 2},
        a: {a: 0, k: [290.134, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.908, 1.905], [0, 2.695], [-1.905, 1.908], [-2.695, 0], [-1.908, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.695, 0], [-1.905, -1.907], [0, -2.694], [1.908, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [0, 10.162], [-7.187, 7.187], [-10.162, 0], [-7.187, -7.187], [0, -10.162], [7.187, -7.187], [10.162, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.722], [5.264, 0], [3.721, -3.723], [0, -5.264], [-3.724, -3.722], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.723], [-5.264, 0], [-3.724, 3.722], [0, 5.264], [3.721, 3.724], [5.264, 0], [3.723, -3.722], [0, -5.264]],
              v: [[14.035, -14.035], [0, -19.849], [-14.034, -14.035], [-19.849, 0], [-14.034, 14.034], [0, 19.849], [14.035, 14.034], [19.849, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [290.134, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 27',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 8.00000032584668,
      op: 908.000036983598,
      st: 8.00000032584668,
      bm: 0
    }, {
      ddd: 0,
      ind: 23,
      ty: 4,
      nm: 'Group 13',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [253.535, 20.099, 0], ix: 2},
        a: {a: 0, k: [253.535, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.799, 0], [0, -2.8], [0, 0]],
              o: [[-2.799, 0], [0, 0], [0, -2.8], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0.001, 18.134], [-5.068, 13.066], [-5.068, -13.065], [0.001, -18.134], [5.068, -13.065], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [253.535, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 13',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 7.00000028511585,
      op: 907.000036942867,
      st: 7.00000028511585,
      bm: 0
    }, {
      ddd: 0,
      ind: 24,
      ty: 4,
      nm: 'Group 28',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [219.574, 20.099, 0], ix: 2},
        a: {a: 0, k: [219.574, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.908, 1.905], [0, 2.695], [-1.905, 1.908], [-2.695, 0], [-1.908, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.695, 0], [-1.905, -1.907], [0, -2.694], [1.908, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [0, 10.162], [-7.187, 7.187], [-10.162, 0], [-7.187, -7.187], [0, -10.162], [7.187, -7.187], [10.162, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.722], [5.264, 0], [3.721, -3.723], [0, -5.264], [-3.724, -3.722], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.723], [-5.264, 0], [-3.724, 3.722], [0, 5.264], [3.721, 3.724], [5.264, 0], [3.723, -3.722], [0, -5.264]],
              v: [[14.035, -14.035], [0, -19.849], [-14.034, -14.035], [-19.849, 0], [-14.034, 14.034], [0, 19.849], [14.035, 14.034], [19.849, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [219.574, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 28',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 6.00000024438501,
      op: 906.000036902136,
      st: 6.00000024438501,
      bm: 0
    }, {
      ddd: 0,
      ind: 25,
      ty: 4,
      nm: 'Group 29',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [170.891, 20.099, 0], ix: 2},
        a: {a: 0, k: [170.891, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.907, 1.905], [0, 2.695], [-1.905, 1.908], [-2.694, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.694, 0], [-1.905, -1.907], [0, -2.694], [1.907, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [-0.001, 10.162], [-7.187, 7.187], [-10.163, 0], [-7.187, -7.187], [-0.001, -10.162], [7.187, -7.187], [10.161, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.722], [5.265, 0], [3.721, -3.723], [0, -5.264], [-3.724, -3.722], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.723], [-5.264, 0], [-3.724, 3.722], [0, 5.264], [3.721, 3.724], [5.265, 0], [3.724, -3.722], [0, -5.264]],
              v: [[14.035, -14.035], [-0.001, -19.849], [-14.035, -14.035], [-19.848, 0], [-14.035, 14.034], [-0.001, 19.849], [14.035, 14.034], [19.848, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [170.891, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 29',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 5.00000020365417,
      op: 905.000036861406,
      st: 5.00000020365417,
      bm: 0
    }, {
      ddd: 0,
      ind: 26,
      ty: 4,
      nm: 'Group 19',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [133.049, 20.099, 0], ix: 2},
        a: {a: 0, k: [133.049, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.799, 0], [0, -2.8], [0, 0]],
              o: [[-2.799, 0], [0, 0], [0, -2.8], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0, 18.134], [-5.068, 13.066], [-5.068, -13.065], [0, -18.134], [5.068, -13.065], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [133.049, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 19',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 4.00000016292334,
      op: 904.000036820675,
      st: 4.00000016292334,
      bm: 0
    }, {
      ddd: 0,
      ind: 27,
      ty: 4,
      nm: 'Group 21',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [107.436, 20.099, 0], ix: 2},
        a: {a: 0, k: [107.436, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.799, 0], [0, -2.8], [0, 0]],
              o: [[-2.799, 0], [0, 0], [0, -2.8], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0, 18.134], [-5.068, 13.066], [-5.068, -13.065], [0, -18.134], [5.068, -13.065], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [107.436, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 21',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 3.00000012219251,
      op: 903.000036779944,
      st: 3.00000012219251,
      bm: 0
    }, {
      ddd: 0,
      ind: 28,
      ty: 4,
      nm: 'Group 22',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [81.823, 20.099, 0], ix: 2},
        a: {a: 0, k: [81.823, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.799, 0], [0, -2.8], [0, 0]],
              o: [[-2.799, 0], [0, 0], [0, -2.8], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0, 18.134], [-5.068, 13.066], [-5.068, -13.065], [0, -18.134], [5.068, -13.065], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [81.823, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 22',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 2.00000008146167,
      op: 902.000036739213,
      st: 2.00000008146167,
      bm: 0
    }, {
      ddd: 0,
      ind: 29,
      ty: 4,
      nm: 'Group 30',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [44.888, 20.099, 0], ix: 2},
        a: {a: 0, k: [44.888, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.905, -1.907], [2.695, 0], [1.908, 1.905], [0, 2.695], [-1.904, 1.908], [-2.694, 0], [-1.907, -1.904], [0, -2.694]],
              o: [[-1.907, 1.905], [-2.694, 0], [-1.904, -1.907], [0, -2.694], [1.908, -1.904], [2.695, 0], [1.905, 1.908], [0, 2.695]],
              v: [[7.187, 7.187], [-0.001, 10.162], [-7.188, 7.187], [-10.163, 0], [-7.188, -7.187], [-0.001, -10.162], [7.187, -7.187], [10.161, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[3.723, 3.722], [5.265, 0], [3.721, -3.723], [0, -5.264], [-3.724, -3.722], [-5.264, 0], [-3.722, 3.724], [0, 5.264]],
              o: [[-3.722, -3.723], [-5.264, 0], [-3.724, 3.722], [0, 5.264], [3.721, 3.724], [5.265, 0], [3.723, -3.722], [0, -5.264]],
              v: [[14.035, -14.035], [-0.001, -19.849], [-14.035, -14.035], [-19.848, 0], [-14.035, 14.034], [-0.001, 19.849], [14.035, 14.034], [19.848, 0]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.948999980852, 0.705999995213, 0.313999998803, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [44.888, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 30',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 1.00000004073083,
      op: 901.000036698482,
      st: 1.00000004073083,
      bm: 0
    }, {
      ddd: 0,
      ind: 30,
      ty: 4,
      nm: 'Group 23',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [7.443, 20.099, 0], ix: 2},
        a: {a: 0, k: [7.443, 20.099, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.799, 0], [0, 2.799], [0, 0], [-2.799, 0], [0, -2.8], [0, 0]],
              o: [[-2.799, 0], [0, 0], [0, -2.8], [2.799, 0], [0, 0], [0, 2.799]],
              v: [[0.001, 18.134], [-5.068, 13.066], [-5.068, -13.065], [0.001, -18.134], [5.068, -13.065], [5.068, 13.066]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [7.443, 20.099], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 23',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }]
  }, {
    id: 'comp_1', layers: [{
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Group 1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [232.004, 253.373, 0], ix: 2},
        a: {a: 0, k: [232.004, 253.373, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.977, -1.687], [0, 0], [1.685, 1.976], [0, 0], [0, 0], [0, 0]],
              o: [[0, 0], [-1.976, 1.686], [0, 0], [0, 0], [0, 0], [1.687, 1.977]],
              v: [[59.536, 43.699], [33.889, 65.577], [27.258, 65.052], [-61.748, -39.279], [-28.945, -67.263], [60.06, 37.067]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.368999974868, 0.368999974868, 0.368999974868, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [232.285, 253.653], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Group 2',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [108.915, 108.916, 0], ix: 2},
        a: {a: 0, k: [108.915, 108.916, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-28.51, -33.419], [33.419, -28.51], [28.511, 33.393], [-33.42, 28.509]],
              o: [[28.511, 33.393], [-33.42, 28.509], [-28.51, -33.419], [33.419, -28.51]],
              v: [[60.501, -51.6], [51.601, 60.501], [-60.501, 51.627], [-51.626, -60.501]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[40.8, -34.802], [-34.804, -40.773], [-40.8, 34.803], [34.804, 40.772]],
              o: [[-40.774, 34.804], [34.801, 40.8], [40.801, -34.803], [-34.802, -40.801]],
              v: [[-63.03, -73.864], [-73.862, 63.004], [63.004, 73.863], [73.863, -63.003]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.368999974868, 0.368999974868, 0.368999974868, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [108.917, 108.916], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 2',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Group 3',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [178.668, 190.688, 0], ix: 2},
        a: {a: 0, k: [178.668, 190.688, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0, 0], [0, 0], [0, 0]],
              o: [[0, 0], [0, 0], [0, 0], [0, 0]],
              v: [[21.881, 4.938], [1.429, 22.385], [-21.881, -4.939], [-1.43, -22.385]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.298000021542, 0.298000021542, 0.298000021542, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [178.668, 190.688], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 3',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Group 4',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [133.209, 80.69, 0], ix: 2},
        a: {a: 0, k: [133.209, 80.69, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.879, -0.052], [0.178, 0.02], [-0.231, 2.099], [-0.052, 1.579], [32.888, 1.078], [5.194, -1.254], [0.494, 2.053], [-2.054, 0.497], [-6.088, -0.209], [1.217, -37.101], [0.191, -1.736]],
              o: [[-0.175, 0.005], [-2.1, -0.231], [0.17, -1.541], [1.077, -32.885], [-5.385, -0.183], [-2.053, 0.482], [-0.496, -2.053], [5.862, -1.407], [37.105, 1.217], [-0.056, 1.774], [-0.213, 1.924]],
              v: [[38.923, 39.101], [38.392, 39.079], [35.008, 34.858], [35.344, 30.179], [-22.347, -31.415], [-38.305, -29.795], [-42.921, -32.621], [-40.099, -37.237], [-22.095, -39.063], [42.991, 30.431], [42.616, 35.694]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [1, 1, 1, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [133.209, 80.69], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 4',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Group 5',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [108.91, 108.918, 0], ix: 2},
        a: {a: 0, k: [108.91, 108.918, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-47.234, -1.548], [1.548, -47.233], [47.233, 1.548], [-1.548, 47.233]],
              o: [[47.233, 1.547], [-1.547, 47.234], [-47.234, -1.548], [1.548, -47.234]],
              v: [[2.803, -85.523], [85.523, 2.802], [-2.801, 85.524], [-85.524, -2.802]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.275, 0.517999985639, 0.685999971278, 1], ix: 4},
          o: {a: 0, k: 20, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [108.91, 108.917], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 5',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Group 6',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [146.159, 184.812, 0], ix: 2},
        a: {a: 0, k: [146.159, 184.812, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-28.51, -33.42], [33.42, -28.51], [28.512, 33.393], [-33.419, 28.509]],
              o: [[28.511, 33.393], [-33.419, 28.509], [-28.509, -33.419], [33.42, -28.511]],
              v: [[18.164, -107.379], [9.264, 4.721], [-102.838, -4.153], [-93.963, -116.28]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ind: 1,
          ty: 'sh',
          ix: 2,
          ks: {
            a: 0,
            k: {
              i: [[40.801, -34.801], [-34.803, -40.774], [-40.203, 25.69], [0, 0], [0, 0], [0, 0], [-4.837, 4.126], [0, 0], [4.127, 4.837], [0, 0], [0, 0], [0, 0], [31.842, 37.303]],
              o: [[-40.774, 34.804], [31.841, 37.328], [0, 0], [0, 0], [0, 0], [4.127, 4.838], [0, 0], [4.837, -4.127], [0, 0], [0, 0], [0, 0], [31.708, -35.655], [-34.801, -40.801]],
              v: [[-105.367, -129.644], [-116.199, 7.225], [9.827, 26.085], [25.461, 44.41], [19.284, 49.679], [103.871, 148.829], [120.102, 150.116], [135.388, 137.075], [136.674, 120.845], [52.088, 21.694], [45.912, 26.963], [30.279, 8.64], [31.526, -118.783]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 2',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false}, {
          ty: 'fl',
          c: {a: 0, k: [0.219999994016, 0.325, 0.477999997606, 1], ix: 4},
          o: {a: 0, k: 17, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [146.159, 184.812], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 6',
        np: 4,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }]
  }, {
    id: 'comp_2', layers: [{
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Group 1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [322.058, 397.133, 0], ix: 2},
        a: {a: 0, k: [322.058, 397.133, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.23, 0.044], [0.179, 0.025], [0, 0], [-0.339, 2.444], [-2.466, -0.346], [0, 0], [0, 0], [-2.475, -0.346], [0.339, -2.442], [0, 0]],
              o: [[-0.175, -0.004], [0, 0], [-2.443, -0.338], [0.341, -2.441], [0, 0], [0, 0], [0.34, -2.442], [2.442, 0.339], [0, 0], [-0.316, 2.264]],
              v: [[9.574, 15.804], [9.043, 15.762], [-13.375, 12.657], [-17.185, 7.621], [-12.148, 3.812], [5.845, 6.304], [8.338, -11.691], [13.376, -15.502], [17.185, -10.466], [14.08, 11.952]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [322.058, 397.004], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 1',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Group 2',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [344.62, 243.877, 0], ix: 2},
        a: {a: 0, k: [344.62, 243.877, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.23, 0.043], [0.179, 0.024], [-0.338, 2.442], [0, 0], [0, 0], [-0.338, 2.443], [-2.481, -0.347], [0, 0], [0.338, -2.443], [0, 0]],
              o: [[-0.175, -0.004], [-2.443, -0.338], [0, 0], [0, 0], [-2.442, -0.338], [0.341, -2.442], [0, 0], [2.442, 0.338], [0, 0], [-0.313, 2.265]],
              v: [[8.024, 17.357], [7.493, 17.315], [3.681, 12.28], [6.173, -5.715], [-11.822, -8.208], [-15.632, -13.243], [-10.595, -17.053], [11.822, -13.948], [15.632, -8.913], [12.53, 13.504]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [344.62, 243.747], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 2',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Group 3',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [51.066, 358.266, 0], ix: 2},
        a: {a: 0, k: [51.066, 358.266, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.23, 0.043], [0.18, 0.024], [0, 0], [-0.339, 2.443], [0, 0], [-2.488, -0.334], [0.339, -2.442], [0, 0], [0, 0], [0.338, -2.443]],
              o: [[-0.175, -0.004], [0, 0], [-2.443, -0.338], [0, 0], [0.338, -2.441], [2.443, 0.338], [0, 0], [0, 0], [2.443, 0.339], [-0.316, 2.264]],
              v: [[11.127, 17.35], [10.595, 17.308], [-11.822, 14.203], [-15.632, 9.168], [-12.529, -13.25], [-7.492, -17.06], [-3.682, -12.025], [-6.174, 5.97], [11.822, 8.462], [15.633, 13.498]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [51.065, 358.141], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 3',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Group 4',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [73.627, 205.008, 0], ix: 2},
        a: {a: 0, k: [73.627, 205.008, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.23, 0.043], [0.18, 0.025], [-0.338, 2.443], [0, 0], [-2.467, -0.343], [0, 0], [0.338, -2.443], [2.454, 0.341], [0, 0], [0, 0]],
              o: [[-0.175, -0.003], [-2.442, -0.338], [0, 0], [0.34, -2.441], [0, 0], [2.443, 0.338], [-0.341, 2.441], [0, 0], [0, 0], [-0.316, 2.265]],
              v: [[-12.843, 15.802], [-13.375, 15.76], [-17.185, 10.725], [-14.079, -11.693], [-9.042, -15.502], [13.375, -12.398], [17.186, -7.362], [12.148, -3.552], [-5.845, -6.044], [-8.337, 11.951]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.957000014361, 0.709999952129, 0.270999983245, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [73.627, 204.88], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 4',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Group 5',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [127.837, 87.379, 0], ix: 2},
        a: {a: 0, k: [127.837, 87.379, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0, 0], [0.121, 3.205], [2.966, 2.926], [2.07, 1.163], [0.831, 0.597], [4.595, 0.637], [2.565, 3.738], [1.125, -0.27], [2.111, -1.163], [1.198, -3.697], [0.548, -3.163], [-1.958, -1.027], [-2.6, -0.938], [-5.307, -0.775], [-5.327, 0.376], [-2.705, 0.574], [-0.257, 2.474]],
              o: [[0.333, -3.192], [-0.148, -3.883], [-1.716, -1.692], [-1.009, -0.566], [-3.484, 2.898], [-4.591, -0.635], [-0.962, 0.349], [-2.309, 0.558], [-3.651, 2.012], [-0.988, 3.05], [-0.425, 2.448], [2.45, 1.287], [5.023, 1.81], [5.316, 0.698], [2.759, -0.194], [2.163, -0.456], [0, 0]],
              v: [[26.945, 13.018], [27.225, 3.376], [24.026, -9.108], [17.826, -12.776], [15.09, -14.489], [2.825, -11.037], [-8.036, -17.692], [-11.135, -16.787], [-18.098, -14.941], [-24.57, -3.797], [-26.921, 5.558], [-24.222, 9.548], [-16.605, 12.831], [-1.056, 16.994], [15.039, 17.214], [23.261, 16.124], [26.946, 13.018]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [127.7, 87.471], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 5',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Group 6',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [133.894, 55.066, 0], ix: 2},
        a: {a: 0, k: [133.894, 55.066, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[-9.956, -1.379], [-2.073, 8.059], [10.863, 1.504], [0.241, -10.247]],
              o: [[9.956, 1.379], [2.554, -9.927], [-10.86, -1.504], [-0.196, 8.32]],
              v: [[-3.693, 17.981], [13.298, 1.829], [1.27, -17.856], [-15.655, -2.182]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [134.693, 55.039], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 6',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Group 7',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [278.614, 89.104, 0], ix: 2},
        a: {a: 0, k: [278.614, 89.104, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[3.16, 0.445], [0, 0], [-0.445, 3.159], [0, 0], [-3.159, -0.445], [0, 0], [0.445, -3.16]],
              o: [[0, 0], [-3.159, -0.445], [0, 0], [0.445, -3.159], [0, 0], [3.16, 0.445], [-0.446, 3.16]],
              v: [[76.207, 16.598], [-77.827, -5.108], [-82.762, -11.663], [-82.762, -11.663], [-76.207, -16.598], [77.825, 5.11], [82.762, 11.663]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [278.614, 89.104], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 7',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Group 8',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [236.8, 57.352, 0], ix: 2},
        a: {a: 0, k: [236.8, 57.352, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[3.16, 0.445], [0, 0], [-0.446, 3.16], [-3.16, -0.445], [0, 0], [0.445, -3.16]],
              o: [[0, 0], [-3.16, -0.446], [0.445, -3.159], [0, 0], [3.16, 0.445], [-0.446, 3.16]],
              v: [[31.568, 10.307], [-33.186, 1.182], [-38.122, -5.372], [-31.568, -10.307], [33.186, -1.181], [38.122, 5.372]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [236.799, 57.352], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 8',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Group 9',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [177.563, 357.062, 0], ix: 2},
        a: {a: 0, k: [177.563, 357.062, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[3.159, 0.445], [0, 0], [-0.445, 3.16], [-3.16, -0.445], [0, 0], [0.445, -3.159]],
              o: [[0, 0], [-3.16, -0.445], [0.445, -3.159], [0, 0], [3.159, 0.445], [-0.446, 3.16]],
              v: [[108.942, 21.211], [-110.559, -9.722], [-115.495, -16.276], [-108.94, -21.211], [110.56, 9.722], [115.495, 16.276]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.732999973671, 0.779999976065, 0.859000052658, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [177.563, 357.062], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 9',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Group 10',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [256.619, 338.59, 0], ix: 2},
        a: {a: 0, k: [256.619, 338.59, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[3.159, 0.445], [0, 0], [-0.444, 3.159], [-3.159, -0.444], [0, 0], [0.445, -3.16]],
              o: [[0, 0], [-3.159, -0.445], [0.446, -3.16], [0, 0], [3.159, 0.445], [-0.446, 3.16]],
              v: [[56.439, 13.812], [-58.058, -2.323], [-62.994, -8.877], [-56.439, -13.813], [58.058, 2.323], [62.993, 8.877]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.732999973671, 0.779999976065, 0.859000052658, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [256.619, 338.591], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 10',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Group 11',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [125.614, 320.445, 0], ix: 2},
        a: {a: 0, k: [125.614, 320.445, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[3.159, 0.445], [0, 0], [-0.446, 3.159], [-3.16, -0.445], [0, 0], [0.445, -3.16]],
              o: [[0, 0], [-3.16, -0.446], [0.445, -3.16], [0, 0], [3.159, 0.445], [-0.446, 3.16]],
              v: [[52.945, 13.32], [-54.563, -1.83], [-59.498, -8.384], [-52.943, -13.32], [54.563, 1.831], [59.498, 8.385]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.732999973671, 0.779999976065, 0.859000052658, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [125.614, 320.445], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 11',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Group 12',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [218.269, 303.831, 0], ix: 2},
        a: {a: 0, k: [218.269, 303.831, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[3.16, 0.445], [0, 0], [-0.446, 3.16], [-3.16, -0.446], [0, 0], [0.445, -3.159]],
              o: [[0, 0], [-3.16, -0.445], [0.445, -3.159], [0, 0], [3.16, 0.445], [-0.446, 3.161]],
              v: [[88.308, 18.304], [-89.927, -6.814], [-94.862, -13.369], [-88.308, -18.303], [89.927, 6.815], [94.863, 13.368]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.732999973671, 0.779999976065, 0.859000052658, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [218.269, 303.831], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 12',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Group 13',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [92.116, 286.428, 0], ix: 2},
        a: {a: 0, k: [92.116, 286.428, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[3.16, 0.445], [0, 0], [-0.445, 3.159], [-3.16, -0.445], [0, 0], [0.446, -3.16]],
              o: [[0, 0], [-3.16, -0.446], [0.445, -3.159], [0, 0], [3.16, 0.445], [-0.445, 3.16]],
              v: [[15.397, 8.029], [-17.017, 3.462], [-21.952, -3.093], [-15.398, -8.029], [17.016, -3.46], [21.951, 3.094]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.732999973671, 0.779999976065, 0.859000052658, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [92.116, 286.428], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 13',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Group 14',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [280.441, 283.289, 0], ix: 2},
        a: {a: 0, k: [280.441, 283.289, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[3.16, 0.445], [0, 0], [-0.445, 3.159], [-3.16, -0.445], [0, 0], [0.445, -3.159]],
              o: [[0, 0], [-3.161, -0.445], [0.445, -3.159], [0, 0], [3.16, 0.444], [-0.446, 3.161]],
              v: [[40.349, 11.546], [-41.968, -0.055], [-46.904, -6.609], [-40.349, -11.545], [41.968, 0.057], [46.904, 6.61]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.732999973671, 0.779999976065, 0.859000052658, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [280.441, 283.289], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 14',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Group 15',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [149.143, 265.174, 0], ix: 2},
        a: {a: 0, k: [149.143, 265.174, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[3.159, 0.445], [0, 0], [-0.445, 3.159], [-3.16, -0.446], [0, 0], [0.445, -3.159]],
              o: [[0, 0], [-3.159, -0.445], [0.446, -3.16], [0, 0], [3.159, 0.444], [-0.446, 3.161]],
              v: [[68.378, 15.495], [-69.997, -4.005], [-74.932, -10.56], [-68.377, -15.495], [69.997, 4.007], [74.932, 10.56]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.732999973671, 0.779999976065, 0.859000052658, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [149.143, 265.174], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 15',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Group 16',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [114.167, 230.953, 0], ix: 2},
        a: {a: 0, k: [114.167, 230.953, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[3.16, 0.444], [0, 0], [-0.445, 3.16], [-3.159, -0.446], [0, 0], [0.446, -3.159]],
              o: [[0, 0], [-3.159, -0.445], [0.445, -3.16], [0, 0], [3.16, 0.446], [-0.445, 3.16]],
              v: [[29.353, 9.996], [-30.973, 1.495], [-35.908, -5.06], [-29.354, -9.995], [30.972, -1.493], [35.907, 5.061]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.732999973671, 0.779999976065, 0.859000052658, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [114.167, 230.952], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 16',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Group 17',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [227.308, 202.952, 0], ix: 2},
        a: {a: 0, k: [227.308, 202.952, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.994, 0.336], [0, 0], [-0.376, 2.229], [-1.995, -0.336], [0, 0], [0.376, -2.228], [0, 0]],
              o: [[0, 0], [-1.994, -0.336], [0.376, -2.228], [0, 0], [1.994, 0.336], [0, 0], [-0.376, 2.229]],
              v: [[31.034, 8.755], [-32.403, -0.651], [-35.344, -5.315], [-31.035, -8.755], [32.402, 0.651], [35.344, 5.315], [35.344, 5.315]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [227.308, 202.952], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 17',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'Group 18',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [224.147, 187.412, 0], ix: 2},
        a: {a: 0, k: [224.147, 187.412, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.009, 0.339], [0, 0], [-0.376, 2.229], [-2.008, -0.34], [0, 0], [0.376, -2.228]],
              o: [[0, 0], [-2.008, -0.34], [0.376, -2.229], [0, 0], [2.008, 0.339], [-0.377, 2.229]],
              v: [[25.293, 7.785], [-26.661, 0.32], [-29.628, -4.349], [-25.293, -7.784], [26.661, -0.318], [29.629, 4.349]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [224.147, 187.412], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 18',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: 'Group 19',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [185.839, 181.56, 0], ix: 2},
        a: {a: 0, k: [185.839, 181.56, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0.378, -2.238], [2.237, 0.378], [-0.377, 2.238], [-2.238, -0.378]],
              o: [[-0.377, 2.238], [-2.239, -0.378], [0.378, -2.238], [2.237, 0.378]],
              v: [[4.052, 0.684], [-0.684, 4.052], [-4.052, -0.684], [0.685, -4.052]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [185.839, 181.56], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 19',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 20,
      ty: 4,
      nm: 'Group 20',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [233.429, 168.738, 0], ix: 2},
        a: {a: 0, k: [233.429, 168.738, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.994, 0.337], [0, 0], [-0.376, 2.229], [-1.995, -0.337], [0, 0], [0.376, -2.228]],
              o: [[0, 0], [-1.994, -0.337], [0.376, -2.228], [0, 0], [1.994, 0.337], [-0.377, 2.229]],
              v: [[31.034, 8.754], [-32.403, -0.651], [-35.344, -5.315], [-31.035, -8.755], [32.402, 0.651], [35.344, 5.315]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [233.429, 168.738], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 20',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 21,
      ty: 4,
      nm: 'Group 21',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [229.933, 153.142, 0], ix: 2},
        a: {a: 0, k: [229.933, 153.142, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.008, 0.339], [0, 0], [-0.377, 2.228], [-2.008, -0.339], [0, 0], [0.377, -2.228]],
              o: [[0, 0], [-2.009, -0.339], [0.376, -2.229], [0, 0], [2.009, 0.339], [-0.376, 2.229]],
              v: [[25.293, 7.785], [-26.661, 0.318], [-29.628, -4.349], [-25.293, -7.786], [26.661, -0.318], [29.628, 4.349]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [229.933, 153.142], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 21',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 22,
      ty: 4,
      nm: 'Group 22',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [191.626, 147.29, 0], ix: 2},
        a: {a: 0, k: [191.626, 147.29, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0.378, -2.238], [2.238, 0.378], [-0.377, 2.238], [-2.237, -0.378]],
              o: [[-0.378, 2.238], [-2.238, -0.378], [0.377, -2.238], [2.238, 0.378]],
              v: [[4.052, 0.684], [-0.685, 4.052], [-4.052, -0.684], [0.684, -4.052]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [191.626, 147.29], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 22',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 23,
      ty: 4,
      nm: 'Group 23',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [238.921, 134.419, 0], ix: 2},
        a: {a: 0, k: [238.921, 134.419, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[1.994, 0.337], [0, 0], [-0.376, 2.228], [0, 0], [-1.995, -0.337], [0, 0], [0.377, -2.228], [0, 0]],
              o: [[0, 0], [-1.994, -0.337], [0, 0], [0.376, -2.228], [0, 0], [1.993, 0.337], [0, 0], [-0.376, 2.228]],
              v: [[31.034, 8.754], [-32.403, -0.651], [-35.344, -5.315], [-35.344, -5.315], [-31.035, -8.755], [32.402, 0.651], [35.344, 5.315], [35.344, 5.315]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [238.921, 134.419], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 23',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 24,
      ty: 4,
      nm: 'Group 24',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [235.72, 118.872, 0], ix: 2},
        a: {a: 0, k: [235.72, 118.872, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[2.008, 0.339], [0, 0], [-0.376, 2.229], [-2.008, -0.34], [0, 0], [0.376, -2.228], [0, 0]],
              o: [[0, 0], [-2.008, -0.34], [0.376, -2.229], [0, 0], [2.008, 0.339], [0, 0], [-0.376, 2.228]],
              v: [[25.294, 7.785], [-26.662, 0.32], [-29.629, -4.349], [-25.294, -7.784], [26.661, -0.318], [29.628, 4.349], [29.628, 4.35]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [235.72, 118.872], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 24',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 25,
      ty: 4,
      nm: 'Group 25',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [197.412, 113.021, 0], ix: 2},
        a: {a: 0, k: [197.412, 113.021, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[0.378, -2.238], [2.237, 0.378], [-0.377, 2.238], [-2.238, -0.378]],
              o: [[-0.377, 2.238], [-2.239, -0.378], [0.378, -2.237], [2.237, 0.378]],
              v: [[4.052, 0.684], [-0.684, 4.052], [-4.052, -0.685], [0.685, -4.052]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.894000004787, 0.925, 0.976000019148, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [197.412, 113.021], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 25',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 26,
      ty: 4,
      nm: 'Group 26',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [208.712, 225.269, 0], ix: 2},
        a: {a: 0, k: [208.712, 225.269, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[4.23, 0.528], [0, 0], [-0.529, 4.23], [0, 0], [-4.229, -0.528], [0, 0], [0.528, -4.23], [0, 0]],
              o: [[0, 0], [-4.23, -0.528], [0, 0], [0.529, -4.23], [0, 0], [4.229, 0.528], [0, 0], [-0.528, 4.229]],
              v: [[127.896, 224.491], [-179.164, 186.156], [-185.866, 177.541], [-136.511, -217.789], [-127.896, -224.492], [179.165, -186.156], [185.867, -177.541], [136.511, 217.789]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [1, 1, 1, 1], ix: 4},
          o: {a: 0, k: 100, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [208.711, 225.27], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 26',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 27,
      ty: 4,
      nm: 'Group 27',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [189.493, 240.026, 0], ix: 2},
        a: {a: 0, k: [189.493, 240.026, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [{
        ty: 'gr',
        it: [{
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [[4.221, 0.595], [0, 0], [0.595, -4.222], [0, 0], [-4.22, -0.595], [0, 0], [-0.595, 4.221], [0, 0]],
              o: [[0, 0], [-4.22, -0.595], [0, 0], [-0.595, 4.22], [0, 0], [4.221, 0.593], [0, 0], [0.595, -4.22]],
              v: [[182.083, -183.303], [-124.334, -226.483], [-133.053, -219.917], [-188.648, 174.583], [-182.083, 183.303], [124.334, 226.485], [133.054, 219.919], [188.648, -174.582]],
              c: true
            },
            ix: 2
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false
        }, {
          ty: 'fl',
          c: {a: 0, k: [0.219999994016, 0.325, 0.477999997606, 1], ix: 4},
          o: {a: 0, k: 20, ix: 5},
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false
        }, {
          ty: 'tr',
          p: {a: 0, k: [189.493, 240.025], ix: 2},
          a: {a: 0, k: [0, 0], ix: 1},
          s: {a: 0, k: [100, 100], ix: 3},
          r: {a: 0, k: 0, ix: 6},
          o: {a: 0, k: 100, ix: 7},
          sk: {a: 0, k: 0, ix: 4},
          sa: {a: 0, k: 0, ix: 5},
          nm: 'Преобразовать'
        }],
        nm: 'Group 27',
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: 'ADBE Vector Group',
        hd: false
      }],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }, {
      ddd: 0,
      ind: 28,
      ty: 4,
      nm: 'Layer 3/Lupa Outlines',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [0, 0, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6}
      },
      ao: 0,
      shapes: [],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0
    }]
  }],
  layers: [{
    ddd: 0,
    ind: 1,
    ty: 3,
    nm: 'Adjustment Layer 1',
    sr: 1,
    ks: {
      o: {a: 0, k: 100, ix: 11},
      r: {a: 0, k: 0, ix: 10},
      p: {a: 0, k: [491, 509, 0], ix: 2},
      a: {a: 0, k: [491, 509, 0], ix: 1},
      s: {a: 0, k: [100, 100, 100], ix: 6}
    },
    ao: 0,
    hasMask: true,
    masksProperties: [{
      inv: false,
      mode: 'a',
      pt: {
        a: 0,
        k: {
          i: [[-29.271, 0], [0, 29.271], [29.271, 0], [0, -29.271]],
          o: [[29.271, 0], [0, -29.271], [-29.271, 0], [0, 29.271]],
          v: [[531, 636], [584, 583], [531, 530], [478, 583]],
          c: true
        },
        ix: 1
      },
      o: {a: 0, k: 100, ix: 3},
      x: {a: 0, k: 0, ix: 4},
      nm: 'Mask 1'
    }],
    ip: 0,
    op: 900.000036657751,
    st: 0,
    bm: 0
  }, {
    ddd: 0,
    ind: 2,
    ty: 0,
    nm: 'Text',
    refId: 'comp_0',
    sr: 1,
    ks: {
      o: {a: 0, k: 100, ix: 11},
      r: {a: 0, k: 0, ix: 10},
      p: {a: 0, k: [455.688, 230.905, 0], ix: 2},
      a: {a: 0, k: [328.391, 49.51, 0], ix: 1},
      s: {a: 0, k: [100, 100, 100], ix: 6}
    },
    ao: 0,
    hasMask: true,
    masksProperties: [{
      inv: false,
      mode: 'a',
      pt: {
        a: 0,
        k: {
          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
          v: [[0, 0], [0, 100], [657, 100], [657, 0]],
          c: true
        },
        ix: 1
      },
      o: {a: 0, k: 100, ix: 3},
      x: {a: 0, k: 0, ix: 4},
      nm: 'Mask 1'
    }],
    w: 657,
    h: 100,
    ip: 0,
    op: 900.000036657751,
    st: 0,
    bm: 0
  }, {
    ddd: 0,
    ind: 3,
    ty: 0,
    nm: 'Lupa',
    refId: 'comp_1',
    sr: 1,
    ks: {
      o: {a: 0, k: 100, ix: 11},
      r: {a: 0, k: 0, ix: 10},
      p: {
        a: 1,
        k: [{
          i: {x: 0.833, y: 0.833},
          o: {x: 0.167, y: 0.167},
          t: 0.06,
          s: [531, 636, 0],
          to: [-29.271, 0, 0],
          ti: [0, 29.271, 0]
        }, {
          i: {x: 0.833, y: 0.833},
          o: {x: 0.167, y: 0.167},
          t: 15.045,
          s: [478, 583, 0],
          to: [0, -29.271, 0],
          ti: [-29.271, 0, 0]
        }, {
          i: {x: 0.833, y: 0.833},
          o: {x: 0.167, y: 0.167},
          t: 30.03,
          s: [531, 530, 0],
          to: [29.271, 0, 0],
          ti: [0, -29.271, 0]
        }, {
          i: {x: 0.833, y: 0.833},
          o: {x: 0.167, y: 0.167},
          t: 45.015,
          s: [584, 583, 0],
          to: [0, 29.271, 0],
          ti: [29.271, 0, 0]
        }, {t: 60.0000024438501, s: [531, 636, 0]}],
        ix: 2
      },
      a: {a: 0, k: [147.141, 168.841, 0], ix: 1, x: 'var $bm_rt;\n$bm_rt = transform.anchorPoint;'},
      s: {a: 0, k: [100, 100, 100], ix: 6, x: 'var $bm_rt;\n$bm_rt = transform.scale;'}
    },
    ao: 0,
    w: 295,
    h: 338,
    ip: 0,
    op: 900.000036657751,
    st: 0,
    bm: 0
  }, {
    ddd: 0,
    ind: 4,
    ty: 0,
    nm: 'List',
    refId: 'comp_2',
    sr: 1,
    ks: {
      o: {a: 0, k: 100, ix: 11},
      r: {
        a: 1,
        k: [{i: {x: [0.667], y: [1]}, o: {x: [0.333], y: [0]}, t: 1, s: [0]}, {
          i: {x: [0.667], y: [1]},
          o: {x: [0.333], y: [0]},
          t: 30,
          s: [2]
        }, {t: 60.0000024438501, s: [0]}],
        ix: 10
      },
      p: {a: 0, k: [478.07, 521.804, 0], ix: 2},
      a: {a: 0, k: [197.678, 233.677, 0], ix: 1},
      s: {a: 0, k: [100, 100, 100], ix: 6}
    },
    ao: 0,
    w: 396,
    h: 468,
    ip: 0,
    op: 900.000036657751,
    st: 0,
    bm: 0
  }, {
    ddd: 0,
    ind: 5,
    ty: 4,
    nm: 'Fon Outlines',
    sr: 1,
    ks: {
      o: {a: 0, k: 100, ix: 11},
      r: {a: 0, k: 0, ix: 10},
      p: {a: 0, k: [466.399, 512.678, 0], ix: 2},
      a: {a: 0, k: [302.745, 310.464, 0], ix: 1},
      s: {a: 0, k: [100, 100, 100], ix: 6}
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[-146.124, 64.67], [34.899, 125.341], [23.556, 6.179], [67.47, -102.469], [-57.269, -70.247]],
            o: [[119.995, -53.107], [-38.706, -139.01], [-81.947, -21.495], [-74.986, 113.885], [15.013, 18.415]],
            v: [[127.914, 245.544], [267.596, -104.388], [68.563, -288.719], [-227.509, -206.662], [-167.086, 132.579]],
            c: true
          },
          ix: 2
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false
      }, {
        ty: 'fl',
        c: {a: 0, k: [0.925, 0.952999997606, 0.995999983245, 1], ix: 4},
        o: {a: 0, k: 100, ix: 5},
        r: 1,
        bm: 0,
        nm: 'Fill 1',
        mn: 'ADBE Vector Graphic - Fill',
        hd: false
      }, {
        ty: 'tr',
        p: {a: 0, k: [302.745, 310.464], ix: 2},
        a: {a: 0, k: [0, 0], ix: 1},
        s: {a: 0, k: [100, 100], ix: 3},
        r: {a: 0, k: 0, ix: 6},
        o: {a: 0, k: 100, ix: 7},
        sk: {a: 0, k: 0, ix: 4},
        sa: {a: 0, k: 0, ix: 5},
        nm: 'Преобразовать'
      }],
      nm: 'Group 1',
      np: 4,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false
    }],
    ip: 0,
    op: 900.000036657751,
    st: 0,
    bm: 0
  }, {
    ddd: 0,
    ind: 6,
    ty: 4,
    nm: 'Shape Layer 1',
    sr: 1,
    ks: {
      o: {a: 0, k: 100, ix: 11},
      r: {a: 0, k: 0, ix: 10},
      p: {a: 0, k: [491, 509, 0], ix: 2},
      a: {a: 0, k: [0, 0, 0], ix: 1},
      s: {a: 0, k: [100, 100, 100], ix: 6}
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ty: 'rc',
        d: 1,
        s: {a: 0, k: [982, 1018], ix: 2},
        p: {a: 0, k: [0, 0], ix: 3},
        r: {a: 0, k: 0, ix: 4},
        nm: 'Rectangle Path 1',
        mn: 'ADBE Vector Shape - Rect',
        hd: false
      }, {
        ty: 'st',
        c: {a: 0, k: [1, 1, 1, 1], ix: 3},
        o: {a: 0, k: 100, ix: 4},
        w: {a: 0, k: 2, ix: 5},
        lc: 1,
        lj: 1,
        ml: 4,
        bm: 0,
        nm: 'Stroke 1',
        mn: 'ADBE Vector Graphic - Stroke',
        hd: false
      }, {
        ty: 'fl',
        c: {a: 0, k: [1, 0.992156862745, 0.992156862745, 1], ix: 4},
        o: {a: 0, k: 100, ix: 5},
        r: 1,
        bm: 0,
        nm: 'Fill 1',
        mn: 'ADBE Vector Graphic - Fill',
        hd: false
      }, {
        ty: 'tr',
        p: {a: 0, k: [0, 0], ix: 2},
        a: {a: 0, k: [0, 0], ix: 1},
        s: {a: 0, k: [100, 100], ix: 3},
        r: {a: 0, k: 0, ix: 6},
        o: {a: 0, k: 100, ix: 7},
        sk: {a: 0, k: 0, ix: 4},
        sa: {a: 0, k: 0, ix: 5},
        nm: 'Преобразовать'
      }],
      nm: 'Rectangle 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false
    }],
    ip: 0,
    op: 900.000036657751,
    st: 0,
    bm: 0
  }],
  markers: []
}
