<template lang="pug">
  div.app
    router-view

</template>

<script>
/* eslint-disable no-useless-escape */
import Header from "@/components/Header";

export default {
  name: "analysis-layout",
  components: {Header},
  data(){
    return{

    }
  },
    
}
</script>

<style lang="scss">

.app .header {
  background: #000 !important;
}


</style>