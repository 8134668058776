<template lang="pug">
  .preloader
    lottie(
      :options='defaultOptions'
      
      v-on:animcreated='handleAnimation')


</template>

<script>
import Lottie from 'vue-lottie';
import LOADER_ANIMATION_DATA from '@/data/loader';

export default {
  name: "Preloader",
  components: {
    'lottie': Lottie
  },
  data() {
    return {
      defaultOptions: {
        animationData: LOADER_ANIMATION_DATA,
        autoplay: true,
        renderer: 'svg',
      },
      animationSpeed: 1,
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    
  }
}
</script>

<style scoped lang="scss">

.preloader {
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
}

</style>