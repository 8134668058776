<template lang="pug">
  validation-provider(
    :rules='rules'
    v-slot='{ errors }'
    :name="name"
  )
    div(:class="{ 'errors': errors[0] }")
      textarea(
        type="text"
        :placeholder="placeholder"
        @input="$emit('input', $event.target.value)"
        :value="value"
        ref="textarea"
        ).textarea
    .error-text(v-if="errors[0]") {{ errors[0] }}

</template>

<script>
export default {
  name: "Textarea",
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ""
    
    },

  },
}
</script>

<style scoped lang="scss">

.textarea {
  color: #000000;
  border: 1px solid #c9c9c9;
  width: 100%;
  height: 136px;
  font-size: 16px;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  font-family: $RobotoRegular;
}

</style>