<template lang="pug">
  validation-provider(
    :rules='rules'
    v-slot='{ errors }'
    :name="name"
  )
    div(:class="{ 'errors': errors[0] }")
      input.input(
        type="text"
        :placeholder="placeholder"
        @input="$emit('input', $event.target.value)"
        :value="value"
        ref="input"
      )
    .error-text(v-if="errors[0]") {{ errors[0] }}



</template>

<script>
export default {
  name: "Input",
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ""
  
    },

    

  },

}
</script>

<style scoped lang="scss">

.input {
  color: #000000;
  border: 1px solid #c9c9c9;
  width: 100%;
  height: 60px;
  font-size: 16px;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  margin-bottom: 25px;
}

</style>