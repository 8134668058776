<template lang="pug">
  div(:class="menuOpen ? 'menu-open' : ''")
    MobileMenu
      template(v-slot:menu-content)
        ul.header-right__list(v-if="$route.meta.basePages")
          header-link(
            v-for="(item, i) in linksHeaderMain"
            :key='i'
            :text='item.text'
            :link='item.link'
            :scroll='item.scroll'
            @click="menuOpen=false"
          )
        ul.header-right__list(v-if="$route.meta.serviceSettings")
          header-link(
            v-for="(item, i) in linksHeaderService"
            :key='i'
            :text='item.text'
            :link='item.link'
            :scroll='item.scroll'
            @click="menuOpen=false"

          )
  
  
    Header
      template(v-slot:header-right)
        .header-right
          ul.header-right__list(v-if="$route.meta.basePages")
            header-link(
              v-for="(item, i) in linksHeaderMain"
              :key='i'
              :text='item.text'
              :link='item.link'
              :scroll='item.scroll'
            )
          ul.header-right__list(v-if="$route.meta.serviceSettings")
            header-link(
              v-for="(item, i) in linksHeaderService"
              :key='i'
              :text='item.text'
              :link='item.link'
              :scroll='item.scroll'
            )
          Burger(
            @click="menuOpened"
          )
          
          
          
      
    router-view
    Footer

</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import HeaderLink from "@/components/landing/HeaderLink";
import Burger from "@/components/landing/Burger";
import MobileMenu from "@/components/landing/MobileMenu";

export default {
  name: "landing-layout",
  data: () => ({
    menuOpen:false,
    linksHeaderMain: [
      {
        link: '/service',
        tag: "a",
        text: "Услуги",
        scroll: false,
      },
    ],
    linksHeaderService: [
      {
        link: '#service',
        tag: "a",
        text: "Услуги",
        scroll: true,
      },
      {
        link: '/',
        tag: "a",
        text: "На главную",
        scroll: false,
      },
    ]
  }),
  components: {
    MobileMenu,
    Burger,
    HeaderLink,
    Footer,
    Header
  },
  
  methods: {
    menuOpened: function () {
      this.menuOpen = !this.menuOpen;
    }
  }
  
}
</script>

<style lang="scss">


</style>