<template lang="pug">
  li.header-right__item(@click="handleClick")
    router-link(
      :to="link"
      :tag='tag'
      v-smooth-scroll="scroll"
    )
      .header-right__link {{text}}

</template>

<script>
export default {
  name: "HeaderLink",
  
  props: {
    link: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: 'a'
    },
    text: {
      type: String,
      default: ''
    },
    scroll: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    }
  }
}
</script>

<style scoped lang="scss">

.header-right__link {
  color: $white;
  
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 20%;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-bottom: 0px solid #ffe100;
    box-shadow: inset 0px -1px 0px 0px #ffe100;
    transition: all 0.3s ease;
  }
  
  &:hover {
    &:after {
      opacity: 1;
      bottom: -0px;
    }
  }
  @include tablet{
    &:after{
      display: none;
    }
  }
}

</style>