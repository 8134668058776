<template lang="pug">
  div
    div(v-if='loading')
      Preloader
    div(v-else-if="errored")
      ResumeHide
    div(v-else-if='resume')
      ModalPaid
      ModalReviews
      ModalAnalysis(v-if="modalShow" @close='modalShow=false')
      .page.page-analysis(v-if='parsedDocument')
        .container
          .resume-title Резюме проанализировано!
          .resume-body
            .resume-name
              img(alt="cvboost" v-if='paid' src="@/assets/images/crown-blue.svg")
              |{{parsedDocument.personal.firstName}}
              |{{parsedDocument.personal.lastName}}
              || {{localeDate}} | {{localeTime}}
            .days(v-if='paid') Осталось дней доступа: {{getPaidDays}}
          section.section
            .resume-report.grid
              .resume-report__rating.grid-left(:class="getScoreData >= 97 ? 'resume-report_result-score_senior ' : getScoreData >= 30 && getScoreData < 97 ? 'resume-report_result-score_middle' : getScoreData < 30 ? 'resume-report_result-score_junior' : ''")
                .block-gray.block-gray_rating
                  .resume-report__rating-result
                    .resume-report__rating-result_current {{getScoreData}}
                    .resume-report__rating-result_max /100
                  .resume-report__result-info
                    span(v-if="getScoreData >= 97") Да это же отличное резюме!
                    span(v-if="(getScoreData >= 30) && (getScoreData < 97)") Неплохо! Но можно лучше!
                    span(v-if="getScoreData < 30") Тут есть над чем работать!
                  .resume-report__result-text Такое резюме отлично подходит на позицию <span>{{capitalize(resume.salaryData.level)}} {{capitalize(resume.salaryData.position)}}</span>! Добавить особо нечего.
                  .resume-report__result-note Рейтинг резюме – как резюме влияет на решение рекрутера связаться с кандидатом
                .resume-report_result-info
                  .resume-report_result-info_block
                    .resume-report_result-info_title.title-small Резюме подходит под вакансии:
                    .resume-report_info_fit-vacancy {{capitalize(resume.salaryData.position)}}-разработчик
                  .resume-report_result-info_title.title-small Общие данные:
                  .resume-report_info_details
                    .resume-report_info_detail
                      .resume-report_info_detail-key Слов в тексте
                      .resume-report_info_detail-val.resume-report_info_detail-val-negative {{fullResumeText}}
                    .resume-report_info_detail
                      .resume-report_info_detail-key Рекомендовано слов
                      .resume-report_info_detail-val {{wordsRecommend}}
                    .resume-report_info_detail
                      .resume-report_info_detail-key Опыт работы
                      .resume-report_info_detail-val {{ resume.relevantExperience.totalMonths | prettyExperience() }}
                    .resume-report_info_detail
                      .resume-report_info_detail-key Недостает навыков
                      .resume-report_info_detail-val.resume-report_info_detail-val-negative(v-tooltip="'Доступно в платной<br> версии'") {{getTagsData(resume.salaryData.position).missed.length}}
                    .resume-report_info_detail
                      .resume-report_info_detail-key Ошибок составления резюме
                      .resume-report_info_detail-val.resume-report_info_detail-val-negative(v-tooltip="'Доступно в платной<br> версии'") {{getTagsData(resume.salaryData.position).negative.length}}
                    .resume-report_info_detail
                      .resume-report_info_detail-key Найдено навыков
                      .resume-report_info_detail-val.resume-report_info_detail-val-positive {{getTagsData(resume.salaryData.position).positive.length}}
              .resume-report__recommendations.grid-right
                .block-gray.block-gray_recommendations
                  .resume-report__recommendations-head
                    .resume-report__recommendations-title {{renderRecommendations.length}} персональных рекомендаций
                    .resume-report__recommendations-note Внесите изменения в резюме, исходя из этих советов, чтобы его улучшить
                  ul.resume-report__recommendations-items
                    li.resume-report__recommendations-item(v-for="(item, i) in renderRecommendationsVisible" :key="item.desc")
                      .resume-report__recommendations-item-num {{i + 1}}
                      p.resume-report__recommendations-item-text(v-html="item.desc")
                    li.resume-report__recommendations-item(v-if='!paid')
                      .resume-report_recommend-more_text.resume-report_no-payment-tip(v-tooltip="'Доступно в платной версии'" @click="showModalPaid")
                        img(alt="cvboost" src="@/assets/images/plus-more.svg").resume-report_recommend-more
                        .resume-report_recommend-link_more Для этого резюме есть еще {{renderRecommendations.length - renderRecommendationsVisible.length}} рекомендаций
                          img(alt="cvboost" src="@/assets/images/lock.svg").resume-report_recommend-lock
                      .resume-report_recommend-more_text-descr Доступны в премиум версии
                    div(v-if='paid')
                      .resume-report__recommendations-item(@click="recommendationsCollapsedShow()")
                        .resume-report_recommend-more_text.resume-report_no-payment-tip
                          div(v-if='recommendationsCollapsed')
                            img(alt="cvboost" src="@/assets/images/plus-more.svg").resume-report_recommend-more
                            .resume-report_recommend-link_more
                              | Показать все рекомендации (еще {{renderRecommendations.length - renderRecommendationsVisible.length}})
                          div(v-if='!recommendationsCollapsed')
                            .resume-report_recommend-link_more Скрыть рекомендации
          section.section
            .grid
              .block-yellow.review.grid-left
                h4.h4 Нам важно ваше мнение
                .review-text Хотите похвалить или поругать CVBoost? Оставьте свой отзыв — и мы сделаем сервис еще лучше!
                Button.button-orange(label='Оставить отзыв о CV Boost' @click="showModalReviews()")
              .block-gray.block-gray_level.grid-right
                h4.h4 Примерный уровень зарплаты
                .resume-salary__block
                  .resume-salary__block-left
                    .resume-salary__desc В среднем, с таким резюме можно претендовать на
                    .resume-salary__value {{priceSet(renderSalary[0])}} ₽
                  .resume-salary__block-right
                    .resume-salary__desc В среднем, {{capitalize(resume.salaryData.level)}} {{capitalize(resume.salaryData.position)}} в Москве и области получает
                    .resume-salary__value.resume-salary__value_gray {{priceSet(renderSalary[1])}} ₽
                .resume-salary__note
                  .resume-salary__note-text * - Данные для Москвы и Московской области
                  .resume-salary__note-text ** - Данные с сайта career.habr.com на основе ~7.000 анкет
          section.section(v-if='!paid')
            .block-blue.block-blue_access
              .block-blue__left
                .img-content__wrap
                  img(alt="cvboost" src="@/assets/images/no-payment.svg").img-content
              .block-blue__right
                .access-title Откройте доступ к полному анализу своего резюме
                Button.button-blue.blue_access.button-radius.w-100.button-pay(label='<span>Открыть все рекомендации</span> <span class="price-pay">249 ₽</span>' scroll=false  @click="showModalPaid")
                .access-block
                  .title-small Что есть в платной версии:
                  ul.access-list.access-list_crown
                    li.access-item Доступ ко всем рекомендациям
                    li.access-item Что нужно изучить или подтянуть + полезные ссылки и материалы для развития этих навыков
                    li.access-item Выделение грамматических, пунктуационных и стилистических ошибок в тексте резюме
                .access-block
                  .title-small Почему CVBoost?
                  ul.access-list.access-list_star
                    li.access-item Самый выгодный сервис по улучшению резюме - результат быстро, по существу, без субъективных оценок и воды
                    li.access-item Сэкономит время на создание действительно работающего резюме
                    li.access-item Снизит количество отказов по резюме на ~50%*
                    li.access-item Увеличит количество собеседований на ~30-40%*
                    li.access-item Уменьшит время поиска работы, в среднем, до 2-3 недель
                  .footnote *информация из интервью с пользователями и из формы обратной связи
          section.section
            h4.h4.tags-title.text-center В тексте найдено {{tagsDataPosition.positive.length}} положительных тегов, {{tagsDataPosition.negative.length}} отрицательных тегов, {{tagsDataPosition.missed.length}} недостающих
            .grid
              .grid-left
                .tags-block
                  .tags-block__title Что в резюме хорошо
                  .tags-items
                    .tag.tag-positive(v-for="(item) in renderTags(tagsDataPosition.positive)" :key="item.title" v-tooltip="item.tip")
                      | {{item.title}}
                  .show-skills(v-if='!paid' v-tooltip="'Доступно в платной версии'" @click="showModalPaid") Показать все навыки ({{tagsDataPosition.positive.length}})
                .tags-block
                  .tags-block__title Что в резюме надо исправить
                  .tags-items
                    .tag.tag-negative(v-for="(item) in renderTags(tagsDataPosition.negative)" :key="item.title" v-tooltip="item.tip")
                      | {{item.title}}
                  .show-skills(v-if='!paid' @click="showModalPaid" v-tooltip="'Доступно в платной версии'") Показать все навыки ({{tagsDataPosition.negative.length}})
                .tags-block
                  .tags-block__title Что желательно добавить
                  .tags-items
                    .tag.tag-default(v-for="(item) in renderTags(tagsDataPosition.missed)" :key="item.title" v-tooltip="item.tip")
                      | {{item.title}}
                  .show-skills(v-if='!paid'  @click="showModalPaid" v-tooltip="'Доступно в платной версии'") Показать все навыки ({{tagsDataPosition.missed.length}})
              
              .grid-right.about-wrapper
                .about-block
                  .about-title Обо мне
                  .about-block__desc
                    | {{parsedDocument.aboutMe}}
                .about-work
                  .about-title Опыт работы по профессии {{parsedDocument.position.title}}: {{ resume.relevantExperience.totalMonths | prettyExperience() }}
                  
                  .about-block__desc( v-for="(item, i) in experience")
                    .about-block__desc-position {{item.position}}
                    .about-block__desc-company {{item.company}}
                    .about-block__desc-time
                      | {{ dateDiff(item.start, item.end) | prettyExperience }}
                      | ({{ date(item.start) }} – {{ item.end ? date(item.end) : ' сейчас' }})
                    .about-block__desc-text(v-html="rawText(item.description)")
          
          section.section
            .block-blue.block-blue_access.efficient
              .block-blue__left
                .img-content__wrap
                  img(alt="cvboost" src="@/assets/images/no-payment.svg").img-content
              .block-blue__right
                h4.h4 Как теперь сделать резюме эффективней?
                ul.list-num
                  li.list-num__item
                    .num 1
                    .list-num_item-text Измените текст резюме, используя наши рекомендации
                  li.list-num__item
                    .num 2
                    .list-num_item-text Отправьте новое резюме на <a href='javascript:void(0);'  @click="showModalAnalysis"> повторную проверку</a>
                  li.list-num__item
                    .num 3
                    .list-num_item-text Сравните получившийся результат с предыдущим
                Button.button-blue.w-100(label='<span>Проверить резюме еще раз</span>' scroll=false @click="showModalAnalysis")
    div(v-else)
      ResumeDownload

</template>

<script>
import Button from "@/components/elements/Buttons/Button";
import Input from "@/components/elements/Input";
import Checkbox from "@/components/elements/Checkbox";
import moment from 'moment'
import {mapState} from 'vuex';
import ResumeDownload from "@/components/ResumeDownload";
import ResumeHide from "@/components/ResumeHide";

import TAGS_DATA_SCORING from '@/data'

import SALARY_PRESETS from "@/data/salary-presets.js"

import Preloader from "@/components/Preloader";
import ModalPaid from "@/components/Modals/ModalPaid";
import ModalAnalysis from "@/components/Modals/ModalAnalysis";
import {PAYMENT_COOKIE, PAYMENT_DAYS} from '@/variables'
import ModalReviews from "@/components/Modals/ModalReviews";



/* eslint-disable no-useless-escape */


export default {
  name: "Analysis",
  components: {ModalReviews, ModalAnalysis, ModalPaid, Preloader, ResumeHide, ResumeDownload, Checkbox, Input, Button},
  data() {
    return {
      modalShow: false,
      tagsData: TAGS_DATA_SCORING,
      salaryPresets: SALARY_PRESETS,
      recommendationsCollapsed: true,
    }
  },
  methods: {
  
  
    getCookieTest: function (name) {
      let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    
    
    
    showModalPaid() {
      this.$modal.show('modal-paid');
      this.$metrika.reachGoal(85906765,'reachGoal','modal-paid-open')
      this.$gtag.event('ga-modal-paid-open', {
        'event_category': 'ga-paid',
        'event_label': 'ga-paid clicked',
        'value': 1
        });
    },
    
    showModalReviews() {
      this.$modal.show('modal-reviews');
    },
    showModalAnalysis() {
      this.modalShow = true
      setTimeout(function () {
        this.$modal.show('modal-analysis');
      }.bind(this), 100)
  
      this.$metrika.reachGoal(85906765,'reachGoal','repeat-resume')
      this.$gtag.event('ga-resume-link', {
        'event_category': 'ga-resume',
        'event_label': 'ga-resume clicked',
        'value': 3
      });
  
  
    },
    
    capitalize(data) {
      return `${this.lodash.capitalize(data)}`
      
    },
    
    rawText: function (txt) {
      return txt
        .replace(/\n/g, '<br>')
        .replace(/(https?:\/\/[^\s]+)/g, (url) => {
          return ' <a href="' + url + '" target="_blank">' + url.replace(/^https?\:\/\//i, '') + '</a>'
        })
    },
    
    
    rawTextHtml: function (data) {
      const string = this.rawText(data)
      const txtHtml = new DOMParser().parseFromString(string, "text/html")
      return txtHtml.body.innerHTML
    },
    
    
    dateDiff(start, end) {
      let end2 = end || Date.now();
      return moment(end2).diff(start, 'months')
    },
    date(d) {
      return moment(d).format('YYYY');
    },
    
    
    getTagsData: function (position) {
      const result = {
        positive: [],
        missed: [],
        negative: []
      }
      const cvTagsData = this.resume.tags
      const configTagsData = TAGS_DATA_SCORING[position]
      if (configTagsData) {
        for (const positiveItem of configTagsData.positive) {
          const found = cvTagsData.find(t => t.tag.key === positiveItem.key)
          if (found) {
            result.positive.push(positiveItem)
          } else {
            result.missed.push(positiveItem)
          }
        }
        for (const negativeItem of configTagsData.negative) {
          const found = cvTagsData.find(t => t.tag.key === negativeItem.key)
          if (found) {
            result.negative.push(negativeItem)
          }
        }
      }
      
      return result
    },
    
    
    
    renderTags: function (positive) {
      const visibleTags = !this.paid ? positive.slice(0, 2) : positive
      return visibleTags
    },
    
    
    //////// Доработать
    
    recommendationsCollapsedShow: function () {
      return this.recommendationsCollapsed = !this.recommendationsCollapsed
    },
    
    createMarkInstance: function (keywords, {color}) {
      const container = document.querySelector('.about-wrapper')
      
      const className = '_' + this.makeId(5)
      const options = {
        className: ['cv-text-highlight', className].join(' '),
        element: 'mark',
        exclude: ['mark'],
        separateWordSearch: false,
        each(el) {
          el.style.backgroundColor = color
        }
      }
      
      // eslint-disable-next-line no-undef
      const instance = new Mark(container)
      instance.mark(keywords, options)
      return {
        instance,
        className
      }
    },
    
    
    makeId: function (length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    
    
   
  
    
    renderResumeHighlight: function (tagsData) {
      //eslint-disable-next-line no-unused-vars
      
      const cvTagsData = this.resume.tags
      
      const getKeywords = (tags) => {
        const keywords = []
        for (const positiveItem of tags) {
          const found = cvTagsData.find(t => t.tag.key === positiveItem.key)
          if (found && found.data.tooltipHtml) {
            const matches = found.data.tooltipHtml.match(/<mark>(.*?)<\/mark>/g)
            if (matches) {
              keywords.push(...matches.map(val => {
                return val.replace(/<\/?mark>/g, '')
              }))
            }
          }
        }
        
        return keywords
      }
      
      const keywords = {
        positive: getKeywords(tagsData.positive),
        negative: getKeywords(tagsData.negative)
      }
      
      keywords.positive = keywords.positive.filter(Boolean)
      keywords.negative = keywords.negative.filter(Boolean)
      
      // keywords.positive = state.paid ? keywords.positive : keywords.positive.slice(0, 2)
      // keywords.negative = state.paid ? keywords.negative : keywords.negative.slice(0, 2)
  
      const positiveInstance = this.createMarkInstance(keywords.positive, {
        color: '#D5EEC8'
      })

      const negativeInstance = this.createMarkInstance(keywords.negative, {
        color: '#ea7f7f'
      })
      //
      /* eslint-disable no-useless-escape */
  
  
    
    
      console.log('positiveInstance', positiveInstance)
      console.log('negativeInstance', negativeInstance)
      
      
    },
    priceSet: function(data){
      let price_sep;
      const price = Number.prototype.toFixed.call(parseFloat(data) || 0, 0);
      price_sep = price.replace(/(\D)/g, ","),
      price_sep = price_sep.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    
      return price_sep;
    }
    
   
  },
  filters: {
    prettyExperience(exp, isNum = false) {
      let total = exp;
      
      if (exp === null) {
        return 'сейчас'
      }
      
      if (isNum) {
        total = moment().diff(exp, 'months');
      }
      
      const years = (total / 12).toFixed(0);
      const months = total - years * 12;
      const yearsTxt = /\d*1\d$/.test(years) || /[05-9]$/.test(years) ? ' лет' : (/1$/.test(years) ? ' год' : ' года');
      const monthsTxt = /\d*1\d$/.test(months) || /[05-9]$/.test(months) ? ' месяцев' : (/1$/.test(months) ? ' месяц' : ' месяца');
      const yearsAll = years + yearsTxt;
      const monthsAll = months + monthsTxt;
      
      return `${years > 0 ? `${yearsAll}` : ''}${months > 0 ? ` ${monthsAll}` : ''}`
    }
  },
  computed: {
    
    localeDate() {
      return (new Date(this.resume.scoring.parsedDocument.resumeUpdated)).toLocaleDateString()
    },
    localeTime() {
      return (new Date(this.resume.scoring.parsedDocument.resumeUpdated)).toLocaleTimeString()
    },
    
    fullResumeText: function () {
      const targetPositions = this.resume.relevantExperience.targetPositions;
      const fullAboutMeText = this.parsedDocument.aboutMe;
      const fullDescriptionText = targetPositions.map(exp => this.lodash.get(exp, 'description', null)).filter(Boolean).join('\n') // доделать
      const fullSkillsText = this.resume.scoring.parsedDocument.skills.join();
      const fullResumeSymbol = (fullAboutMeText + fullDescriptionText + fullSkillsText).split(/[ ,.]+/).length;
      
      return fullResumeSymbol
      
    },
    wordsRecommend: function () {
      const cvWordsRecommendLevel = this.resume.salaryData.level
      let cvWordsRecommend
      if (cvWordsRecommendLevel === 'junior') {
        cvWordsRecommend = '300 – 500'
      } else if (cvWordsRecommendLevel === 'middle') {
        cvWordsRecommend = '500 – 700'
      } else if (cvWordsRecommendLevel === 'senior') {
        cvWordsRecommend = '700 – 1500'
      }
      return cvWordsRecommend
    },
    
    getScoreData: function () {
      const salaryData = this.resume.salaryData
      const scoringConfig = this.resume.scoringConfig
      const computedScoring = this.resume.computedScoring
      const formLevel = this.resume.salaryData.level
      const positionSeniorThreshold = scoringConfig.level[salaryData.position] ? scoringConfig.level[salaryData.position][formLevel] : 1
      const positionScoringValue = computedScoring[salaryData.position] ? computedScoring[salaryData.position].value : 0
      let scoringPercent = Math.round(100 * positionScoringValue / positionSeniorThreshold)
      scoringPercent = Math.max(17, Math.min(97, scoringPercent))
      return scoringPercent
      
    },
    tagsDataPosition: function () {
      return this.getTagsData(this.resume.salaryData.position)
      
    },
    renderRecommendations: function () {
      
      const items = []
      const tagsData = this.tagsDataPosition
      const cvTagsData = this.resume.tags
      for (const positiveItem of tagsData.positive) {
        const found = cvTagsData.find(t => t.tag.key === positiveItem.key)
        if (found) {
          const isLowLevel = found.data.level === 1
          if (isLowLevel) {
            items.push({
              title: positiveItem.title,
              desc: positiveItem.smallDesc || positiveItem.smalldesc,
              tooltipHtml: found.data.tooltipHtml
            })
          }
        }
      }
      for (const negativeItem of tagsData.negative) {
        items.push({
          title: negativeItem.title,
          desc: negativeItem.desc,
          tooltipHtml: null
        })
      }
      for (const missedItem of tagsData.missed) {
        items.push({
          title: missedItem.title,
          desc: missedItem.zeroDesc || missedItem.zerodesc,
          tooltipHtml: null
        })
      }
      
      
      return items
      
      
    },
    renderRecommendationsVisible: function () {
      const NO_PAYMENT_RECOMMENDATIONS_VISIBLE = 2
      const maxItemsVisibleCollapsed = this.paid ? 5 : 3
      let visibleItems;
      if (this.paid) {
        visibleItems = this.recommendationsCollapsed
          ? this.renderRecommendations.slice(0, maxItemsVisibleCollapsed)
          : this.renderRecommendations
      } else {
        visibleItems = this.renderRecommendations.slice(0, NO_PAYMENT_RECOMMENDATIONS_VISIBLE)
      }
      
      return visibleItems
    },
    
    
    renderSalary: function () {
      const salaryData = this.resume.salaryData
      const salaryPreset = this.salaryPresets[salaryData.position][salaryData.level]
      const scoreData = this.getScoreData
      const {min: minAbs, max: maxAbs} = salaryPreset.salary
      const avgAbs = (maxAbs + minAbs) / 2
      const selfMin = Math.max(0, scoreData - 18)
      const selfMax = Math.min(100, scoreData + 18)
      const selfAvg = (selfMin + selfMax) / 2
      const percent = (maxAbs - minAbs) / 100
      const selfAvgAbs = minAbs + (percent * selfAvg)
      
      
      return [selfAvgAbs, avgAbs]
      
    },
    
 
    
    getPaidDays: function() {
      const dateCookies = moment(Number(this.getCookieTest(PAYMENT_COOKIE))).format("DD MMM YYYY")
      const date = moment(new Date()).format("DD MMM YYYY");
      return (moment(dateCookies).diff(date, 'days') + PAYMENT_DAYS)
    },
    
    ...mapState({
      resume: state => state.resume,
      scoring: state => state.resume.scoring,
      experience: state => state.resume.scoring.parsedDocument.experience.experience,
      parsedDocument: state => state.resume.scoring.parsedDocument,
      paid: state => state.paid,
      link: state => state.link,
      
      loading: state => state.loading,
      errored: state => state.errored,
      
    }),
    
    
  },
  mounted() {
    this.$nextTick(function () {
      this.renderResumeHighlight(this.tagsDataPosition)
    })
    
    this.$metrika.reachGoal('modal-paid-open')
    this.$metrika.reachGoal('repeat-resume')
  },
  updated() {
    this.$nextTick(function () {
      this.renderResumeHighlight(this.tagsDataPosition)
    })
  },
  
}
</script>

<style scoped lang="scss">


.page-analysis {
  overflow-y: hidden;
  
  .container {
    max-width: 1230px;
  }
}


.resume-title {
  font-size: 28px;
  font-family: $RobotoMedium;
  text-align: center;
  margin-bottom: 15px;
  @include tablet {
    font-size: 20px;
  }
}

.resume-body {
  margin-bottom: 50px;
  
}

.days{
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #C0C4CC;
}

.resume-name {
  display: flex;
  justify-content: center;
  font-size: 28px;
  color: #BBC7DB;
  text-align: center;
  margin-bottom: 7px;
  @include tablet {
    font-size: 18px;
  }
  img{
    margin-right: 10px;
  }
}

.resume-report__rating-result {
  display: flex;
  align-items: center;
  font-size: 52px;
  line-height: 61px;
  font-family: $RobotoBold;
  margin-bottom: 50px;
  @include tablet {
    margin-bottom: 25px;
  }
}


.resume-report_result-score_senior {
  .resume-report__rating-result_current,
  .resume-report__result-info {
    color: #67C23A;
  }
}

.resume-report_result-score_middle {
  .resume-report__rating-result_current,
  .resume-report__result-info {
    color: #409EFF;
  }
}

.resume-report_result-score_junior {
  .resume-report__rating-result_current,
  .resume-report__result-info {
    color: #FF8400;
  }
}

.resume-report__rating-result_max {
  color: #BBC7DB;
}

.resume-report__result-info {
  font-size: 24px;
  line-height: 28px;
  font-family: $RobotoBold;
  margin-bottom: 25px;
}

.resume-report__result-text {
  line-height: 22px;
  color: #2F394A;
  margin-bottom: 30px;
  
  span {
    font-family: $RobotoBold;
  }
}

.resume-report__result-note {
  font-size: 14px;
  color: #C0C4CC;
}

.resume-report_result-info {
  padding: 40px;
  border-radius: 5px;
  @include tablet {
    padding: 25px;
  }
}

.block-gray_rating {
  margin-bottom: 25px;
}

.resume-report_result-info_block {
  margin-bottom: 35px;
}


.resume-report_info_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.resume-report_info_fit-vacancy {
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  font-family: $RobotoMedium;
}

.resume-report_info_detail-key {
  font-size: 14px;
}

.resume-report_info_detail-val {
  font-size: 14px;
  font-family: $RobotoBold;
  
}

.resume-report_info_detail-val-negative {
  color: #FF4D00;
  font-family: $RobotoBold;
}

.resume-report_info_detail-val-positive {
  color: #79C300;
}

.block-gray_recommendations {
  padding-left: 80px;
  @include tablet {
    padding-left: 53px;
  }
}

.resume-report__recommendations-title {
  font-family: $RobotoMedium;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 7px;
}

.resume-report__recommendations-note {
  font-size: 16px;
  color: #8E9FBC;
  line-height: 19px;
}


.resume-report__recommendations-head {
  padding: 0 0 47px 0px;
  border-bottom: 1px solid #BBC7DB;
}

.resume-report__recommendations-item {
  padding: 35px 0px;
  border-bottom: 1px solid #BBC7DB;
  position: relative;
  
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  @include tablet {
    border-bottom: none;
  }
  
}


.resume-report__recommendations-item-num {
  position: absolute;
  left: -50px;
  top: 25px;
  font-style: normal;
  font-family: $RobotoMedium;
  font-size: 36px;
  line-height: 59px;
  color: rgba(64, 158, 255, 0.168875);
  @include tablet {
    left: -38px;
    top: 20px;
    font-size: 24px;
  }
}

.resume-report__recommendations-item-text {
  color: #2F394A;
  
  ::v-deep {
    b {
      font-family: $RobotoBold;
    }
  }
  
  @include tablet {
    font-size: 14px;
  }
  
}

.resume-report_recommend-more {
  @include width-flex(26px)
  height: 26px;
  position: absolute;
  left: -50px;
  top: 35px;
  @include tablet {
    left: -45px;
  }
  
}

.resume-report_recommend-more_text {
  margin-bottom: 5px;
  display: inline-block;
}

.resume-report_recommend-link_more {
  font-size: 24px;
  color: #409EFF;
  @include tablet {
    font-size: 18px;
  }
}

.resume-report_recommend-lock {
  @include width-flex(12px)
  height: 15px;
  margin-left: 10px;
  
}

.resume-report_recommend-more_text-descr {
  font-size: 18px;
  color: #8E9FBC;
  @include tablet {
    font-size: 14px;
  }
}

.review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .h4 {
    margin-bottom: 25px;
  }
}

.section {
  margin-bottom: 25px;
  
}

.review-text {
  font-size: 18px;
  color: #2F394A;
  margin-bottom: 45px;
}

.block-gray_level {
  padding-left: 80px;
  @include tablet {
    padding-left: 25px;
  }
  
  .h4 {
    margin-bottom: 34px;
  }
}


.resume-salary__block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @include tablet {
    flex-direction: column;
  }
}

.resume-salary__desc {
  letter-spacing: -0.4px;
  color: #707991;
  margin-bottom: 12px;
}

.resume-salary__note {
  font-size: 12px;
  line-height: 20px;
  color: #9ea9c1;
}

.resume-salary__block-left,
.resume-salary__block-right {
  @include width-flex(48%)
  @include tablet {
    @include width-flex(100%)
    text-align: center;
    border-bottom: 1px solid rgba(187, 199, 219, 0.35);
    padding-bottom: 20px;
    padding-top: 20px;
    &:first-child {
      padding-top: 0;
    }
  }
}


.resume-salary__value {
  font-size: 36px;
  font-family: $RobotoBold;
  color: #2F394A;
}

.resume-salary__value_gray {
  color: #BBC7DB;
}

.block-blue_access {
  display: flex;
  align-items: center;
  @include tablet {
    flex-direction: column;
  }
}

.block-blue__left {
  @include width-flex(40%)
  @include tablet {
    @include width-flex(100%)
  }
}

.block-blue__right {
  @include width-flex(60%)
  @include tablet {
    @include width-flex(100%)
    margin-top: 30px;
  }
}


.access-title {
  font-size: 30px;
  color: #004A96;
  font-family: $RobotoMedium;
  margin-bottom: 35px;
  @include tablet {
    font-size: 24px;
    text-align: center;
  }
}

.button-pay {
  margin-bottom: 35px;
}

.access-block {
  margin-bottom: 40px;
  @include last-mb-0
  
}


.footnote {
  font-size: 14px;
  color: #C0C4CC;
}

.efficient {
  .h4 {
    color: #173891;
    margin-bottom: 15px;
    font-family: $RobotoMedium;
  }
  
  .block-blue__right {
    padding-left: 50px;
    padding-right: 50px;
    @include tablet {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
}

.list-num__item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  @include tablet {
    align-items: flex-start;
  }
  
  a {
    color: #409EFF;
  }
}

.num {
  font-size: 50px;
  color: rgba(64, 158, 255, 0.168875);
  font-family: $RobotoMedium;
  margin-right: 30px;
  @include tablet {
    font-size: 40px;
  }
}

.list-num {
  margin-bottom: 40px;
}

.list-num_item-text {
  font-size: 18px;
  color: #2F394A;
}

.show-skills {
  display: flex;
  align-items: center;
  color: #409EFF;
  
  &:hover {
    cursor: pointer;
  }
  
  &:before {
    content: '';
    @include background-contain('lock.svg')
    @include width-flex(15px)
    height: 15px;
    margin-right: 10px;
  }
}

.tags-block__title {
  margin-bottom: 25px;
  font-family: $RobotoBold;
  font-size: 20px;
  line-height: 23px;
}


.tags-title {
  margin-bottom: 50px;
}

.tag {
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 7px 12px;
  border-radius: 7px;
}

.tags-items {
  margin-bottom: 10px;
}

.tag-positive {
  background: #FED831;
}

.tag-negative {
  background: #FFB0B0;
}

.tag-default {
  background: #D1E7FF;
}

.tags-block {
  margin-bottom: 50px;
}

.about-block {
  border: 1px solid rgba(64, 158, 255, 0.27);
  padding: 16px 32px;
  border-radius: 5px;
  line-height: 24px;
  font-size: 18px;
  color: #606266;
  margin-bottom: 25px;
}

.about-title {
  margin-bottom: 15px;
  font-size: 20px;
  color: #C0C4CC;
}

.about-work {
  padding: 16px 32px;
  @include tablet {
    max-height: 300px;
    overflow: auto;
  }
  
}

.about-block__desc-position {
  color: #303133;
  margin-bottom: 10px;
  font-family: $RobotoBold;
  font-size: 18px;
}

.about-block__desc-company {
  margin-bottom: 5px;
  color: #909399;
  font-size: 18px;
}

.about-block__desc-time {
  //display: flex;
  margin-bottom: 7px;
  color: #909399;
  font-size: 18px;
}

.about-block__desc-text {
  line-height: 24px;
  color: #606266;
  font-size: 18px;
  
  ::v-deep a {
    color: #ff8562;
    text-decoration: none;
  }
}

.about-block__desc {
  margin-bottom: 25px;
  
}

.resume-report_no-payment-tip {
  &:hover {
    cursor: pointer;
  }
}

</style>