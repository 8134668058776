export default {
  positive: [
    {
      key: 'prototype-tools',
      title: 'Инструменты прототипирования',
      zeroDesc: '<b>Какие сервисы помогали вам на этапе прототипа продукта, фичи или сайта?</b> Например: Invision, Adobe XD (InDesign), Mocksplus, Zeplin, Figma, Miro и т.п. И чем именно они были полезны? Если использовали, распишите поподробней когда и для чего. Или расскажите про свой способ прототипирования. Вдруг вы непризнанный гений. Это полезный и важный навык. Мы, например, уважаем Notion и храним там материалы по продуктам, а рисовать user flow и user journey карты любим в Miro',
      smallDesc: '<b>Вы упомянули об использовании инструментов прототипирования – рекомендуем детальнее расписать какие, когда и для чего вы использовали</b> [тег для подсветки из текста]. Возможно расширить список упоминаний. Или расскажите про свой способ прототипирования. Вдруг вы непризнанный гений. Расскажите об этом рекрутерам так чтобы они обратили на это внимание',
      tip: '<mark>Инструменты прототипирования</mark> – прим.: Figma, Sketch, Notion, Bubble, Adala, Mocksplus'
    },
    {
      key: 'design-and-prototyping',
      title: 'Дизайн и Прототипы',
      zeroDesc: '<b>Мы не нашли в тексте упоминаний инструментов прототипирования.</b> Либо это ошибка, либо вы просто забыли написать об этом. Добавьте информацию о том какие сервисы помогали вам на этапе прототипа продукта/фичи/сайта и чем именно они помогали? Мы в команде например любим графично отражать результаты встреч в notion и рисовать user flow и user journey карты в Miro',
      smallDesc: '<b>Мы увидели, что вы пишете о работе с инструментами прототипирования при создании продукта/фичи - это классно! Cоветуем вам расширить список и написать побольше о том, как эти инструменты помогают вам - эта информация очень помогает рекрутеру при отборе',
      tip: '<mark>Дизайн и Прототипы</mark> - мы нашли слова, связанные с дизайном и протипированием'
    },
    {
      key: 'analytics-tools',
      title: 'Инструменты аналитики',
      zeroDesc: '<b>Кажется, вы не упомянули ни одного инструмента аналитики данных.</b> Мы считаем, что для вашей специальности этот навык очень важен и уверены, что какой-то опыт у вас всё-таки был... Попробуйте добавить информации об этом, достаточно будет даже простого перечисления. Например так: “Аналитика в компании Х: Yandex.Метрика, Google Аналитика, OWOX, Tableau”. Но можно и посложнее, например так: “Настроил импорт данных с сайта и рекламных расходов в Google BigQuery с помощью OWOX BI, где рассчитывались основные показатели по когортам, а по ним автоматически генерировались отчёты по когортномум анализу в Google Sheets”',
      smallDesc: '<b>Очень хорошо, что вы пишете об инструментах вэб аналитики.</b> Это один из компонентов, на которые действительно смотрит хороший HR. При этом, мы советуем расширить список инструментов, с которыми вы работали или подробнее описать процесс.... Например так: “Опыт работы с BI инструментами Data Studio / Power BI / Tableau; работы в сервисах Google Analytics / Google Tag Manager / Google BigQuery” или даже так: “Регулярное построение и рефакторинг атрибуционных кросc-канальных отчетов/дашбордов через OWOX, Data Studio, Power BI, BigQuery”',
      tip: '<mark>Инструменты аналитики</mark> - инструменты, которые можно использовать в аналитике продуктов'
    },
    {
      key: 'product-metrics',
      title: 'Продуктовые метрики',
      zeroDesc: '<b>Наши консультанты уверены, что рекрутеры часто ищут в резюме продуктовые, маркетинговые и бизнес-метрики.</b> У вас мы об этом ничего не нашли. Если у вас был такой опыт, расскажите какими показателями пользовались для измерения привлечения пользователей, использования продукта, монетизации. Вот хороший пример: “Мониторинг unit-экономики: ARPU, CAC, LTV, mROI, P&L”',
      smallDesc: '<b>В тексте резюме вы упомянули несколько продуктовых метрик и это хорошо.</b> Но ещё лучше будет, если вы немного расширите информацию о них.... Или много. Вот хороший пример: “Показатели продуктовой корзины: gross margin - 66%, CAC - на уровне 4-х ARPU, revenue MoM- 20%, b2b new product churn rate – 5% в год, b2с new product churn rate – 10% в год, также новые продукты позволили сократить отток по базовым продуктам компании на 10-15%, что позволило заработать N миллионов долларов”',
      tip: '<mark>Продуктовые метрики</mark> - в резюме встречаются упоминание продуктовых метрик'
    },
    {
      key: 'achievements-product-metrics',
      title: 'Достижения в продуктовых метриках',
      zeroDesc: '<b>Обязательно упомяните ваши достижения не только на словах, но и в цифрах.</b> Хорошо отражает качество вашей работы рост продуктовых и бизнес-метрик - напишите об этом. Можно так: “Рост выручки в 3 раза (237 млн. - 82млн. руб.), Увеличил ARPPU на 41% (239тыс. – 171 тыс. руб.), в основном за счет увеличения частотности использования сервиса)”',
      smallDesc: '<b>Если уж пишете о метриках, лучше всего быть максимально конкретным:</b> расскажите какие из них удалось изменить и сколько на это ушло времени. Можно так: “Рост выручки на 90% за счёт определения и устранения узких мест воронки;  Увеличение конверсии на 48%;  Увеличение кол-ва заказов на 81%;  Рост ARPPU на 15,6%; Рост ARPU на 72%;  Уменьшение количесва прерываний покупки на 33%; NPS: +21 пункт (53/32)”',
      tip: '<mark>Достижения в продуктовых метриках</mark> - достижения и результаты в продуктовых метриках'
    },
    {
      key: 'gt-product',
      title: 'Навыки продакта',
      zeroDesc: '<b>Было бы неплохо добавить в резюме информации о вашей рутине при работе над продуктом.</b> Мы имеем в виду ваш опыт с общения с пользователем, проверки гипотез, использования метрик, анализа конкурентов и т. д., Посмотрите наш пример: “Большой опыт во всём, что связано с User Experience: исследования, проектирование UI, тестирование с привлечением пользователей”',
      smallDesc: '<b>Нашли в вашем резюме несколько слов о том как вы работаете с продуктом. Но этого маловато.</b> Давайте попробуем разнообразить? Расскажите о общении с пользователем, проверке гипотез, использовании метрик, анализе конкурентов и т. д. Посмотрите наш пример: “определил roadmap, исходя из целей компании, исследования проблем клиентов, бизнес-требований, расчёта unit экономики, спроектировал ux-архитектуру нового приложения, провел cust-dev для проектирования новых функций”',
      tip: '<mark>Навыки продуктолога</mark> - есть слова, связанные с продуктовыми навыками'
    },
    {
      key: 'gt-scrum',
      title: 'Мастер гибких методологий',
      zeroDesc: '<b>Если в одной из ваших команд вы применяли гибкие методологии, обязательно напишите об этом!</b> Можно как-то так: “При построении и развитии продуктов использую методики lean, JTBD, HADI, GrowthHacking, CustDev, прорабатываю пользовательские сценарии, анализирую рынок, конкурентов, связи продуктовых метрик и бизнеса; Имею практический опыт работы по методологии Lean/Agile”. Но можно и проще: “Тесное взаимодействие с командой разработчиков (в качестве Product Owner) по Scrum”',
      smallDesc: '<b>Мы считаем, что вы пробовали работать в гибких методологиях и вам не понаслышке знаком фреймворк Scrum.</b> Теперь мы хотим знать больше! Попробуйте больше подчеркнуть ваше участие в команде. Для этого можно, например, упомянуть какие из мероприятий скрама по-вашему являются наиболее эффективными, а от каких можно отказаться',
      tip: '<mark>Мастер гибких методологий</mark> - вы знаете чем отличается Scrum от Kanban, а Agile для вас - не пустой звук, а песня!'
    },
    {
      key: 't-shaped',
      title: 'Универсальный герой',
      zeroDesc: '<b>Есть ощущение, что вы всю жизнь шли прямо вперёд, чтобы развиваться как узконаправленный специалист и это круто!</b> Но если в вашей жизни был и другой опыт, не стесняйтесь, напишите об этом! Людей которые имеют глубокую экспертизу в одной области и широкий спектр знаний в других, называют T-shaped специалистом и, конечно же, очень ценят на рынке',
      smallDesc: '<b>Судя по резюме, у вас есть экспертиза в разных сферах.</b> Людей которые имеют глубокую экспертизу в одной области и широкий спектр знаний в других, называют T-shaped специалистами. Сейчас к этому стремится вся digital-индустрия. Чем больше вы напишете о сферах, где вы компетентны и чем глубже раскроете, тем более "T" образной персоной будете считаться. Но не переборщите с нерелевантным профессии опытом, можно показаться недостаточно компетентным в своём основном поле',
      tip: '<mark>Универсальный герой</mark> - такой навык получают те, у кого есть глубокая экспертиза в одном вопросе и широкий спектр знаний в других, так называемые T-shaped специалисты'
    },
    {
      key: 'wants-marketing',
      title: 'Маркетолог',
      zeroDesc: '<b>Сейчас давольно много появлятся вакасний с мерткетинговой направленностью.</b> Пример: Product Marketing Manager. Они хорошо оплачиваются. Поэтому если у вас был опыт в маркетинге, не стесняйтесь рассказать о нём, даже если вы претендуете на другую должность',
      smallDesc: '<b>Из-за некоторых формулировок наши алгоритмы решили, что вам интересно развитие в маркетинге.</b> Если алгоритмы не ошиблись, попробуйте написать ещё одно резюме, но уже на позицию маркетолога, а в этом использовать другие формулировки. Если вы всё же твёрдо решили развиваться НЕ маркетолог, просто переформулируйте эти места',
      tip: '<mark>Маркетинг</mark> – есть упоминания опыта в маркетнге или желания углубиться в марткетинг'
    },
    {
      key: 'made-myself',
      title: 'Ориентация на результат',
      zeroDesc: '<b>Есть ощущение, что в тексте вы много говорите о том, что делали, но не говорите о том, что СДЕЛАЛИ.</b> Это не очень информативно для рекрутенра. Попробуйте переформулировать текст о ваших позициях, исходя из результатов - что было сделано. Для этого используйте слова вроде “создал(а)”, “внедрил(а)” или “оптимизировал(а)”',
      smallDesc: '<b>Кажется, вы много пишете о своих задачах, а о результатах значительно меньше.</b> Попробуйте переписать те куски, где пишете формулировки вроде “я делал(а)” так, чтобы получалось “я сделал(а)”. Для этого используйте слова вроде “реализовал(а)”, “организовал(а)” или “улучшил(а)”',
      tip: '<mark>Ориентация на результат</mark> - это важное качество для любого независимого специалиста. Можно показать свою целеустремленность, немного изменив формулировки в резюме'
    },
    {
      key: 'exp-startup',
      title: '"Покоритель стартапов"',
      zeroDesc: '<b>Если вдруг одна из компаний, которые перечислены в вашем опыте работы, была стартапом, напишите об этом прямо</b> - это может быть большим плюсом для рекрутера, т.к. частенько в сартапах работать сложнее, нагрузка больше, а опыт, соответственно, ценнее. “Опыт работы в стартапах, во внутренних стартапах-стримах крупных корпораций, общении с финансовыми службами, построения юнит-экономики, проведении презентаций и защиты проектов перед стейкхолдерами”',
      smallDesc: '<b>Наши алгоритмы решили, что у вас есть опыт работы в стартапах.</b> Напишите пожалуйста ещё пару слов об этом опыте, нам кажется, сейчас маловато информации. Например так: “Запустили на международные рынок два собственных стартапа, (IT, медицина, биомедицина, нано- технологии). Консультировала 60+ стартапов по CustDev, клиентскому опыту, разработке продуктов, бизнес-модели и монетизации”',
      tip: '<mark>"Покоритель стартапов"</mark> – кажется, вы работали в стартапах. Неплохо!'
    },
    {
      key: 'methodologies',
      title: 'Методологии разработки',
      zeroDesc: '<b>Пропишите в резюме какие методологии разработки вы использовали и о каких знаете (изучали).</b> Например: Scrum, Kanban. Большинство современных компаний ценят такие знания, а ещё больше опыт построения. Это помогает быстрее влиться в процесс',
      smallDesc: '<b>Видим, что пишите про метологии разработки. Это круто.</b> Угубитесь немного в описании, расширьте количество упоминаний. Для чего какие метологии использовали? Почему выбрали именно их и т.д. Ну вы поняли',
      tip: '<mark>Методологии разработки</mark> – упомнинания и отсутствие упоминаний методологий разработки'
    },
    {
      key: 'from-scratch',
      title: 'Разработка проекта с нуля',
      zeroDesc: '<b>Пробовали ли вы когда-нибудь развивать проект с нуля? От идеи через MVP до взрослого работающего классного продукта.</b> Если пробовали, обязательно напишите об этом опыте: упомяните, что проект был "под ключ" и дальше расскажите о деталях управления, customer development, основных метриках и конечно аналитике',
      smallDesc: '<b>Пробовали запускать проект c нуля? Начинали разработку продукта? Напишите об этом детальнее!</b> Это отличный опыт. Расскажите почему не получилось (если не получилось) и какие выводы вы сделали. Это многое расскажет о вас и поможет найти максимально подходящее предложение',
      tip: '<mark>"С нуля"</mark> – разработка проекта с нуля, от идеи до реализации'
    },
    {
      key: 'team-management',
      title: 'Опыт общего управления',
      zeroDesc: '<b>Если вы когда-либо управляли командой людей, расскажите о вашей роли, взаимодействиях с членами команды, что удалось изменить/улучшить?</b> Скажем, вот так: ”Основная задача – реорганизация проектного управления в компании: Оптимизация проектной деятельности; Оптимизация бизнес процессов, снижение издержек, организация ресурсного планирования; Формализация ролевой модель проектного управления; Проработка мотивации для Проектных Команд; Внедрение Jira...”',
      smallDesc: '<b>Вы написали об управлении командой, это важно!</b> Напишите ещё немного: добавьте информации о том как взаимодействовали с командой, чего удалось добиться? Скажем, вот так: “Из моих задач в команде: Формирование бэклога и контроль разработки мобильного приложения, Плотная работа с командой разработки по Scrum в роли Product Owner, Управление бэклогом более 10-ти Scrum-команд, синхронизация для достижения единых целей продукта, Управление бюджетом команды и проекта, Наставничество новых Product owners (более 10 человек за год), Формирование стратегии развития продукта...”',
      tip: '<mark>Опыт общего управления</mark> - слова про управление командами'
    }
  ],
  negative: [
    {
      key: 'bouncer',
      title: '"Попрыгунчик"',
      desc: '<b>Вы часто меняли работу после непродолжительного периода. Обязательного опишите в резюме причины почему так происходило.</b> Возможно это были проектные контракты или осознание того, что это не ваше место? Это нормально, расскажите об этом. Иначе, работодатель посчитав, что вы проблемный кандидат, решит не связыватся c вами',
      tip: '<mark>"Попрыгунчик"</mark> - часто меняющий места работы после непродолжительного периода'
    },
    {
      key: 'job-gaps',
      title: 'Перерывы в работе',
      desc: '<b>Кажется, у вас был перерыв в работе больше 3 месяцев. Это может смутить HRа, но мы знаем что с этим сделать.</b> Хорошая идея: отметить это время как работу на себя и прописать каким полезным в работе навыкам вы в этот момент обучились, в чём стали сильнее. Вот отличный пример: “Компания: Декрет и отпуск по уходу за ребенком. Должность: мама и проектный менеджер. Описание: Во время отпуска по уходу за ребенком создала несколько занятных проектов: 1. Переработка сайта компании, торгующей немецкой кухонной фурнитурой - весь дизайн и функции. Начиная со сбора требований заказчика, до ТЗ на дизайн и разработку, через взаимодействие с подрядчиками. 2. Организация собственных курсов для кормящих и занятий послеродовой йогой. Благодаря этому проекту удалось получить опыт в Customer development, SMM, email-маркетинге, создании и аб-тестировании разных версий лендингов, составлении ТЗ на рекламные макеты',
      tip: '<mark>Перерывы в работе</mark> – наличие в резюме более 3 месяцев перерывов в работе'
    },
    {
      key: 'lev-tolstoy',
      title: '"Лев Толстой"',
      desc: '<b>У вас в тексте мы нашли несколько довольно длинных предложений, восприятие которых любым специалистом, включая рекрутеров, как мы считаем, часто бывает затруднительно</b>, особенно в случае, когда обработкаа информации происходит ближе к концу рабочего дня, а HR устал и ждёт, когда поедет домой… ну, вы поняли.) Постарайтесь разбить текст на более читаемые блоки, можно даже визуально разделить их переносом строки - так удобнее читать',
      tip: '<mark>"Лев Толстой"</mark> – любовь к большим и сложным текстам'
    },
    {
      key: 'small-experience',
      title: '"Зелёный ещё"',
      desc: '<b>Может быть, вы что-то забыли? По себе знаем, сложно что-либо написать про работу, если релевантного опыта особо и не было.</b> В таком случае можно описать другой ваш опыт, который давал вам необходимые в работе навыки. Если вы только выпустились, но были старостой группы и отвечали за посещаемость - замечательно, расскажите чему вы научились! Участвовали в конкурсе стипендий и выиграли - чудесно, это тоже навык, очень похоже на получение проектов в тендерной системе! Если же вы давно начали карьеру, но сейчас решили совершить крутой поворот, попробуйте найти в вашем прошлом опыте навыки, релевантные для текущей должности. Например, врач-хирург, который решил пойти в бизнес-аналитики, точно может писать о навыках глубокого анализа информации(изучение анамнеза) и принятия решений в ситуации давления/стресса',
      tip: '<mark>"Зелёный ещё"</mark> – общий опыт работы небольшой. Менее трёх лет. Но это не страшно, мы уверены, что вам есть чем заполнить пустые клетки!'
    },
    {
      key: 'cliche',
      title: 'Клише',
      desc: '<b>Есть некоторый список самых популярных фраз в резюме и именно их на дух не переносит любой HR.</b> Это фразы типа “активная жизненная позиция”',
      tip: '<mark>Клише</mark> - мы нашли в резюме шаблонные фразы, которые ненавидят все рекрутеры'
    },
    {
      key: 'perfectionist',
      title: 'Перфекционизм',
      desc: '<b>Мы обнаружили в резюме упоминание перфекционизма и спешим вас предупредить:</b> перфекционисты тормозят процессы в стартапах, но классно дают качественную работу, нужную для сложных "водопадных" проектов и качественных продуктов на заказ. То есть, перфекционизм сильно влияет на скорость работы и рекрутеры об этом знают. Если вы метите в стартап, маленькую, молодую, или просто быстро растущую компанию, лучше об этом не писать. Если ваше место в спокойной, стабильной компании-гиганте и её огромной команде, оставляйте “перфекционизм” на месте!',
      tip: '<mark>Перфекционист</mark> - болезненное стремление сделать работу идеально, из-за чего могут тормозиться процессы'
    },
    {
      key: 'exp-perfectionist',
      title: 'Перфекционист',
      desc: '<b>Мы нашли в резюме что-то “идеальное” и спешим вас предупредить:</b> перфекционисты тормозят процессы в стартапах, но классно дают качественную работу, нужную для сложных "водопадных" проектов и качественных продуктов на заказ. То есть, перфекционизм сильно влияет на скорость работы и рекрутеры об этом знают. Если вы метите в стартап, маленькую, молодую, или просто быстро растущую компанию, лучше так не писать. Если ваше место в спокойной, стабильной компании-гиганте и её огромной команде, оставляйте  весь перфекционизм на месте!',
      tip: '<mark>Перфекционист</mark> - болезненное стремление сделать работу идеально, из-за чего могут тормозиться процессы'
    },
    {
      key: 'freelance',
      title: 'Freelance',
      desc: '<b>Обнаружили в вашем резюме, что вы упоминаете Freelance. Если на чистоту, то HR-ы не очень любят фрилансеров.</b> Будет полезно рассказать, почему вы решили пофрилансить. Пример: "Захотел путишествоать, но финансовой подушки не хватало и т.п.". Если вы хотите фриланс и у вас указан только фриланс, то не обращайте внимание на эту рекомендацию. Удачи!',
      tip: '<mark>Freelance</mark> – ну, что тут можно пояснить. В резюме есть упоминание фриланса, всё'
    },
    {
      key: 'junior',
      title: 'Из Junior в князи',
      desc: '<b>Нашим алгоритмам показалось, что ваш переход от Junior позиции к Senior произошёл как-то очень быстро, скачкообразно.</b> Возможно, имеет смысл прописать более постепенный ход событий или как-то объяснить такой серьёзный рост за такое маленькое время. Например, если вы работали в одной компании 7 лет и прошли путь от junior через Middle к Senior и на следующем месте работы начали сразу с Senior позиции, стоит прописать изменение должностей на месте работы, где состоялся ваш рост',
      tip: '<mark>Из Junior в князи</mark> - быстрый переход от junior позиций к более высоким без промежуточного звена'
    },
    {
      key: 'unemployed',
      title: 'Безработный',
      desc: '<b>Кажется, вы сейчас нигде не работаете, HR-ов это насторожит, так что лучше рассказать почему так вышло.</b> Например: "Выгорел на прошлой работе, ушёл вникуда чтобы отдохнуть. Теперь настало время камбэкнуться в полную силу"',
      tip: '<mark>Безработный</mrak> – на момент размещения резюме'
    }
  ]
}
