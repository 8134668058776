<template lang="pug">
  div
    .main-banner
      .gradient
      .main-banner__inner
        .container
          h1.title-big.text-white Услуги
      a(href="#service" v-smooth-scroll).arrow-scroll
        svg.t-cover__arrow-svg(style='fill:#ffd900;' x='0px' y='0px' width='38.417px' height='18.592px' viewBox='0 0 38.417 18.592')
          g
            path(d='M19.208,18.592c-0.241,0-0.483-0.087-0.673-0.261L0.327,1.74c-0.408-0.372-0.438-1.004-0.066-1.413c0.372-0.409,1.004-0.439,1.413-0.066L19.208,16.24L36.743,0.261c0.411-0.372,1.042-0.342,1.413,0.066c0.372,0.408,0.343,1.041-0.065,1.413L19.881,18.332C19.691,18.505,19.449,18.592,19.208,18.592z')
    section.section-service#service
      .container
        p.service-desc CV Boost раскрывает все навыки digital-специалистов в резюме, используя данные ресёрча рынка, внутренние умные алгоритмы и рекомендации профессиональных рекрутеров. Анализ с помощью CV Boost помогает улучшить читаемость, оформление, стилистику текста и глубже раскрыть профессиональные навыки не упустив необходимые для рекрутеров детали
        .service-block
          .service-block__item
            img(alt="" src="@/assets/images/clever_analysis.webp")
            h4.h4 Умный анализ
            p.service-block__item-desc После оплаты мы проанализируем резюме прямо на сайте при помощи внутренних алгоритмов
          .service-block__item
            img(alt="" src="@/assets/images/smart_structure.webp")
            h4.h4 Умная структура
            p.service-block__item-desc В структурированном виде на странице сайта покажем, как и где необходимо изменить резюме для увеличения уровня
          .service-block__item
            img(alt="" src="@/assets/images/save.webp")
            h4.h4 Сохранить на будущее
            p.service-block__item-desc Полученный анализ можно скачать в удобном формате на устройство и использовать сколько угодно
        .buttons-wrap
          Button.button-yellow(label='На страницу анализа резюме' link='/app')


</template>

<script>
import Button from "@/components/elements/Buttons/Button";

export default {
  name: "Service",
  components: {Button}
}
</script>

<style scoped lang="scss">
.main-banner {
  background-image: url('~@/assets/images/linkedinsalesnavigat.webp');
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-service {
  padding: 120px 0 60px;
  .button{
    padding: 20px 60px;
  }
  @include tablet {
    padding-top: 50px;
  }
  .button{
    padding: 20px 30px;
    font-size: 16px;
    
  }
}

.service-block {
  display: flex;
  justify-content: space-around;
  margin-bottom: 90px;
  @include tablet {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.service-desc {
  font-size: 30px;
  font-family: $RobotoThin;
  text-align: center;
  color: #000;
  line-height: 1.35;
  margin-bottom: 90px;
  @include tablet{
    font-size: 22px;
  }
}

.service-block__item-desc {
  font-family: $RobotoThin;
  line-height: 25px;
  
}

.service-block__item {
  text-align: center;
  padding: 0 35px;

  img {
    margin-bottom: 30px;
  }

  .h4 {
    margin-bottom: 25px;
  }
  @include tablet {
    margin-bottom: 50px;
  }
}
</style>