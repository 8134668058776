<template lang="pug">
  .button-wrap(@click="handleClick")
    router-link.button(
      :to="link"
      :tag='tag'
      v-smooth-scroll="scroll"
      v-html="label"
    )

</template>

<script>
export default {
  name: "Button",
  props: {
    label: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: '#'
    },
    tag: {
      type: String,
      default: 'a'
    },
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    scroll: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    }
  }
  
  
}
</script>

<style scoped lang="scss">
@import "ButtonStyles";


</style>