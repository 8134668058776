<template lang="pug">
  .menu-mobile
    slot(name="menu-content")
  
  
</template>

<script>
export default {
name: "MobileMenu"
}
</script>

<style scoped lang="scss">
.menu-mobile{
  background-color: rgb(0, 0, 0);
  position: fixed;
  top: -1000px;
  left: 0;
  right: 0;
  z-index: 2000;
  padding: 15px;
  transition: 0.5s;
  
}

.menu-open{
  .menu-mobile {
    top: 60px;
    transition: 0.5s;
  
  }
}


.header-right__list{
  text-align: center;
}
.header-right__item{
  padding: 5px;
  display: block;
}
</style>