import analyst from './tags-data-analyst.js'
import backend from './tags-data-backend.js'
import designer from './tags-data-designer.js'
import frontend from './tags-data-frontend.js'
import fullstack from './tags-data-fullstack.js'
import product from './tags-data-product.js'

export default {
  analyst,
  backend,
  designer,
  frontend,
  fullstack,
  product
}