<template lang="pug">
  div
    .main-banner
      .gradient
      .main-banner__inner
        .container
          h1.h1 <span>CV Boost</span> ускорит поиск лучшей работы и повысит уровень зарплаты
          p.desc Проанализируем ваше резюме <span>мгновенно</span> и <span>бесплатно</span> и дадим список рекомендаций для улучшения
          .buttons-wrap
            Button.button-yellow(label='Проанализировать резюме' link='/app')
            Button.button-white(label='Как это работает?' scroll=true link='#work')
          img(alt="cvboost examp" src="@/assets/images/CV_Boost_App_cv_exam.png").img-example
      a(href="#description" v-smooth-scroll).arrow-scroll
        svg.t-cover__arrow-svg(style='fill:#ffd900;' x='0px' y='0px' width='38.417px' height='18.592px' viewBox='0 0 38.417 18.592')
          g
            path(d='M19.208,18.592c-0.241,0-0.483-0.087-0.673-0.261L0.327,1.74c-0.408-0.372-0.438-1.004-0.066-1.413c0.372-0.409,1.004-0.439,1.413-0.066L19.208,16.24L36.743,0.261c0.411-0.372,1.042-0.342,1.413,0.066c0.372,0.408,0.343,1.041-0.065,1.413L19.881,18.332C19.691,18.505,19.449,18.592,19.208,18.592z')
    DescriptionBlock#description(
      text=`CV Boost повышает конверсию на 30% из просмотра резюме в собеседование и даёт объективный повод повысить уровень ожидаемой зарплаты.<br> Подходит для IT и digital-специалистов.`
    )
    section.section-how#work
      .container
        h2.h2 Как работает анализ резюме в CV Boost
        .how-row
          .how-item
            img(alt="cvboost examp" src="@/assets/images/CV_Boost_cv_update.svg").img-content
          .how-item.how-item_desc
            .title Какие ключевые слова заметит рекрутер
            p.p Алгоритмы покажут как рекрутер анализирует резюме. Рекрутеры просматривают больше сотни резюме в день и вынуждены смотреть их "по диагонали". Внимание к себе привлекают отдельные слова, навыки, инструменты. CV Boost сканирует резюме и предлагает ключевые слова, которые полезно добавить, ориентируясь на выбранную профессию.
            Button.button-yellow.button-small(label='Проанализировать бесплатно' link='/app')
        .how-row.how-row_reverse
          .how-item.how-item_desc
            .title Рост уровня зарплаты
            p.p CV Boost проанализирует резюме и просчитает уровень заработной платы, на который можно претендовать с таким резюме. Расчёты основаны на анализе зарплат рынка вакансий.
            Button.button-yellow.button-small(label='Проанализировать бесплатно' link='/app')
          .how-item
            img(alt="cvboost examp" src="@/assets/images/CV_Boost_cv_enhancem.svg").img-content
        .how-row
          .how-item
            img(alt="cvboost examp" src="@/assets/images/CV_Boost_cv_enhancem.svg").img-content
          .how-item.how-item_desc
            .title Поиск отталкивающей информации
            p.p В 80 из 100 резюме есть лишние детали и информация, которую рекрутер воспримет отрицательно. CV Boost чётко видит такие моменты, выделяет их в тексте и даёт советы о том, как можно исправить текст с примерами и объяснением
            Button.button-yellow.button-small(label='Проанализировать бесплатно' link='/app')

    section.section-resume_one
      .container
        h3.h3 Сравнение резюме до и после рекомендаций CV Boost
        TwentyTwenty(
          before="https://static.tildacdn.com/tild3062-3231-4165-a565-326331366138/1_2x.png"
          after="https://static.tildacdn.com/tild3365-6135-4062-b437-323233626232/_12x.png"
        )
    section.section-resume_two
      .container
        TwentyTwenty(
          before="https://static.tildacdn.com/tild3739-3739-4332-b964-303563336230/2_2x.png"
          after="https://static.tildacdn.com/tild3864-3564-4538-b936-396636393438/_22x.png"
        )
  
    DescriptionBlock(
      text='В 99% случаях резюме можно улучшить. В 90% случаях специалисты используют неполное резюме, описывающее опыт хуже, чем он был на самом деле. Проанализировать резюме стоит каждому.'
    )
    section.section-check
      .container
        h2.h2.text-white Как происходит проверка вашего резюме
        .check-block
          .check-block__item
            .check-block__item-num 1
            .check-block__item-desc
              | <span>Старт.</span> Отправьте ссылку на ваше hh резюме. Для этого нужно временно сделать его видимым или доступным по ссылке. Мы расскажем как.
          .check-block__item
            .check-block__item-num 2
            .check-block__item-desc
              | <span>Анализ CV Boost.</span> Наши алгоритмы выделят навыки, найдут лишнее и сгенерируют личные рекомендации по улучшению
          .check-block__item
            .check-block__item-num 3
            .check-block__item-desc
              | <span>Результаты.</span> Будет сформирован отчёт с коэффициентом влияния, статистикой и рекомендациями. Он будет доступен на той же странице всегда. Можно оплатить доступ к премиум-версии
          .check-block__item
            .check-block__item-num 4
            .check-block__item-desc
              | <span>Повторная проверка.</span> Внесите правки по предложенным рекомендациям и проверьте резюме ещё раз. Это не потребует дополнительных оплат. Загружать резюме можно сколько угодно раз.
    section.section-result
      .container
        h2.h2 Что вы получите в результате?
        .result-block
          .result-block__item
            img(alt="" src="@/assets/images/CV_assessment.svg").result-block__item-img
            p.result-block__item-desc Оценку эффективности вашего резюме в виде коэффициента влияния
          .result-block__item
            img(alt="" src="@/assets/images/salary_assessment.svg").result-block__item-img
            p.result-block__item-desc Оценку уровня заработной платы, на который можно претендовать с вашим резюме
          .result-block__item
            img(alt="" src="@/assets/images/skill_assessment.svg").result-block__item-img
            p.result-block__item-desc Анализ выявленных из резюме навыков, формулировок и ключевых слов
          .result-block__item
            img(alt="" src="@/assets/images/error_list.svg").result-block__item-img
            p.result-block__item-desc Список явных ошибок и недочётов
          .result-block__item
            img(alt="" src="@/assets/images/recommendations.svg").result-block__item-img
            p.result-block__item-desc Развёрнутые рекомендации по улучшению резюме с готовыми примерами
          .result-block__item
            img(alt="" src="@/assets/images/premium_account.svg").result-block__item-img
            p.result-block__item-desc Возможность приобрести премиум-план на 30 дней, который дает расширенный список рекомендаций
        .buttons-wrap
          Button.button-yellow(label='Улучшить резюме' link='/app')
    
    section.section-questions
      .container
        h3.h3.text-white Самые важные вопросы и ответы на них
        vsa-list
          vsa-item
            vsa-heading
              | Как выглядит процесс анализа резюме и что я получу в итоге?
            vsa-content
              .accord-text
                | 1. Вы отправляете нам ссылку на ваше резюме на hh.ru. Чтобы мы смогли его прочитать, пожалуйста, откройте нам доступ к нему на время анализа.
                <br>
                |Для этого в личном кабинете в разделе <a href='#'>Мои резюме</a>, нажмите «изменить видимость», отмечайте пункт "Видно только по прямой ссылке", внизу страницы не забудьте нажать «сохранить».
                <br>
                |Чтобы скопировать ссылку, в том же разделе <a href='#'>Мои резюме</a> нажмите на «Доступно только по прямой ссылке» и скопируйте ссылку в появившемся окне.
              .accord-text
                |2. Алгоритмы CV Boost анализируют текст резюме, основываясь на базе из тысяч других резюме для данной профессии, а так же критериях, вручную составленных нашими карьерными консультантами. После анализа формируется подробный отчёт со статистикой и практическими советами для конкретно вашего резюме.
              .accord-text
                |3. Отчёт появится на той же странице. Вам будут доступны несколько советов и часть статистики. За доступ к полной версии можно будет заплатить 499 ₽. Доступ к полной версии возможен с одного устройства в течение 30 дней после покупки.
              .accord-text
                |4. Вы можете исправить и дополнить резюме на странице HH, после чего проверить его ещё раз через CV Boost. Для повторных проверок не требуется никаких дополнительных оплат.
          vsa-item
            vsa-heading
              | А что если у меня нет резюме на hh?
            vsa-content
              .accord-text
                | Пока что, наш сервис работает только с резюме на hh.ru, но в ближайшее время мы планируем добавить функцию загрузки резюме в виде файла. Если вам это интересно, напишите пожалуйста в форму обратной связи — мы свяжемся с вами когда опция станет доступна.
          vsa-item
            vsa-heading
              | Как происходит анализ, откуда вы знаете какие навыки нежелательные, а какие мне нужно добавить?
            vsa-content
              .accord-text
                | Мы обучали алгоритмы CV Boost на базе из более 800 тысяч резюме и более 2 миллионов вакансий.
                | Кроме того, все результаты строго контролировались человеком: наши HR-консультанты с опытом работы в digital от 7 лет помогали понять что из найденного действительно важно.
                <br>
                | В результате, мы получили продукт, который чётко понимает, чего ждёт рынок.
                | А ещё CV Boost продолжает обучаться каждый день и менять свои представления об идеале вместе с изменением рынка!
          vsa-item
            vsa-heading
              | В результате я получу готовое улучшенное резюме?
            vsa-content
              .accord-text
                | Нет. В результате вы получите список ошибок к исправлению и пунктов, которые не раскрыты, либо раскрыты недостаточно полно.
                <br>
                |После анализа вы сможете исправить их в личном кабинете hh.ru и перепроверить резюме на CV Boost. Для этого не нужно никаких дополнительных платежей
          vsa-item
            vsa-heading
              | Кто ваши эксперты, почему они знают, что мне нужно?
            vsa-content
              .accord-text
                | CV Boost обучают бывшие рекрутеры из крупных российских и зарубежных компаний IT и digital-сферы. Каждый из них имеет опыт работы в HR не менее 7 лет и, скажем честно, переманить их было довольно сложно. Но мы смогл
    section.section-message
      .gradient
      .container
        .message__block
          .message__block-title Хотите похвалить или поругать сервис?
          .message__block-desc <span class='span-br'>Если вам есть что нам рассказать — не сдерживайте</span> себя, мы хотим становиться лучше!
          .buttons-wrap
            svg.arrow-icon_mobile(style='fill:#ffe100;' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 70')
              path(d='M30.6 47.5c-.6-.5-1.6-.4-2.1.2L19 59.6V5.5c0-.8-.7-1.5-1.5-1.5S16 4.7 16 5.5v54.1L6.5 47.7c-.6-.6-1.5-.7-2.1-.2-.7.5-.8 1.5-.3 2.1l12.2 15.2c.3.4.7.6 1.2.6s.9-.2 1.2-.6l12.2-15.2c.5-.6.4-1.6-.3-2.1z')
            svg.arrow-icon(style='fill:#ffe100; ' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 180')
              path(d='M54.1 108c-.5 0-.9-.2-1.2-.6-.5-.7-.3-1.6.4-2.1 1.5-1 9.5-5.5 14.6-8.3-17.4-.5-31.3-7.3-41.3-20C9.9 55.7 9.5 24.2 14.2 3.7c.2-.8 1-1.3 1.8-1.1.8.2 1.3 1 1.1 1.8-4.6 19.9-4.2 50.3 11.8 70.8 9.5 12.2 23 18.6 39.9 18.9h.3l-3.2-4c-1.4-1.7-2.7-3.3-4.1-5.1-.7-.9-1.5-1.9-2.3-2.9-.5-.6-.4-1.6.2-2.1.6-.5 1.6-.4 2.1.2 0 0 0 .1.1.1l6.4 7.9c.5.6.9 1.1 1.4 1.7 1.5 1.8 3.1 3.6 4.3 5.5 0 .1.1.1.1.2.1.2.1.3.2.5v.3c0 .2 0 .3-.1.5 0 .1-.1.1-.1.2-.1.2-.2.3-.3.4-.1.1-.2.1-.3.2 0 0-.1 0-.2.1-.9.4-16 8.6-18.2 10.1-.4 0-.7.1-1 .1z')
            Button.button-yellow.button-radius(label='Написать нам'  @click="show" scroll=false)
      ModalSend
      ModalBoring
    













</template>

<script>

import Button from "@/components/elements/Buttons/Button";
import DescriptionBlock from "@/components/landing/DescriptionBlock";
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import 'vue-twentytwenty/dist/vue-twentytwenty.css';
import TwentyTwenty from 'vue-twentytwenty';
import ModalSend from "@/components/Modals/ModalSend";
import ModalBoring from "@/components/Modals/ModalBoring";
export default {
  name: 'Home',
  components: {
    ModalBoring,
    ModalSend,
    DescriptionBlock,
    Button,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon,
    TwentyTwenty
    
  },
  methods: {
    show () {
      this.$modal.show('modal-send');
    }
  },
}
</script>

<style scoped lang="scss">

.main-banner{
  background-image:url('~@/assets/images/photo.jpeg');
}

.desc{
  margin-top: 34px;
}
.buttons-wrap{
  margin-top: 50px;
  margin-bottom: 85px;
}
.main-banner__inner{
  padding: 100px 0 85px;
  @include tablet{
    padding-bottom: 0;
  }
 
  
}

.img-example{
  width: 960px;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  @include tablet{
    margin-bottom: -50px;
    max-width: 85%;
  }
}



.section-how{
  padding-top: 135px;
  padding-bottom: 100px;
  @include tablet{
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.how-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  @include tablet{
    flex-direction: column;
  }
}

.how-row_reverse{
  @include tablet{
    flex-direction: column-reverse;
  }
}

.how-item_desc{
  padding-right: 60px;
  @include tablet{
    padding-right: 0;
  }
  
}

.how-item{
  @include width-flex(calc(50% - 20px))
  .button-wrap{
    margin-top: 30px;
  }
  @include tablet{
    @include width-flex(100%)
  }
}


.section-resume_one{
  padding: 120px 0 90px;
  background-color: #ffbb00;
  @include tablet {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.section-resume_two{
  padding: 90px 0;
  background-color: #ffcd38;
}

.resume-block{
  text-align: center;
}

.section-resume_one{
  .h3{
    margin-bottom: 90px;
  }
}

.img-resume{
  width: 960px;
}


.section-check{
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #1f1f1f;
  @include tablet{
    padding-top: 20px;
    padding-bottom: 30px;
  }
}

.check-block{
  display: flex;
  @include tablet{
    flex-direction: column;
  }
}
.check-block__item{
  display: flex;
  flex-direction: column;
  @include width-flex(25%)
  position: relative;
  &:before{
    content: '';
    background: $white;
    height: 2px;
    width: 100%;
    position: absolute;
    left: 34px;
    right: 0;
    top: 17px;
  }
  &:last-child{
    &:before{
      display: none;
    }
    .check-block__item-desc {
      padding-right: 15px;
    }
  }
  @include tablet{
    @include width-flex(100%)
    flex-direction: row;
    &:before{
      height: 97%;
      width: 2px;
      left: 16px;
      right: 0;
      top: 35px;
    }
  }
}
.check-block__item-num{
  background: #fed831;
  @include width-flex(34px)
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 16px;
}

.check-block__item-desc{
  color: $white;
  font-size: 18px;
  font-family: $RobotoThin;
  line-height: 1.55;
  padding-right: 48px;
  span{
    font-family: $RobotoRegular;
  }
  @include tablet{
    padding-right: 0;
    margin-left: 15px;
    margin-bottom: 50px;
    padding-top: 5px;
  }
}

.section-result{
  padding-top: 90px;
  .h2{
    margin-bottom: 30px;
    font-family: $RobotoRegular;
  }
  .button{
    padding: 20px 60px;
  }
  @include tablet {
    padding-top: 50px;
    .button{
      padding: 20px 40px;
      font-size: 16px;
    }
  }
}

.result-block{
  max-width: 65%;
  margin-left: auto;
  margin-right: auto;
  @include tablet {
    max-width: 100%;
  }
}

.result-block__item{
  display: flex;
  align-items: center;
  @include tablet {
    align-items: flex-start;
    margin-bottom: 40px;
  }
}

.result-block__item-desc{
  margin-left: 20px;
  font-size: 20px;
  line-height: 1.55;
}
.result-block__item-img{
  @include tablet {
    @include width-flex(50px)
  }
}

.section-questions{
  background-color: #1f1f1f;
  padding: 60px 0 90px;
  .h3{
    font-family: $RobotoThin;
  }
}

::v-deep {
  
  .vsa-list {
    --vsa-max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    --vsa-bg-color: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #474747;
    
  }
  
  .vsa-item {
    border-top: 1px solid #474747;
    padding-bottom: 30px;
  }
  
  .vsa-item__heading {
    border-bottom: none !important;
    
  }
  
  .vsa-item__trigger {
    padding: 27px 0px 0px;
    
    &:hover {
      cursor: pointer;
    }
  }
  
  .vsa-item__trigger:focus, .vsa-item__trigger:hover {
    background: none;
    color: #fed831;
  }
  
  .vsa-item__trigger__content {
    color: #fed831;
    font-size: 24px;
    line-height: 1.35;
    text-align: left;
    padding-right: 50px;
    @include tablet{
      font-size: 20px;
      padding-right: 0;
    }
  }
  
  .vsa-item__trigger__icon--is-default:after,
  .vsa-item__trigger__icon--is-default:before {
    display: none;
  }
  
  .vsa-item__trigger__icon {
    background-image: url('~@/assets/images/cross.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    @include width-flex(40px)
    height: 40px;
    background-color: #333333;
    border-radius: 50%;
    
    &:hover {
      //transform: translateY(-50%);
      transition: opacity ease-in-out .2s;
    }
  }
  
  .vsa-item--is-active {
    .vsa-item__trigger__icon {
      transform: rotate(45deg);
      
    }
  }
  
  .vsa-item__content {
    padding: 0px;
    color: $white;
    font-family: $RobotoThin;
    line-height: 1.55;
  }
}
.accord-text{
  padding-top: 20px;
  margin-bottom: 30px;
  @include last-mb-0
  a{
    color: #ffbb00 !important;
  }
}

.section-message{
  text-align: center;
  @include background-cover('blob.webp')
  width: 100%;
  padding: 90px 0 90px;
  position: relative;
  .gradient{
    background: linear-gradient(to bottom right, rgba(0,0,0,0.80), rgba(0,0,0,0.90));
  
  }
}

.message__block{
  position: relative;
  z-index: 10;
  .button{
    width: 230px;
  }
  .buttons-wrap{
    position: relative;
    margin: 0;
  }
}

.message__block-title{
  font-size: 48px;
  line-height: 1.5;
  color: $white;
  margin-bottom: 30px;
  @include tablet{
    font-size: 30px;
    line-height: 1.23;
  }
}

.message__block-desc{
  color: $white;
  margin-bottom: 70px;
  font-size: 24px;
  line-height: 1.5;
  font-family: $RobotoThin;
  @include tablet{
    font-size: 20px;
    line-height: 1.4;
  }
}

.arrow-icon_mobile {
  display: none;
  width: 20px;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  fill: #fff;
  top: -55px;
  bottom: 0;
  @include tablet{
    display: block;
  }
}
.arrow-icon{
  width: 45px;
  position: absolute;
  left: 37%;
  transform: translateX(-100%);
  fill: #fff;
  top: 0;
  bottom: 0;
  margin: auto;
  @include tablet{
    display: none;
  }
}



::v-deep {
  
  .twentytwenty-container .twentytwenty-handle:after,
  .twentytwenty-container .twentytwenty-handle:before {
    border-color: #ff8800;
  }
  .twentytwenty-container{
    max-width: 960px;
    height: 1357px;
    margin-left: auto;
    margin-right: auto;
    &:hover{
      .twentytwenty-overlay{
        background: transparent;
      }
    }
  
  }
  .twentytwenty-handle{
    background-color: #ff8800;
    height: 60px !important;
    width: 9px !important;
    margin-left: 13px;
    border: none;
    border-radius: 0px;
    &:hover{
      .twentytwenty-arrow-left {
        left: -19px;
        transition: all .2s ease;
      }
      .twentytwenty-arrow-right{
        left: 18px;
        transition: all .2s ease;
  
      }
    }
  }
  .twentytwenty-arrow-left{
    border-right: 10px solid #ff8800;
    top: 18px;
    left: -15px;
    transition: all .2s ease;
  }
  .twentytwenty-arrow-right{
    border-left: 10px solid #ff8800;
    bottom: 1px;
    left: 14px;
    transition: all .2s ease;
  }
  

  
}

</style>